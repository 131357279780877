import axios from 'axios';
import Routes from './routes';

const baseURL = process.env.REACT_APP_API_BASEURL;

let token = JSON.parse(localStorage.getItem('token'));

let config = {
    headers: { Authorization: `Bearer ${token}` }
};

export const apiNoteDetached = {      
    retrieve: (async (id) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.get(baseURL + Routes.RETRIEVE_SHARED_DETACHED_NOTE + id, config)
        return res
    }),
    update: (async (id, payload) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.put(baseURL + Routes.UPDATE_SHARED_DETACHED_NOTE + id, payload, config)
        return res
    }),
    delete: (async (id) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.delete(baseURL + Routes.DELETE_SHARED_DETACHED_NOTE + id, config)
        return res
    })
    
}



