import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,
          //Brandon Grotesque
          'Brandon-Bold-Italic': require('../assets/fonts/Brandon/Brandon_bld_it.otf'),
          'Brandon-Bold': require('../assets/fonts/Brandon/Brandon_bld.otf'),
          'Brandon-Black-Italic': require('../assets/fonts/Brandon/Brandon_blk_it.otf'),
          'Brandon-Black': require('../assets/fonts/Brandon/Brandon_blk.otf'),
          'Brandon-Light-Italic': require('../assets/fonts/Brandon/Brandon_light_it.otf'),
          'Brandon-Light': require('../assets/fonts/Brandon/Brandon_light.otf'),
          'Brandon-Medium-Italic': require('../assets/fonts/Brandon/Brandon_med_it.otf'),
          'Brandon-Medium': require('../assets/fonts/Brandon/Brandon_med.otf'),
          'Brandon-Regular-Italic': require('../assets/fonts/Brandon/Brandon_reg_it.otf'),
          'Brandon-Regular': require('../assets/fonts/Brandon/Brandon_reg.otf'),
          'Brandon-Thin-Italic': require('../assets/fonts/Brandon/Brandon_thin_it.otf'),
          'Brandon-Thin': require('../assets/fonts/Brandon/Brandon_thin.otf'),
          //Bio Rhyme
          'Bio-Bold': require('../assets/fonts/Bio-Rhyme/BioRhyme-Bold.ttf'),
          'Bio-ExtraBold': require('../assets/fonts/Bio-Rhyme/BioRhyme-ExtraBold.ttf'),
          'Bio-Regular': require('../assets/fonts/Bio-Rhyme/BioRhyme-Regular.ttf'),
          'Bio-ExtraLight': require('../assets/fonts/Bio-Rhyme/BioRhyme-ExtraLight.ttf'),
          'Bio-Light': require('../assets/fonts/Bio-Rhyme/BioRhyme-Light.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
