import React, { useEffect, useMemo, useRef,useState } from 'react';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity, ScrollView, SectionList, Platform, Switch } from "react-native-web";
import { CheckBox } from 'react-native-web';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import emailValidator from 'email-validator';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Screens from '../../constants/Screens';
import { apiAuth, apiNote, apiUser, apiUserSettings, globalFunctions } from '../../services';
import PropTypes from 'prop-types';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';

import IconHeader from '../../components/Headers/IconHeader';
import QuickNote from '../../components/QuickNote';
import useNotes from '../../hooks/useNotes';
import ScreenNames from '../../constants/ScreenNames';
import { updateUser } from '../../hooks/useAPI';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SettingsNotificationScreen() {  

    const { user, setUser } = useUser();
    const { token, verifyToken } = useToken();

    const [ smsNotifications, setSMSNotifications ] = useState(() => {
        return user?.settings?.sms_notifications || false;
    });
    const [ emailNotifications, setEmailNotifications ] = useState(() => {
        return user?.settings?.email_notifications || false;
    });

    const [ smsErrorText, setSMSErrorText ] = useState('');
    const [ successText, setSuccessText ] = useState('');
    const [ formLoading, setFormLoading ] = useState(false);

    const navigate = useNavigate();

    const save = async() => {

        try {
            setFormLoading(true);

            const res = await updateUser(user.id, { sms_notifications: smsNotifications, email_notifications: emailNotifications });
            setUser(res);

            setSuccessText(Strings.SUCCESSFULLY_UPDATED);
            setFormLoading(false);
            await globalFunctions.timeout(5000);
            setSuccessText('');


        } catch (e) {
            alert(e.message)
            setFormLoading(false);
        }
        
    }

    useEffect(() => {  
        
        if(!user?.phone) setSMSErrorText(Strings.PHONE_NOT_SET);

    },[smsNotifications,emailNotifications]);

    if(!token) return navigate(ScreenNames.SIGNIN);
    else
    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} exit={true} title={Strings.NOTIFICATIONS} category={''} filter={false} onFilterClick={() => null}/>
            
            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer,{  width: !isMobile ? '50%' : '100%'} ]}>
                
                <View style={[{ marginHorizontal: 25, marginTop: 100}]}>
                    
                    {/* FORM */}

                   <View style={{ flexDirection: 'row'}}>
                        <Switch style={Styles.switch} disabled={!user?.phone} value={smsNotifications} onValueChange={(value) => { setSMSNotifications(value); }} activeThumbColor={Colors.accent} activeTrackColor={Colors.primary}  />
                        <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 2, color: Colors.black, justifyContent: 'center', marginLeft: 15, fontSize: 20 }]}>{Strings.SMS_NOTIFICATIONS}</Text>
                   </View>
                   {/* ERROR MESSAGE */}
                   <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{smsErrorText}</Text>

                   <View style={{ flexDirection: 'row', marginTop: 25}}>
                        <Switch style={Styles.switch} value={emailNotifications} onValueChange={(value) => { setEmailNotifications(value); }} activeThumbColor={Colors.accent} activeTrackColor={Colors.primary}  />
                        <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 2, color: Colors.black, justifyContent: 'center', marginLeft: 15, fontSize: 20 }]}>{Strings.EMAIL_NOTIFICATIONS}</Text>
                   </View>

                    {/* SUCCESS MESSAGE */}
                    <Text style={[GlobalStyles.successText, { width: '100%', fontSize: 20, marginTop: 30 }]}>{successText}</Text>
                    
                </View>
            </View>           

            <TouchableOpacity onPress={save} disabled={formLoading} style={[GlobalStyles.secondaryButton, Styles.saveButton, ]}>
                <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.SAVE}</Text>
            </TouchableOpacity>

        </View>
    );
}

const Styles = StyleSheet.create({
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
    },
    icon: {
        width: isMobile ? 25 : 30,
        height: isMobile ? 25 : 30,
        resizeMode: 'contain',   
        alignSelf: "center",    
    },
    label: {
        fontSize: 25,
        marginLeft: 20
    },
    button: { 
        flexDirection: 'row',
        marginBottom: 10,
        paddingVertical: 15
    },
    saveButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    switch: {
        width: 28, 
        height: 28,
        alignSelf: "center",
    }
    
  
});
  
