import React, { useRef,useState } from 'react';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity } from "react-native-web";
import { Link, useNavigate } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import emailValidator from 'email-validator';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Screens from '../../constants/Screens';
import { apiAuth, apiFamily, apiUserSettings, globalFunctions } from '../../services';
import PropTypes from 'prop-types';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';
import ScreenNames from '../../constants/ScreenNames';
import { getMe, login } from '../../hooks/useAPI';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SignInScreen() {  

    const { setFamily, setUser } = useUser();
    const { setToken, setRefreshToken } = useToken();

    const navigate = useNavigate();
    const [nextButtonLoading, setNextButtonLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordSecure, setPasswordSecure] = useState(true);
    const [passwordError, setPasswordError] = useState('');
    const [formError, setFormError] = useState('');

    const submitForm = async() => {
        if(!emailValidator.validate(email)){
            setEmailError(Strings.EMAIL_NOT_VALID);
            return false;
            
        } 
        if(!password){
            setPasswordError(Strings.PASSWORD_EMPTY);
            return false
        }  

        try {
            setNextButtonLoading(true);
            const res = await login({ email, password })
            setUser(res.user);
            setToken(res.jwt);
            const user = await getMe();
            console.log({ user })
            setFamily(user.family);
            setNextButtonLoading(false);
            navigate(ScreenNames.APP_HOME, { replace: false }); 

        } catch (e) {
            console.log(e.message)
            setFormError(e.message);
            setNextButtonLoading(false);
        }
              
    }
    const signup = async() => {
        navigate('/signup/email', { replace: false });  
    }

    return (
        <View style={GlobalStyles.background}> 
            {/* SITE CONTAINER */}
            <View style={GlobalStyles.container}>

                {/* LOGO */}
                <Link to='/'>
                    <Image style={Styles.logo} source={Images.LOGO} />
                </Link>

                {/* DESCRIPTION */}
                <Text style={[GlobalStyles.signUpFlowDescription, { marginTop: 150 }]}>{Strings.SIGN_IN_DESCRIPTION}</Text>

                {/* FORM ERROR MESSAGE */}
                <Text style={[GlobalStyles.errorText, { width: isMobile ? '100%' : '40%', marginTop: 15, alignSelf: 'center' }]}>{formError}</Text>

                {/* INPUT EMAIL */}
                <TextInput keyboardType='email-address' value={email} onChange={(text) => text && setEmailError('')} onChangeText={(email) => setEmail(email)} placeholder={Strings.EMAIL} style={{ marginTop: 100, width: isMobile ? '100%' : '40%'}}></TextInput>
                {/* ERROR MESSAGE */}
                <Text style={[GlobalStyles.errorText, { width: isMobile ? '100%' : '40%' }]}>{emailError}</Text>

                {/* INPUT PASSWORD */}
                <TextInput secureTextEntry={passwordSecure} value={password} onChange={(text) => text && setPasswordError('')} onChangeText={(password) => setPassword(password)} placeholder={Strings.PASSWORD} style={{ marginTop: 50,  width: isMobile ? '100%' : '40%'}}></TextInput>
                {/* INPUT PASSWORD ERROR MESSAGE */}
                <Text style={[GlobalStyles.errorText, { width: isMobile ? '100%' : '40%' }]}>{passwordError}</Text>

                
                
                {/* ACTION BUTTONS */}
                <View style={[Styles.actionButtonContainer]}>
                    <TouchableOpacity disabled={nextButtonLoading} onPress={submitForm} style={[GlobalStyles.secondaryButton, GlobalStyles.buttonDropShadow, {opacity: nextButtonLoading && 0.5,}]} >
                        <Text style={GlobalStyles.secondaryButtonText}>{Strings.SIGN_IN}</Text>
                    </TouchableOpacity>
                    <Pressable onPress={signup}>
                        <Text style={[Styles.forgotPassword, { marginTop: 20 }]}>{Strings.NO_ACCOUNT_YET}</Text>
                    </Pressable>
                </View>
            </View>
        </View>
        );
    }

const Styles = StyleSheet.create({
    logo: {
      top: height / 10,
      width: width / 1,
      height: height / 7,
      resizeMode: 'contain',
    },
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        bottom: height / 20, 
        alignItems: 'center',
    },
    forgotPassword: {
        fontFamily: 'Brandon-Regular',
        fontSize: 16,
        color: Colors.secondary,
    },  
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
        
        //backgroundColor: 'blue',
    }
    
  
  });
  
//   SignInScreen.propTypes = {
//     setToken: PropTypes.func.isRequired
//   }
  