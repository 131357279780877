

export default {
    //AUTH
    REGISTER_USER: 'users/register/',
    LOGIN_USER: 'users/login/',
    LOGOUT_USER: 'users/logout/',
    PASSWORD_RESET: 'users/password/reset/',
    PASSWORD_RESET_CONFIRM: 'users/password/reset/confirm/',
    PASSWORD_CHANGE: 'users/password/change/',
    TOKEN_VERIFY: 'users/token/verify/',
    TOKEN_REFRESH: 'users/token/refresh/',
    RETRIEVE_USER: 'users/',
    UPDATE_USER: 'users/',
    DELETE_USER: 'users/',
    VERIFY_EMAIL: 'registration/verify-email/',
    USERS_DETAIL: 'users/detail',


    //NOTES
    CREATE_NOTE: 'api/notes/',
    LIST_NOTES: 'api/notes/',
    RETRIEVE_NOTE: 'api/rud/note/',
    UPDATE_NOTE: 'api/rud/note/',
    DELETE_NOTE: 'api/rud/note/',

    //LIST ITEM
    CREATE_LIST_ITEM: 'api/list/',
    LIST_LIST_ITEMS: 'api/list/',
    RETRIEVE_LIST_ITEM: 'api/rud/list/',
    UPDATE_LIST_ITEM: 'api/rud/list/',
    DELETE_LIST_ITEM: 'api/rud/list/',

    //LOCATIONS
    CREATE_LOCATION: 'api/location/',
    LIST_LOCATIONS: 'api/location',
    RETRIEVE_LOCATION: 'api/rud/location/',
    UPDATE_LOCATION: 'api/rud/location/',
    DELETE_LOCATION: 'api/rud/location/',

    //FAMILY
    CREATE_FAMILY: 'api/family/',
    LIST_FAMILIES: 'api/family/',
    RETRIEVE_FAMILY: 'api/rud/family/',
    UPDATE_FAMILY: 'api/rud/family/',
    DELETE_FAMILY: 'api/rud/family/',
    
    //PARENTS
    CREATE_PARENT: 'api/family/parent',
    LIST_PARENTS: 'api/family/parent/',
    RETRIEVE_PARENT: 'api/rud/family/parent/',
    UPDATE_PARENT: 'api/rud/family/parent/',
    DELETE_PARENT: 'api/rud/family/parent/',

    //CHILDREN
    CREATE_CHILD: 'api/family/children',
    LIST_CHILDREN: 'api/family/children',
    RETRIEVE_CHILD: 'api/rud/family/child/',
    UPDATE_CHILD: 'api/rud/family/child/',
    DELETE_CHILD: 'api/rud/family/child/',

    //RELATIVES
    CREATE_RELATIVE: 'api/family/relatives',
    LIST_RELATIVES: 'api/family/relatives',
    RETRIEVE_RELATIVE: 'api/rud/family/relative/',
    UPDATE_RELATIVE: 'api/rud/family/relative/',
    DELETE_RELATIVE: 'api/rud/family/relative/',

    //SETTINGS
    CREATE_USER_SETTINGS: 'api/user/settings',
    LIST_USER_SETTINGS: 'api/user/settings',
    RETRIEVE_USER_SETTINGS: 'api/rud/user/setting/',
    UPDATE_USER_SETTINGS: 'api/rud/user/setting/',
    DELETE_USER_SETTINGS: 'api/rud/user/setting/',

    //IMAGES
    CREATE_IMAGE: 'api/images/',
    LIST_IMAGES: 'api/images/',
    RETRIEVE_IMAGE: 'api/rud/image/',
    UPDATE_IMAGE: 'api/rud/image/',
    DELETE_IMAGE: 'api/rud/image/',

    //SHARED NOTES
    CREATE_SHARED_NOTE: 'api/notes/shared/',
    LIST_SHARED_NOTES: 'api/notes/shared/',
    RETRIEVE_SHARED_NOTE: 'api/rud/notes/shared/',
    UPDATE_SHARED_NOTE: 'api/rud/notes/shared/',
    DELETE_SHARED_NOTE: 'api/rud/notes/shared/',

    //SHARED DETACHED NOTES
    RETRIEVE_SHARED_DETACHED_NOTE: 'api/note/detached/',
    UPDATE_SHARED_DETACHED_NOTE: 'api/note/detached/',
    DELETE_SHARED_DETACHED_NOTE: 'api/note/detached/',

    //SHARED NOTES PK
    CREATE_SHARED_NOTE_PK: 'api/notes/sharedpk/',
    LIST_SHARED_NOTES_PK: 'api/notes/sharedpk/',
    RETRIEVE_NOTE_PK: 'api/rud/notes/sharedpk/',

    //EMAIL
    SEND_INVITATION_EMAIL: 'users/sendemail/',

    



}