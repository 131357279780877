import { useState } from 'react';
import { apiAuth } from '../services';

export default function useToken() {
    const getToken = () => {
        return localStorage.getItem('token') || '';
    };

    const getRefreshToken = () => {
        const tokenString: any = localStorage.getItem('refresh_token');
        const userToken = JSON.parse(tokenString);
        return userToken
    };

    const [token, setToken] = useState(getToken());
    const [refreshToken, setRefreshToken] = useState(getRefreshToken());

    const saveToken = token => {
        localStorage.setItem('token', JSON.stringify(token));
        setToken(token);
    };
    const saveRefreshToken = token => {
        localStorage.setItem('refresh_token', JSON.stringify(token));
        setRefreshToken(token);
    };

    const verifyToken = async() => {
        // try {
        //     let res = await apiAuth.verifyToken();
            
        // } catch(e) {
        //     console.log(e.response);
        //     if(e.response.data.code === 'token_not_valid'){
        //         let res = await apiAuth.refreshToken();
        //         console.log('checked token', res.data.access)
        //         saveToken(res.data.access); 
        //     }
        // }
    }

    return {
        setToken: saveToken,
        setRefreshToken: saveRefreshToken,
        token,
        refreshToken,
        verifyToken,
    }
}
