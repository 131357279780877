import React from 'react';
import { StyleSheet, Dimensions } from "react-native";
import { isMobile } from 'react-device-detect';
import Colors from '../constants/Colors';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const containerWidth = width / 1.2;
const containerAppWidth = width / 1.1;
const borderRadius = 5;
const shadowRadius = 6;

const GlobalStyles = StyleSheet.create({
  background: {
    //flex: 1,
    minHeight: height,
    backgroundColor: Colors.background,
    overflow: 'hidden',
  },
  appBackground: {
    //flex: 1,
    height: height,
    backgroundColor: Colors.white,
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    minHeight: height,
    width: containerWidth,
    alignSelf: 'center',
    zIndex: 1,
    alignItems:'center'    
  },
  appContainer: {
    //flex: 1,
    width: isMobile ? width : containerAppWidth,
    alignSelf: 'center',
    zIndex: 1,
  },
  appHeader: {
    height: 80,   
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    //backgroundColor: Colors.white,    
  },
  appHeaderContainer: {
    alignItems: 'center',    
    justifyContent: 'center',
    alignSelf: 'center',
    width: containerAppWidth, // contrallable
    height: 80,
  },
  appBottomNavigation: {
    height: 80,   
    bottom: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    backgroundColor: Colors.lightBlueAccent,    
  },
  appBottomNavigationContainer: {
    alignItems: 'center',    
    justifyContent: 'center',
    alignSelf: 'center',
    width: containerAppWidth, // contrallable
    height: 80,
  },
  appBottom: {
    height: 140,   
    bottom: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    backgroundColor: Colors.blueAccent,
    zIndex: 10,
  },
  appBottomContainer: {
    alignItems: 'center',    
    justifyContent: 'center',
    alignSelf: 'center',
    //width: containerWidth, // contrallable
    height: 80,
  },
  appBottomShadow: {
    shadowColor: Colors.black,
    shadowOpacity: 0.2,
    shadowRadius: 10,
    //borderRadius: borderRadius,
  },
  secondaryButton: {
    height: 52,
    width: isMobile ? containerWidth : containerWidth / 3,
    backgroundColor: Colors.secondary,
    borderRadius: borderRadius,
    alignItems: 'center',
    justifyContent: 'center',    
  },
  secondaryButtonText: {
    fontFamily: 'Brandon-Regular',
    fontSize: 16,
    color: Colors.white,
  },
  secondaryCircleButton: {
    height: isMobile ? 65 : 65,
    width: isMobile ? 65 : 65,
    backgroundColor: Colors.secondary,
    borderRadius: isMobile ? 65 : 65,
    alignItems: 'center',
    justifyContent: 'center',    
  },
  secondaryOutlineButton: {
    height: 52,
    width: isMobile ? containerWidth : containerWidth / 3,
    backgroundColor: 'transparent',
    borderRadius: borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: Colors.secondary,
    borderWidth: 1,
  },
  secondaryOutlineButtonText: {
    fontFamily: 'Brandon-Regular',
    fontSize: 16,
    color: Colors.secondary,
  },
  buttonDropShadow: {
    shadowColor: Colors.black,
    shadowOpacity: 0.55,
    shadowRadius: shadowRadius,
    borderRadius: borderRadius,
  },
  buttonDropShadowCircle: {
    shadowColor: Colors.black,
    shadowOpacity: 0.55,
    shadowRadius: shadowRadius,
    borderRadius: width,
  },
  leftArrowIcon: {
    width: isMobile ? width / 15 : width / 60,
    height: isMobile ? width / 15 : width / 60,
    resizeMode: 'contain',
    justifyContent: 'center',
  },
  nextArrowIcon: {
    width: 25,
    height: 25,
    justifyContent: 'center',
  },
  exitIcon: {
    width: isMobile ? width / 15 : width / 60,
    height: isMobile ? width / 15 : width / 60,
    resizeMode: 'contain',  
    justifyContent: 'center' 
  },
  topActionHeader: {
    width: '100%',
    marginTop: height / 40,
    flexDirection: 'row',
  },  
  progressDotsRow: {
    marginTop: height / 10,
    flexDirection: 'row',
    width: '100%',
  },
  progressDot: {
    width: isMobile ? width / 50 : width / 150,
    height: isMobile ? width / 50 : width / 150,
    borderRadius: isMobile ? width / 50 : width / 150,
    backgroundColor: Colors.secondary,
    marginRight: isMobile ? width / 50 : width / 150,
  },
  progressLongDot: {
    width: isMobile ? width / 20 : width / 60,
    height: isMobile ? width / 50 : width / 150,
    borderRadius: isMobile ? width / 50 : width / 150,
    marginRight: isMobile ? width / 50 : width / 150,
    backgroundColor: Colors.accent
  },
  signUpContainer: {
    flex: 1,
    height,
    width: !isMobile && containerWidth / 2,
    alignSelf: 'center',
  },
  signUpFlowDescription: {
    marginTop: height / 20,
    fontFamily: 'Brandon-Regular',
    fontSize: 20,
    color: Colors.secondary,
  },
  signUpFlowTitle: {
    marginTop: height / 20,
    fontFamily: 'Brandon-Bold',
    fontSize: 35,
    color: Colors.secondary,
    zIndex:1,
  },
  signUpNextButton: {
    height: 55,
    width: 55,
    backgroundColor: Colors.secondary,
    borderRadius: 55,
    alignItems: 'center',
    justifyContent: 'center',    
  },
  inputAddButton: {
    height: 30,
    width: 30,
    backgroundColor: Colors.white,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',    
  },
  inputAddButtonIcon: {
    width: 25,
    height: 25,
    justifyContent: 'center',
  },
  chip: {
    height: 30,
    minWidth: 30,
    backgroundColor: Colors.secondary,
    borderRadius: 30,
    alignItems: 'flex-start',
    justifyContent: 'center',  
    flexDirection: 'row'
    
  },
  chipShadow: {
    shadowColor: Colors.black,
    shadowOpacity: 0.16,
    shadowRadius: 6,
    borderRadius: 60,
  },
  chipText: {
    fontFamily: 'Brandon-Bold',
    fontSize: 13,
    color: Colors.white,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,    
  },
  bigChip: {
    height: 40,
    minWidth: 40,
    backgroundColor: Colors.secondary,
    borderRadius: 40,
    alignItems: 'flex-start',
    justifyContent: 'center',  
    flexDirection: 'row'
    
  },
  bigChipShadow: {
    shadowColor: Colors.black,
    shadowOpacity: 0.16,
    shadowRadius: 6,
    borderRadius: 40,
  },
  bigChipText: {
    fontFamily: 'Brandon-Bold',
    fontSize: 15,
    color: Colors.white,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 8,    
  },
  chipMinus: {
    height: 24,
    width: 24,
    borderRadius: 24,
    backgroundColor: Colors.white,
    marginTop: 3,
    marginRight: 3,
  },
  errorText: {
    marginTop: 5,
    fontFamily: 'Brandon-Regular',
    fontSize: 15,
    color: Colors.red,
  },
  successText: {
    marginTop: 5,
    fontFamily: 'Brandon-Regular',
    fontSize: 15,
    color: Colors.green,
  },
  roundedTextInput: {
    fontFamily: 'Brandon-Regular',
    fontSize: 17,
    color: Colors.secondary,
    backgroundColor: Colors.primary,
    width: isMobile ? 360 : 600,
    height: isMobile ? 50 : 50,
    borderRadius: isMobile ? 50 : 50,
    opacity: 0.59,
    paddingLeft: 60,
    zIndex: 1,
    
  },
  inputIconButton: {
    position: 'absolute',
    alignSelf: 'flex-start',
    left: 5,
    zIndex: 1,
  },
  iconButtonSmall: {
    width: 40,
    height: 40,
    borderRadius: 40,
    borderColor: Colors.white,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.secondary,
  },
  listTitle: {
    fontFamily: 'Brandon-Regular',
    fontSize: isMobile ? 17 : 25,
    color: Colors.secondary,
  },
  note: {   
    //width: isMobile ? 178 : 300,
    //width: isMobile ? 178 : 300,
    width: '100%',
    //height: isMobile ? 160 : 160,
    backgroundColor: 'rgba(105,174,255, 0.1)',
    //transform: [{ skewY: "-3deg" }],
    borderBottomColor: 'rgba(105,174,255, 0.1)',
    borderBottomWidth: 1,
    paddingVertical: 10
  },
  noteDropShadow: {
    shadowColor: Colors.black,
    shadowOpacity: 0.16,
    shadowRadius: 15,
    shadowOffset: { width: 5, height: 5 },
    transform: [{ skewY: "-3deg" }],
  },
  noteTitle: {
    fontFamily: 'Bio-Bold',
    fontSize: isMobile ? 15 : 17,
    color: Colors.secondary,
  },
  noteText: {
    fontFamily: 'Brandon-Regular',
    fontSize: isMobile ? 15 : 20,
    color: Colors.gray1,
  },
  noteContainer: {
    height: '84%',
    marginHorizontal: isMobile ? 15 : 25,
    marginTop: isMobile ? 10 : 15,
  },
  noteInputTitle: {
    fontFamily: 'Bio-Regular',
    fontSize: isMobile ? 23 : 23,
    color: Colors.black,
  },
  noteInputText: {
    fontFamily: 'Brandon-Regular',
    fontSize: isMobile ? 20 : 20,
    color: Colors.black,
  },
  noteInputTextSmall: {
    fontFamily: 'Brandon-Regular',
    fontSize: isMobile ? 15 : 15,
    color: Colors.black,
  },
  checkboxContainer: {
    flexDirection: "row",
  },
  checkbox: {
    alignSelf: "center",
  },
  checkboxLabel: {
    margin: 8,
  },
  bottomDrawer: {
    alignItems: 'center'
  },
  bottomDrawerContainer: {
    width: isMobile ? containerAppWidth : containerAppWidth / 1.5,
    alignSelf: 'center',
  },
  drawerTitle: {
    fontFamily: 'Brandon-Bold',
    fontSize: isMobile ? 15 : 15,
    color: Colors.black,
  },  
  circle: {
    width: 40,
    height: 40,
    borderRadius: 40,
  },
  dot: {
    width: 11,
    height: 11,
    borderRadius: 11,
  },
  labelTitle: {
    fontFamily: 'Brandon-Regular',
    fontSize: 20,
    color: Colors.black,
  },
  textBrandon: {
    fontFamily: 'Brandon-Regular',
    color: Colors.black,
  },
  noteImage: {
    resizeMode: 'cover',
    width: isMobile ? 172 : 344,
    height: isMobile ? 129 : 258,
    borderRadius: 5,
  },
  noteImageShadow: {
    shadowColor: Colors.black,
    shadowOpacity: 0.80,
    shadowRadius: 50,
    //shadowOffset: { width: 5, height: 5 },
  },
  imageModalCenteredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  imageModalView: {
    margin: 20,
    width: isMobile ? containerAppWidth : containerAppWidth / 1.4,
    height: isMobile ? containerAppWidth : containerAppWidth / 2,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    resizeMode: 'contain'
  },
  littleIcon: {
    resizeMode: 'contain',
    width: 16,
    height: 16,        
  },
  divider: {
    height: 1,
    width,
    backgroundColor: Colors.gray5,
    marginVertical: 10,
    opacity: 0.5,
  },
  devFeedback: {
    width: 100,
    height: 50,
    backgroundColor: Colors.secondary,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    position: 'absolute',
    right: 0,
    top: height / 2,
    zIndex: 1000
  },
  devFeedbackText: {
    fontFamily: 'Brandon-Bold',
    fontSize: 17,
    color: Colors.white,
  },
  productContainer: {
    flexDirection: 'column',
    // alignItems: 'center',    
  },
  productRightContainer: {
    // marginLeft: 20    
  },
  productImage: {
    resizeMode: 'contain',
    width: 100,
    height: 100,
    borderRadius: 100
  },
  productPriceContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  productPrice: {
    fontFamily: 'Brandon-Bold',
    fontSize: 35,
  },
  productPriceTerm: {
    fontFamily: 'Brandon-Regular',
    fontSize: 20,
    marginLeft: 10
  },
  productTitle: {
    fontFamily: 'Brandon-Bold',
    fontSize: 20,
  },
  productSubTitle: {
    fontFamily: 'Brandon-Regular',
    fontSize: 17,
  },

});


export default GlobalStyles;
