

export default {
    HOME: 'Home',
    FEATURES: 'Features',
    TEAM: 'Team',
    ABOUT: 'About',
    CONTACT: 'Contact',
    SUBSCRIBE_NOW: 'Subscribe Now',
    LEARN_MORE: 'Learn More',
    NEXT: 'Next',
    BACK: 'Back',
    SIGN_UP: 'Sign Up',
    SIGN_IN: 'Sign In',
    FORGOT_PASSWORD: 'Forgot Password',
    SUBMIT: 'Submit',
    TITLE: 'Title',
    NOTE: 'Note',
    ADD_ITEM: 'Add item',
    COLORS: 'Colors',
    FAMILY: 'Family',
    CATEGORY: 'Category',
    LOCATION: 'Location',
    ADD_LOCATION: 'Add Location',
    PICK_DATE_TIME: 'Pick a date & time',
    CLOSE: 'Close',
    SETTINGS: 'Settings',
    ACCOUNT: 'Account',
    UPDATE_FAMILY_MEMBERS: 'Update Family Members',
    NOTIFICATIONS: 'Notifications',
    LOCATIONS: 'Locations',
    CHANGE_EMAIL_ADDRESS: 'Change Email Address',
    CHANGE_PASSWORD: 'Change Password',
    DELETE_ACCOUNT: 'Delete Account',
    DELETE_PROFILE: 'Delete Profile',
    DELETE_LOCATION: 'Delete Location',
    LOG_OUT: 'Log Out',
    YOUR_NAME: 'Your Name',
    FAMILY_NAME: 'Family Name',
    PHONE_NUMBER: 'Phone Number',
    SAVE: 'Save',
    SHARE: 'Share',
    SHARED: 'Shared',
    INVITE: 'Invite',
    CANCEL: 'Cancel',
    DONE: 'Done',
    MANAGE_SUBSCRIPTION: 'Manage Subscription',

    //SIGN UP FLOW
    SIGNUP_EMAIL_DESCRIPTION: `Welcome to Dovetail! Let’s get you setup. This shouldn’t take too long.`,
    SIGNUP_PASSWORD_DESCRIPTION: `Your password needs to be at least 8 characters and *other requirements*.`,
    SIGNUP_FAMILY_NAME_DESCRIPTION: `Congratulations! You successfully signed up.  Now, let’s set your family name. This is the last name of your family.`,
    SIGNUP_FAMILY_MEMBERS_DESCRIPTION: `Great! Now let’s add your family members so you can get to planning!`,
    SIGNUP_ALLSET_DESCRIPTION: `Great!  You successfully setup your account.  Now let’s get to planning!`,
    SIGN_IN_DESCRIPTION: 'Sign in with your email and password.',
    ENTER_EMAIL: 'Enter your email',
    CREATE_PASSWORD: 'Create password',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    CONFIRM_EMAIL_ADDRESS: 'Confirm Email Address',
    FAMILY_MEMBERS: 'Family members',
    ALL_SET: `You're all set!`,
    START_PLANNING: 'Start Planning',
    NO_ACCOUNT_YET: `Don't have an account yet? Sign up now!`,
    FORGOT_PASSWORD_DESCRIPTION: 'Forgot your password? Enter your email and we will send you password reset information.',
    EMAIL_ADDRESS: 'Email Address',
    NAME_OF_LOCATION: 'Name of Location',
    ADDRESS: 'Address',
    SIGN_IN_WITH_GOOGLE: 'Sign in with Google',
    SYNC_GOOGLE_CALENDAR: 'Sync Google Calendar',

    //
    PARENTS: 'Parents',
    CHILDREN: 'Children',
    RELATIVES: 'Other',
    NAME: 'Name',
    PHONE: 'Phone',
    WORK: 'Work',
    DAYCARE: 'Daycare',
    SCHOOL: 'School',

    //ERRORS
    EMAIL_NOT_VALID: 'Email address is not valid.',
    PASSWORD_NOT_VALID: 'Password is not valid.',
    CONFIRM_PASSWORD_NOT_VALID: 'Password is not valid.',
    PASSWORD_NOT_MATCH: 'Passwords do not match.',
    PASSWORD_EMPTY: 'Please enter a password.',
    PASSWORD_TOO_WEAK: 'Your password is too weak.',
    PASSWORD_WEAK: 'Your password is weak.',
    FAMILY_NAME_EMPTY: 'Please enter your family name.',
    FAMILY_MEMBERS_EMPTY: `Please add at least one family member.`,
    NAME_REQUIRED: 'Your name is required.',
    FAMILY_NAME_REQUIRED: 'Family name is required.',
    PHONE_NUMBER_REQUIRED: 'Phone number is required.',
    PHONE_NUMBER_NOT_VALID: 'Phone number is not valid.',
    EMAIL_NON_MATCH: 'Email addresses do not match.',
    FIELD_REQUIRED: 'This field is required',

    SHARE_NOTE_SUCCESS: 'Successfully shared!',


    //APP
    START_NEW_NOTE: 'New Note Title',
    WELCOME_BACK: 'Welcome back',
    NOTES_FOR: 'Notes for',
    PINNED: 'Pinned',
    TOP_5: 'Top 5',
    IS_TAGGED_IN: 'is Tagged In',
    NOTES: 'Notes',
    SHARED_WITH_YOU: 'Shared with you',
    INVITE_EMAIL_SENT: 'Invitation Sent',
    COLOR_PALETTE: 'Color Palette',

    //
    SMS_NOTIFICATIONS: 'SMS Notifications',
    EMAIL_NOTIFICATIONS: 'Email Notifications',

    //ALERTS
    DELETE_PHOTO_CONFIRM: 'Are you sure you want to delete this photo?',
    DELETE_NOTE_CONFIRM: 'Are you sure you want to delete this note?',
    ARE_YOU_SURE_LOGOUT: 'Are you sure you want to log out?',
    ARE_YOU_SURE_WANT_TO_DELETE_ACCOUNT: 'Are you sure you want to delete your account?',
    ARE_YOU_SURE_WANT_TO_DELETE_LOCATION: 'Are you sure you want to delete this location?',
    SUCCESSFULLY_UPDATED: 'Successfully Updated',
    PHONE_NOT_SET: 'Your phone number has not been set.',
    EMPTY_NOTES: `You don't have any notes.`,
    SHARED_NOTE_EMPTY_EMAIL_PROMPT: `You haven't added an email address for this family member yet.  Would you like to do that now?`,
    SHARED_NOTE_USER_NO_ACCOUNT: `does not have a Dovetail account.  Would you like to invite them?`,

    //CATEGORIES
    GROCERY: 'Grocery',
    SHOPPING: 'Shopping',
    PRACTICE: 'Practice',
    REMINDER: 'Reminder',
    ALL: 'All',
    FILTER: 'Filter',
    SEARCH: 'Search',

    

}