import React, { useState, useEffect } from "react";
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity } from "react-native-web";
import { LinearGradient } from 'expo-linear-gradient';
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { Bird } from "../../Themed";
import Colors from "../../../constants/Colors";
import Images from "../../../constants/Images";
import GlobalStyles from "../../../styles/GlobalStyles";
import ScreenNames from "../../../constants/ScreenNames";
import Strings from "../../../constants/Strings";

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

interface Props {
    title: string;
    type: string,
    exit: boolean,
    category: string,
    filter: boolean,
    onFilterClick: () => void;
}

const IconHeader: React.FC<Props> = ({ 
        title,
        type,
        exit,
        filter,
        category,
        onFilterClick, 
    }: {
        title?
        type?
        exit?
        filter?
        category?
        onFilterClick?
    }) => { 

    const navigate = useNavigate();

    const goToSettings = () => {
        navigate(ScreenNames.APP_SETTINGS, { replace: false });  
    }
    const goHome = () => {
        navigate(ScreenNames.APP_HOME, { replace: false });  
    }
  

    return (
        <LinearGradient 
            colors={[Colors.background]}
            style={GlobalStyles.appHeader}
        >
            {/* CONTAINER */}
            <View style={[GlobalStyles.appHeaderContainer]}>
                {/* ICON */}
                { type == 'back' && <Pressable onPress={() => navigate(-1)} style={Styles.leftIconContainer}>
                    <Image style={Styles.leftIcon} source={Images.ARROW_LEFT} />
                </Pressable>}

                {/* ICON */}
                { filter && <View style={Styles.leftIconContainer}>
                    <Pressable onPress={() => onFilterClick()} style={[GlobalStyles.iconButtonSmall]}>
                        { category == Strings.GROCERY && <Image style={GlobalStyles.littleIcon} source={Images.SHOPPING_WHITE}></Image>}
                        { category == Strings.PINNED && <Image style={GlobalStyles.littleIcon} source={Images.PIN_WHITE}></Image>}
                        { category == Strings.SHARED && <Image style={GlobalStyles.littleIcon} source={Images.SHARE_WHITE}></Image>}
                        { category == Strings.PRACTICE && <Image style={GlobalStyles.littleIcon} source={Images.PRACTICE_WHITE}></Image>}
                        { category == Strings.SHOPPING && <Image style={GlobalStyles.littleIcon} source={Images.SHOPPING_BAG_WHITE}></Image>}
                        { category == Strings.REMINDER && <Image style={GlobalStyles.littleIcon} source={Images.BELL_WHITE}></Image>}
                        { category == Strings.NOTE && <Image style={GlobalStyles.littleIcon} source={Images.NOTEBOOK_WHITE}></Image>}
                        { category == Strings.SEARCH && <Image style={GlobalStyles.littleIcon} source={Images.SEARCH_WHITE}></Image>}
                    </Pressable>
                </View>}

                {/* TITLE */}
                <Text style={[GlobalStyles.textBrandon, Styles.title]}>{title}</Text>

                {/* LOGO */}                
                { type != 'back' && <Pressable onPress={goHome}>
                    <Bird width={45.51} opacity={1} color={Colors.secondary}/>
                </Pressable>}

                {/* ICON */}
                {!exit && <Pressable onPress={goToSettings} style={Styles.rightIconContainer}>
                    <Image style={Styles.rightIcon} source={Images.SETTINGS} />
                </Pressable>}

                {/* ICON - EXIT */}
                { exit && <Pressable onPress={() => navigate(ScreenNames.APP_HOME, { replace: false })} style={Styles.rightIconContainer}>
                    <Image style={Styles.rightIcon} source={Images.EXIT} />
                </Pressable>}
            </View>           

        </LinearGradient>
    );
}

const Styles = StyleSheet.create({
    leftIconContainer: {
        alignSelf: 'flex-start',
        position: 'absolute'
    },
    rightIconContainer: {
        alignSelf: 'flex-end',
        position: 'absolute'
    },
    leftIcon: {
      width: 24.59,
      height: 24,
      resizeMode: 'contain',      
    },
    rightIcon: {
      width: 24.59,
      height: 24,
      resizeMode: 'contain',      
    },
    title: {
        fontSize: isMobile ? 22 : 25,
        alignSelf: 'flex-start',
        marginLeft: 50
    },
    
  
});



export default IconHeader