import React, { useState } from 'react';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Drawer from "react-bottom-drawer";
import { Text, TouchableOpacity, View } from 'react-native-web';
import GlobalStyles from '../styles/GlobalStyles';
import { TextInput } from './Themed';
import Strings from '../constants/Strings';
import { sendFeatureFeedback } from '../hooks/useAPI';
import useUser from '../hooks/useUser';

export function DevFeedback() {
  const { user } = useUser();
  const [ loading, setLoading ] = useState(false);
  const [ visible, setVisible ] = useState(false);
  const [ feature, setFeature ] = useState('');
  const [ text, setText ] = useState('');

  const submit = async() => {
    setLoading(true);

    if(!text) {
        alert('Feedback required');
        setLoading(false);
        return false;
    }

    try {
        await sendFeatureFeedback({ data: { feature, text, screen: window.location.pathname, user: user.id }});
        setText('');
        setFeature('');
        toast('Feedback Sent!');
    } catch (error) {
        console.log(error);
        alert(error?.message || 'Error submitting feedback');
    } finally {
        setVisible(false);
        setLoading(false);
    }
    
  }

  return (
    <>
        <TouchableOpacity
          onPress={() => setVisible(prev => !prev)}
          style={GlobalStyles.devFeedback}
        >
        <Text
          style={GlobalStyles.devFeedbackText}
        >
            {'Feedback'}
        </Text>
        </TouchableOpacity>

        <Drawer
          isVisible={visible}
          onClose={() => setVisible(false)}
        >
          <View style={GlobalStyles.bottomDrawer}>
            
            <View style={[GlobalStyles.bottomDrawerContainer, { marginBottom: 20 }]}>
              <Text style={[GlobalStyles.drawerTitle, { fontSize: 18 }]}>{'Provide Feedback'}</Text>
              <Text style={[GlobalStyles.drawerTitle, { marginVertical: 8, fontFamily: 'Brandon-Regular' }]}>{'Tell us what you like, dislike or have issues with.  All input is welcome.'}</Text>

              <TextInput
              placeholder='Feature (optional)'
              value={feature}
              onChangeText={setFeature}
              />

              <TextInput
              placeholder='Send us feedback'
              value={text}
              onChangeText={setText}
              style={{
                marginTop: 13
              }}
              />

              {/* SAVE COLOR BUTTON */}
              <TouchableOpacity onPress={submit} disabled={loading} style={[GlobalStyles.secondaryButton, { marginTop: 25, alignSelf: 'center', width: '100%'} ]}>
                <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.SUBMIT}</Text>
              </TouchableOpacity>

            </View>
        
          </View>
        </Drawer>

        <ToastContainer />
    </>
  )
}