
const url = '/app';

export default {
    HOME: '/',    
    SIGNIN: '/signin',  
    SIGNUP_MEMBERS: '/signup/members',
    SIGNUP_FAMILY: '/signup/family',
    SIGNUP_PASSWORD: '/signup/password',
    SIGNUP: '/signup/email',
    SIGNUP_TUTORIAL: '/signup/tutorial',
    SIGNUP_ALLSET: '/signup/allset',
    SIGNUP_SUBSCRIBE: '/signup/subscribe',
    FORGOT_PASSWORD: '/forgot-password',  
    APP_HOME: url,    
    APP_NOTE: `${url}/note`,
    APP_NOTE_VIEW: `${url}/note/:id`,
    APP_NOTE_SHARED: `${url}/note/shared`,
    APP_NOTE_SHARED_VIEW: `${url}/note/shared/:id`,
 
    APP_CATEGORY_LIST: `${url}/notes`,
    APP_SETTINGS: `${url}/settings`,
    APP_SETTINGS_ACCOUNT: `${url}/settings/account`,
    APP_SETTINGS_EMAIL: `${url}/settings/email`,
    APP_SETTINGS_PASSWORD: `${url}/settings/password`,
    APP_SETTINGS_NOTIFICATIONS: `${url}/settings/notifications`,
    APP_SETTINGS_FAMILY: `${url}/settings/family`,
    APP_SETTINGS_LOCATIONS: `${url}/settings/locations`,
    APP_SETTINGS_ADD_LOCATIONS: `${url}/settings/add-location`,
    APP_SETTINGS_UPDATE_LOCATIONS: `${url}/settings/update-location/`,
    APP_SETTINGS_COLOR_PALETTE: `${url}/settings/color-palette`,

    APP_AUTH_GOOGLE_CALENDAR: `${url}/auth/googlecalendar`,
    APP_AUTH_GOOGLE_CALLBACK: `${url}/auth/google/callback`,
    APP_AUTH_GOOGLE_MOBILE_CALLBACK: `${url}/mobile/auth/googlecalendar`,

}