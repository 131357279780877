import React, { useEffect, useState } from 'react';
import { Text, View, Dimensions, StyleSheet, TouchableOpacity } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import GlobalStyles from '../../styles/GlobalStyles';
import { apiFamily, apiUser, apiUserSettings, globalFunctions } from '../../services';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';

import IconHeader from '../../components/Headers/IconHeader';
import ScreenNames from '../../constants/ScreenNames';
import { getMe, updateFamily, updateUser } from '../../hooks/useAPI';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SettingsAccountScreen() {  

    const { user, setUser } = useUser();
    const { token } = useToken();
    
    const [ name, setName ] = useState(() => {
        return user?.name || '';
    });
    const [ nameError, setNameError ] = useState('');
    const [ familyName, setFamilyName ] = useState(() => {
        return user.family.name || '';
    });
    const [ familyNameError, setFamilyNameError ] = useState('');
    const [ phone, setPhone ] = useState(() => {
        return user.phone || '';
    });
    const [ phoneError, setPhoneError ] = useState('');
    const [ successText, setSuccessText ] = useState('');

    const [ formLoading, setFormLoading ] = useState(false);

    const navigate = useNavigate();    
    
    const save = async() => {
        if(!familyName){
            setFamilyNameError(Strings.FAMILY_NAME_REQUIRED);
            return false;
        }
        if(!phone){
            setPhoneError(Strings.PHONE_NUMBER_REQUIRED);
            return false;
        }
        let phoneValid = await globalFunctions.phoneValidation(phone);
        if(!phoneValid){
            setPhoneError(Strings.PHONE_NUMBER_NOT_VALID);
            return false;
        }

        setFormLoading(true);
        
        try {

            await updateUser(user.id, { phone, name });
            await updateFamily(user.family.id, { name: familyName });

            const res = await getMe();

            setUser(res);
            setFormLoading(false);
            setSuccessText(Strings.SUCCESSFULLY_UPDATED);
            await globalFunctions.timeout(5000);
            setSuccessText('');           

        } catch (e) {
            alert(e.message);
            setFormLoading(false);
        } 
        
    }

    if(!token) return navigate(ScreenNames.SIGNIN);
    else
    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} exit={true} title={Strings.ACCOUNT} onFilterClick={() => null} category={''} filter={false} />
            
            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer, { alignSelf: 'center', width: !isMobile ? '50%' : '100%'} ]}>
                
                <View style={[{ marginHorizontal: 25, marginTop: 100 }]}>
                    
                    {/* FORM */}
                    {/* YOUR NAME */}
                    <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 25, marginBottom: 10, color: Colors.accent }]}>{Strings.YOUR_NAME}</Text>
                    {/* INPUT NAME */}
                    <TextInput value={name} onChange={(text) => text && setNameError('')} onChangeText={(text) => setName(text)} placeholder={Strings.YOUR_NAME} style={{ width: '100%'}}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{nameError}</Text>
                    
                    {/* FAMILY NAME */}
                    <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 25, marginBottom: 10, color: Colors.accent }]}>{Strings.FAMILY_NAME}</Text>
                    {/* INPUT FAMILY NAME */}
                    <TextInput value={familyName} onChange={(text) => text && setFamilyNameError('')} onChangeText={(text) => setFamilyName(text)} placeholder={Strings.FAMILY_NAME} style={{ width: '100%' }}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{familyNameError}</Text>
                    
                    {/* PHONE NUMBER */}
                    <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 25, marginBottom: 10, color: Colors.accent }]}>{Strings.PHONE_NUMBER}</Text>
                    {/* INPUT PHONE NUMBER */}
                    <TextInput keyboardType='phone-pad' value={phone} onChange={(text) => text && setPhoneError('')} onChangeText={(text) => setPhone(text)} placeholder={Strings.PHONE_NUMBER} style={{ width: '100%'}}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{phoneError}</Text>

                    {/* SUCCESS MESSAGE */}
                    <Text style={[GlobalStyles.successText, { width: '100%', fontSize: 20, marginTop: 30 }]}>{successText}</Text>
                    
                </View>
            </View>            

            <TouchableOpacity onPress={save} disabled={formLoading} style={[GlobalStyles.secondaryButton, Styles.saveButton, ]}>
                <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.SAVE}</Text>
            </TouchableOpacity>

        </View>
    );
}

const Styles = StyleSheet.create({
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
    },
    icon: {
        width: isMobile ? 25 : 30,
        height: isMobile ? 25 : 30,
        resizeMode: 'contain',   
        alignSelf: "center",    
    },
    label: {
        fontSize: 25,
        marginLeft: 20
    },
    button: { 
        flexDirection: 'row',
        marginBottom: 10,
        paddingVertical: 15
    },
    deleteAccountButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    deleteAccountText: {
        fontSize: 25,        
        color: Colors.black, 
    },
    saveButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    
  
});
  
