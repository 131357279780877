import React, { useEffect, useMemo, useRef,useState } from 'react';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity, ScrollView, SectionList, Platform } from "react-native-web";
import { CheckBox } from 'react-native-web';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import emailValidator from 'email-validator';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Screens from '../../constants/Screens';
import { apiAuth, apiLocation, apiNote, apiUser, globalFunctions } from '../../services';
import PropTypes from 'prop-types';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';

import IconHeader from '../../components/Headers/IconHeader';
import ScreenNames from '../../constants/ScreenNames';
import { createLocation, listLocations, updateLocation } from '../../hooks/useAPI';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SettingsAddLocationScreen() {  

    const { user, setUser } = useUser();
    const { verifyToken } = useToken();

    let note = useQuery().get('note');    
    let lockName = useQuery().get('name');    

    const ID = useParams().id;

    const [ id, setID ] = useState(() => {
        return ID || '';
    });

    const [ name, setName ] = useState(useQuery().get('name'));
    const [ nameError, setNameError ] = useState('');
    
    const [ address, setAddress ] = useState('');
    const [ addressError, setAddressError ] = useState('');

    const [ successText, setSuccessText ] = useState('');
    const [ formLoading, setFormLoading ] = useState(false);
    const [ disableName, setDisableName ] = useState(true);

    const [location, setLocation] = useState<any>({});

    const fetchLocation = async() => {
        try {
            const res = await listLocations({
                filters: {
                    id: ID,
                    user: user.id
                }
            });
            console.log({ res })
            if(res.data?.data && res.data?.data[0]) {
                setName(res?.data[0].attributes.name);
                setAddress(res?.data[0].attributes.address);
                setLocation({ id: res?.data[0].id, ...res.data?.data[0].attributes});
            }
            
        } catch (e) {
            console.log(e.message) ;           
        }
    }

    useEffect(() => {
        (async() => {
            if(ID) await fetchLocation();
            if(lockName) setDisableName(false)
        })();
    },[])

    

    const navigate = useNavigate();   
    
    function useQuery() {
        const { search } = useLocation();
      
        return useMemo(() => new URLSearchParams(search), [search]);
    }  

    const deleteLocation  = async() => {
        if(confirm(Strings.ARE_YOU_SURE_WANT_TO_DELETE_LOCATION)){
            try {
                await apiLocation.delete(ID);
                navigate(ScreenNames.APP_SETTINGS_LOCATIONS)
            } catch (error) {
                console.log({ error })
            }

        }
    }

    const save = async() => {
        
        if(!name) {
            setNameError(Strings.FIELD_REQUIRED);
            return false;
        }

        if(!address) {
            setAddressError(Strings.FIELD_REQUIRED);
            return false;
        }        
        
        setFormLoading(true);

        try {
            if(!location?.id){
                const res = await createLocation({ data: { name, address, user: user.id }});
                if(note) navigate(ScreenNames.APP_NOTE + '/' + note);
                else {
                    setLocation(res);
                    setFormLoading(false);
                    setSuccessText(Strings.SUCCESSFULLY_UPDATED);
                    await globalFunctions.timeout(3000);
                    setSuccessText('');
                }
            } else{
                const res = await updateLocation(location.id, { name , address });
                if(note) navigate(ScreenNames.APP_NOTE + '/' + note);
                else {
                    setLocation(res);
                    setFormLoading(false);
                    setSuccessText(Strings.SUCCESSFULLY_UPDATED);
                    await globalFunctions.timeout(3000);
                    setSuccessText('');
                }
            }

            

        } catch (e) {
            console.log(e.message)            
            setFormLoading(false);
        }       
        
    }   
    

    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} exit={true} title={Strings.ADD_LOCATION} onFilterClick={() => null} category={''} filter={false} />
            
            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer,{  width: !isMobile ? '50%' : '100%'} ]}>
                
                <View style={[{ marginHorizontal: 25, marginTop: 100}]}>
                    
                    {/* FORM */}
                    {/* NAME */}
                    <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 25, marginBottom: 10, color: Colors.accent }]}>{Strings.NAME_OF_LOCATION}</Text>
                    {/* INPUT NAME */}
                    <TextInput editable={disableName} value={name} onChange={(text) => text && setNameError('')} onChangeText={(text) => setName(text)} placeholder={Strings.NAME_OF_LOCATION} style={{ width: '100%'}}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{nameError}</Text>
                    
                    {/* ADDRESS */}
                    <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 25, marginBottom: 10, color: Colors.accent }]}>{Strings.ADDRESS}</Text>
                    {/* INPUT ADDRESS */}
                    <TextInput onKeyPress={(event) => { if(event && event.nativeEvent.key === 'Enter') save(); }} value={address} onChange={(text) => text && setAddressError('')} onChangeText={(text) => setAddress(text)} placeholder={Strings.ADDRESS} style={{ width: '100%' }}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{addressError}</Text>

                    {/* SUCCESS MESSAGE */}
                    <Text style={[GlobalStyles.successText, { width: '100%', fontSize: 20, marginTop: 30 }]}>{successText}</Text>
                    
                </View>
            </View>           

            <TouchableOpacity onPress={save} disabled={formLoading} style={[GlobalStyles.secondaryButton, Styles.saveButton, ]}>
                <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.SAVE}</Text>
            </TouchableOpacity>
            {/* DELETE LOCATION BUTTON */}
            {location?.id && <TouchableOpacity onPress={deleteLocation} style={Styles.deleteLocationButton}>
                <Text style={[ GlobalStyles.textBrandon, Styles.deleteLocationText,]}>{Strings.DELETE_LOCATION}</Text>
            </TouchableOpacity>}

        </View>
    );
}

const Styles = StyleSheet.create({
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
    },
    icon: {
        width: isMobile ? 25 : 30,
        height: isMobile ? 25 : 30,
        resizeMode: 'contain',   
        alignSelf: "center",    
    },
    label: {
        fontSize: 25,
        marginLeft: 20
    },
    button: { 
        flexDirection: 'row',
        marginBottom: 10,
        paddingVertical: 15
    },
    deleteAccountButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    deleteAccountText: {
        fontSize: 25,        
        color: Colors.black, 
    },
    saveButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 80,
    },
    deleteLocationButton: {
        alignSelf: 'center', 
        bottom: 30,
        position: 'absolute', 
    },
    deleteLocationText: {
        fontSize: 20,        
        color: Colors.red, 
    }
    
  
});
  
