import React, { useState, useEffect } from 'react';
import { View } from "react-native-web";
import {
  //BrowserRouter,
  BrowserRouter as Router,
  Routes,
  Route,  
} from "react-router-dom";
import useCachedResources from './hooks/useCachedResources';
import Screens from './constants/Screens';
import GlobalStyles from './styles/GlobalStyles';
import ScreenNames from './constants/ScreenNames';
import GoogleCalendarAuthScreen from './screens/GoogleCalendarAuthScreen';
import { DevFeedback } from './components/Feedback';
import { getMe } from './hooks/useAPI';
import useUser from './hooks/useUser';
import GoogleCalendarAuthMobileScreen from './screens/GoogleCalendarAuthMobileScreen';

function App() {
  const { setUser } = useUser();
  const [showFeedback, setShowFeedback] = useState(process.env.REACT_APP_STAGE === 'dev' ? true : false);
  const isLoadingComplete = useCachedResources();

  useEffect(() => {    
    (async() => {
      try {
        const res = await getMe();
        setUser(res);
      } catch (error) {
        console.log(error);
      }
    })();
  },[]);

  if (!isLoadingComplete) {
    return <View style={GlobalStyles.background}></View>;
  } else {   
      return (
        <>
          {showFeedback && <DevFeedback />}
          <Router>
            <Routes>
              <Route path="/" element={<Screens.IntroScreen />} />
              {/* SIGN UP */}
              <Route path="/signin" element={<Screens.SignInScreen/>} />
              <Route path="/signup/tutorial" element={<Screens.TutorialScreen/>} />
              <Route path="/signup/email" element={<Screens.SignUpEmailScreen />}/>
              <Route path="/signup/password" element={<Screens.SignUpPasswordScreen />}/>

              {/* SIGN UP - LOGGED IN */}
              <Route path="/signup/family" element={<Screens.SignUpFamilyNameScreen />}/>
              <Route path="/signup/members" element={<Screens.SignUpFamilyMembersScreen />}/>
              <Route path="/signup/allset" element={<Screens.SignUpAllSetScreen />}/>
              <Route path="/signup/subscribe" element={<Screens.SignUpSubscribeScreen />}/>
              <Route path="/forgot-password" element={<Screens.ForgotPasswordScreen />}/>

              {/* APP - LOGGED IN */}
              <Route path={ScreenNames.APP_HOME} element={<Screens.DashboardScreen />}/>
              <Route path={ScreenNames.APP_NOTE_VIEW} element={<Screens.NoteScreen />}/>
              <Route path={ScreenNames.APP_NOTE_SHARED_VIEW} element={<Screens.NoteScreen />}/>
              <Route path={ScreenNames.APP_CATEGORY_LIST} element={<Screens.CategoryListScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS} element={<Screens.SettingsScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS_ACCOUNT} element={<Screens.SettingsAccountScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS_EMAIL} element={<Screens.SettingsEmailScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS_PASSWORD} element={<Screens.SettingsPasswordScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS_NOTIFICATIONS} element={<Screens.SettingsNotificationScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS_FAMILY} element={<Screens.SettingsFamilyScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS_COLOR_PALETTE} element={<Screens.SettingsColorScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS_LOCATIONS} element={<Screens.SettingsLocationsScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS_ADD_LOCATIONS} element={<Screens.SettingsAddLocationScreen />}/>
              <Route path={ScreenNames.APP_SETTINGS_UPDATE_LOCATIONS + ':id'} element={<Screens.SettingsAddLocationScreen />}/>

              {/* AUTH */}
              <Route path={ScreenNames.APP_AUTH_GOOGLE_CALENDAR} element={<GoogleCalendarAuthScreen />}/>
              <Route path={ScreenNames.APP_AUTH_GOOGLE_CALLBACK} element={<GoogleCalendarAuthScreen />}/>
              <Route path={ScreenNames.APP_AUTH_GOOGLE_MOBILE_CALLBACK} element={<GoogleCalendarAuthMobileScreen />}/>

              {/* NO ROUTE*/}
              <Route path="*" element={<Screens.SignInScreen/>} />

            </Routes>
          </Router>
        </>        
      );
  }
  
}

export default App;
