import React, { useEffect } from 'react';
import _ from 'lodash';
import { Image, Text, View, Dimensions, StyleSheet, TouchableOpacity, ScrollView } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';
import { GoogleSignInButton } from '../../components/GoogleSignInButton';
import IconHeader from '../../components/Headers/IconHeader';
import ScreenNames from '../../constants/ScreenNames';
import { createPortalSession } from '../../hooks/useAPI';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SettingsScreen() {  

    const { user, subscriptionActive, logOut } = useUser();
    const { token } = useToken();

    const navigate = useNavigate();    
    
    const deleteAccount = () => {
        if(confirm(Strings.ARE_YOU_SURE_WANT_TO_DELETE_ACCOUNT)){
            // navigate(ScreenNames.SIGNIN, { replace: false })
        }
    }
    const logout = () => {
        logOut()
        navigate(ScreenNames.SIGNIN, { replace: false })
    }
    
    const manageAccount = async() => {
        try {
            const res = await createPortalSession({
                return_url: `https://${window.location.hostname}${ScreenNames.APP_SETTINGS}`
            });
            window.location.href = res.url;
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {  
        if(!token) return navigate(ScreenNames.SIGNIN);
        //else if(!subscriptionActive) return navigate(ScreenNames.APP_SETTINGS, { replace: false });
    },[]); 

    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} exit={true} title={Strings.SETTINGS} category={''} filter={false} onFilterClick={() => null} />
            
            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer, ]}>                
                <ScrollView showsVerticalScrollIndicator={false} style={[{ marginHorizontal: 25, marginTop: 100, height: isMobile ? 600 : '100%' }]}>
                    {/* LIST SECTION */} 
                    <TouchableOpacity onPress={() => navigate(ScreenNames.APP_SETTINGS_ACCOUNT, { replace: false })} style={Styles.button}>
                        <Image style={Styles.icon} source={Images.USER} />
                        <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.ACCOUNT}</Text>
                    </TouchableOpacity> 
                    <TouchableOpacity onPress={manageAccount} style={Styles.button}>
                        <Image style={Styles.icon} source={Images.BOX_OPEN} />
                        <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.MANAGE_SUBSCRIPTION}</Text>
                        {!subscriptionActive && <View style={{ paddingHorizontal: 5, paddingVertical: 2, backgroundColor: Colors.red, borderRadius: 5, alignItems: 'center', justifyContent: 'center', height: 20, marginLeft: 15 }}>
                            <Text style={[{ fontFamily: 'Brandon-Bold', fontSize: 12, color: Colors.white }]}>{_.capitalize(user.subscription_status)}</Text>
                        </View>}
                    </TouchableOpacity> 
                    <TouchableOpacity onPress={() => navigate(ScreenNames.APP_SETTINGS_FAMILY, { replace: false })} style={Styles.button}>
                        <Image style={Styles.icon} source={Images.FAMILY_MEMBERS} />
                        <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.UPDATE_FAMILY_MEMBERS}</Text>
                    </TouchableOpacity> 
                    <TouchableOpacity onPress={() => navigate(ScreenNames.APP_SETTINGS_NOTIFICATIONS, { replace: false })} style={Styles.button}>
                        <Image style={Styles.icon} source={Images.NOTIFICATIONS} />
                        <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.NOTIFICATIONS}</Text>
                    </TouchableOpacity> 
                    <TouchableOpacity onPress={() => navigate(ScreenNames.APP_SETTINGS_COLOR_PALETTE, { replace: false })} style={Styles.button}>
                        <Image style={Styles.icon} source={Images.PALETTE} />
                        <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.COLOR_PALETTE}</Text>
                    </TouchableOpacity> 
                    <TouchableOpacity onPress={() => navigate(ScreenNames.APP_SETTINGS_LOCATIONS, { replace: false })} style={Styles.button}>
                        <Image style={Styles.icon} source={Images.LOCATIONS} />
                        <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.LOCATIONS}</Text>
                    </TouchableOpacity> 
                    <TouchableOpacity onPress={() => navigate(ScreenNames.APP_SETTINGS_EMAIL, { replace: false })} style={Styles.button}>
                        <Image style={Styles.icon} source={Images.ENVELOPE} />
                        <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.CHANGE_EMAIL_ADDRESS}</Text>
                    </TouchableOpacity> 
                    <TouchableOpacity onPress={() => navigate(ScreenNames.APP_SETTINGS_PASSWORD, { replace: false })} style={Styles.button}>
                        <Image style={Styles.icon} source={Images.KEY} />
                        <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.CHANGE_PASSWORD}</Text>
                    </TouchableOpacity>
                    <GoogleSignInButton onSuccess={() => null} type='settings' />
                    <TouchableOpacity onPress={logout} style={Styles.button}>
                        <Image style={Styles.icon} source={Images.LOG_OUT} />
                        <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.LOG_OUT}</Text>
                    </TouchableOpacity> 
                </ScrollView>
            </View>

            {/* DELETE ACCOUNT BUTTON */}
            <TouchableOpacity onPress={deleteAccount} style={Styles.deleteAccountButton}>
                <Text style={[ GlobalStyles.textBrandon, Styles.deleteAccountText,]}>{Strings.DELETE_ACCOUNT}</Text>
            </TouchableOpacity>

        </View>
    );
}

const Styles = StyleSheet.create({
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
    },
    icon: {
        width: isMobile ? 25 : 30,
        height: isMobile ? 25 : 30,
        resizeMode: 'contain',   
        alignSelf: "center",    
    },
    label: {
        fontSize: 25,
        marginLeft: 20
    },
    button: { 
        flexDirection: 'row',
        marginBottom: 10,
        paddingVertical: 15
    },
    deleteAccountButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    deleteAccountText: {
        fontSize: 25,        
        color: Colors.red, 
    }
    
  
});
  
