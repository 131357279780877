import React, { useEffect, useState, useRef, useContext } from 'react';
import _ from 'lodash';
import { Image, Text, View, Dimensions, StyleSheet, TouchableOpacity, FlatList } from "react-native-web";
import { TextInput, Bird } from '../../components/Themed';
import { Link, useNavigate } from "react-router-dom";
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Colors from '../../constants/Colors';
import useUser from '../../hooks/useUser';
import ScreenNames from '../../constants/ScreenNames';
import { createMember, listFamilies } from '../../hooks/useAPI';

const PLUS_SMALL = require('../../assets/icons/plus-small.svg');
//const ARROW_RIGHT_WHITE = require('../assets/icons/arrow-right-white.svg');

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default function SignUpFamilyMembersScreen() {

    const { family, setFamilies } = useUser();

    const navigate = useNavigate();
    const [nextButtonLoading, setNextButtonLoading] = useState(false);

    const [familyMembersError, setFamilyMembersError] = useState('');

    const [parent, setParent] = useState<any>('');
    const [parents, setParents] : any = useState([]);

    const [parentAutoFocus, setParentAutoFocus] = useState(true);
    const parentInput: any = useRef(null);

    const [child, setChild] = useState<any>('');
    const [children, setChildren]: any = useState([]);
    const [childAutoFocus, setChildAutoFocus] = useState(false);
    const childInput: any = useRef(null);

    const [relative, setRelative] = useState<any>('');
    const [relatives, setRelatives]: any = useState([]);
    const [relativeAutoFocus, setRelativeAutoFocus] = useState(false);
    const relativeInput: any = useRef(null);

    const fetchFamilies = async() => {
        try {
            const res = await listFamilies({});
            setFamilies(res.data);
        } catch (error) {
            console.log({ error });
            return error;
        }
    }

    useEffect(() => {
        let par : any = [], chl : any  = [], rel : any  = [];
        _.map(family?.members, member => {
            if(member.relation === 'parent') {
                par.push(member);
            }
            else if(member.relation === 'child') {
                chl.push(member);
            }
            else if(member.relation === 'other') {
                rel.push(member);
            }
        });
        setParents(par);
        setChildren(chl);
        setRelatives(rel);
       
    },[]);

    const submitForm = async() => {

        if((parents.length == 0) && (children.length == 0) && (relatives.length == 0)){
            setFamilyMembersError(Strings.FAMILY_MEMBERS_EMPTY);
            return false;
        }
        setNextButtonLoading(true);

        const members = [...parents, ...children, ...relatives];

        //add to family
        try {
            for await (const member of members) {
                await createMember({ data: member });
            }

            await fetchFamilies();
           
            // if(!user?.registered){    
            //     const res = await updateUser(user.id, { registered: true });
            //     setUser(res);
            // }          
            navigate(ScreenNames.SIGNUP_SUBSCRIBE, { replace: false });  
            
        } catch(e) {
            setNextButtonLoading(false);
            setFamilyMembersError(e.message);
        }
       
    }


    // RENDER CHIP
    const _renderChip = (item: any, index: number, type: any) => {   
        return (
            <View style={[GlobalStyles.chip, GlobalStyles.chipShadow, { backgroundColor: item.color, margin: 5 }]}>
                <Text style={GlobalStyles.chipText}>{item.name}</Text>
                <TouchableOpacity style={GlobalStyles.chipMinus} onPress={() =>_removeChip(item, type)}>
                    <Image style={[GlobalStyles.inputAddButtonIcon]} source={Images.MINUS_SMALL} />
                </TouchableOpacity>
            </View>
        );
    }

    const _removeChip = (person, type) => {       

        if(type == 'parent') {
            let filtered = parents.filter(item => item.name !== person.name);
            console.log({ filtered})
            setParents(filtered);
        }
        if(type == 'child') {
            let filtered = children.filter(item => item.name !== person.name);
            setChildren(filtered);
        }
        if(type == 'relative') {
            let filtered = relatives.filter(item => item.name !== person.name);
            setRelatives(filtered);
        }
        
    }

    //ADD PARENT TO LIST
    const addParent = async(event) => {
        if((parent && !event.key) || (parent && (event.key === 'Enter'))) {
            //setParents([...parents, { name: parent, relation: 'parent', color: randomColor(randomColorParams), family: family.id, active: false}]);            
            setParents([...parents, { name: parent, relation: 'parent', color: Colors.secondary, family: family.id, active: false}]);            
            setParent('');
            setTimeout(() => parentInput.current.focus(), 10);            
        }
        
    }

    //ADD CHILD TO LIST
    const addChild = (event) => {
        if((child && !event.key) || (child && (event.key === 'Enter'))) {
            setChildren([...children, { name: child, relation: 'child', color: Colors.secondary, family: family.id, active: false}]);
            setChild('');
            setTimeout(() => childInput.current.focus(), 10);
        }        
    }

    //ADD RELATIVE TO LIST
    const addRelative = (event) => {
        if((relative && !event.key) || (relative && (event.key === 'Enter'))) {
            setRelatives([...relatives, { name: relative, relation: 'other', color: Colors.secondary, family: family.id, active: false}]);
            setRelative('');
            setTimeout(() => relativeInput.current.focus(), 10);
        }        
    }

    

    return (
        <View style={GlobalStyles.background}> 
        {/* SITE CONTAINER */}
        <View style={GlobalStyles.container}>

            {/* TOP ACTION HEADER */}
            <View style={GlobalStyles.topActionHeader}>
                {/* BACK ARROW */}
                <Link style={{ textDecoration: 'none', left: 0,}} to='/signup/family'>
                    <Image style={[GlobalStyles.leftArrowIcon]} source={Images.ARROW_LEFT} />
                </Link>
                {/* EXIT */}
                <Link style={{ textDecoration: 'none'}} to={{ pathname: '/' }}>
                    <Image style={[GlobalStyles.exitIcon, { position: 'absolute', right: 0,}]} source={Images.EXIT} />
                </Link>
            </View>

            {/* SIGN UP CONTAINER */}
            <View style={GlobalStyles.signUpContainer}>
                {/* PROGRESS */}
                <View style={GlobalStyles.progressDotsRow}>
                    <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                    <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                    <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                    <View style={GlobalStyles.progressLongDot} />
                    <View style={GlobalStyles.progressDot} />
                </View>

                {/* DESCRIPTION */}
                <Text style={GlobalStyles.signUpFlowDescription}>{Strings.SIGNUP_FAMILY_MEMBERS_DESCRIPTION}</Text>
                
                {/* TITLE */}
                <Text style={GlobalStyles.signUpFlowTitle}>{Strings.FAMILY_MEMBERS}</Text>

                {/* ERROR MESSAGE */}
                <Text style={GlobalStyles.errorText}>{familyMembersError}</Text>

                {/* FAMILY MEMBERS */}
                <View style={{ marginTop: 100, marginBottom: 70}}>
                    {/* PARENTS */}
                    <View>
                        {/* INPUT */}
                        <TextInput Ref={parentInput} value={parent} onKeyPress={addParent} autoFocus={parentAutoFocus} onChangeText={(name) => setParent(name)} placeholder={Strings.PARENTS} focu></TextInput>
                        <TouchableOpacity onPress={addParent} style={[GlobalStyles.inputAddButton, { position: 'relative', alignSelf: 'flex-end', bottom: 40, zIndex: 2 }]}>
                            {/* <Image style={[GlobalStyles.inputAddButtonIcon]} source={Images.PLUS_SMALL} /> */}
                            <PLUS_SMALL width={20} height={20} />
                        </TouchableOpacity>
                        {/* CHIPS */}                        
                        <FlatList 
                            style={{ marginTop: -10, marginLeft: -5, }}
                            contentContainerStyle={{flexDirection : "row", flexWrap : "wrap"}}  
                            data={parents}
                            //keyExtractor={(item, index) => item.id.toString()}
                            renderItem={({item, index}) => _renderChip(item, index, 'parent')}>
                        </FlatList> 
                    </View>
                    {/* CHILDREN */}
                    <View style={{ marginTop: 35 }}>
                        {/* INPUT */}
                        <TextInput Ref={childInput} value={child} onKeyPress={addChild} autoFocus={childAutoFocus} onChangeText={(name) => setChild(name)} placeholder={Strings.CHILDREN}></TextInput>
                        <TouchableOpacity onPress={addChild} style={[GlobalStyles.inputAddButton, { position: 'relative', alignSelf: 'flex-end', bottom: 40, zIndex: 2 }]}>
                            <Image style={[GlobalStyles.inputAddButtonIcon]} source={Images.PLUS_SMALL} />
                        </TouchableOpacity>
                        {/* CHIPS */}                        
                        <FlatList 
                            style={{ marginTop: -10, marginLeft: -5, }}
                            contentContainerStyle={{flexDirection : "row", flexWrap : "wrap"}}  
                            data={children}
                            //keyExtractor={(item, index) => item.id.toString()}
                            renderItem={({item, index}) => _renderChip(item, index, 'child')}>
                        </FlatList> 
                    </View>
                    {/* RELATIVES */}
                    <View style={{ marginTop: 35 }}>
                        {/* INPUT */}
                        <TextInput Ref={relativeInput} value={relative} onKeyPress={addRelative} autoFocus={relativeAutoFocus} onChangeText={(name) => setRelative(name)} placeholder={Strings.RELATIVES}></TextInput>
                        <TouchableOpacity onPress={addRelative} style={[GlobalStyles.inputAddButton, { position: 'relative', alignSelf: 'flex-end', bottom: 40, zIndex: 2 }]}>
                            <Image style={[GlobalStyles.inputAddButtonIcon]} source={Images.PLUS_SMALL} />
                        </TouchableOpacity>
                        {/* CHIPS */}                        
                        <FlatList 
                            style={{ marginTop: -10, marginLeft: -5, }}
                            contentContainerStyle={{flexDirection : "row", flexWrap : "wrap"}}  
                            data={relatives}
                            //keyExtractor={(item, index) => item.id.toString()}
                            renderItem={({item, index}) => _renderChip(item, index, 'relative')}>
                        </FlatList> 
                    </View>
                </View>

                {/* BIRD */}
                <Bird style={{ position: 'absolute', left: -width / 5.5, bottom: height / 8, zIndex: -1}}/>                  

                {/* ACTION BUTTONS */}
                <View style={[Styles.actionButtonContainer]}>                    
                    <TouchableOpacity disabled={nextButtonLoading} onPress={submitForm} style={[GlobalStyles.signUpNextButton, GlobalStyles.buttonDropShadowCircle, { alignSelf:'flex-end', opacity: nextButtonLoading && 0.5, }]}>
                        <Image style={[GlobalStyles.nextArrowIcon]} source={Images.ARROW_RIGHT_WHITE} />
                    </TouchableOpacity>
                </View>

            </View>

        
        </View>
        </View>
    );
}

const Styles = StyleSheet.create({
    
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        marginTop:10,
        bottom: height / 50, 
        alignItems: 'center',
        zIndex: 0,
    },
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
        //backgroundColor: 'blue',
    }
    
  
  });
  