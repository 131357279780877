import axios from 'axios';
import uniqid from 'uniqid';
import Routes from './routes';

const baseURL = process.env.REACT_APP_API_BASEURL;
const { REACT_APP_API_BASEURL } = process.env;

export const apiAuth = {
    login: (async (payload: any) => {
        return await axios.post(REACT_APP_API_BASEURL + '/auth/local', payload)
    }),  

    register: (async ({ username, email, password }) => {
        return await axios.post(REACT_APP_API_BASEURL + '/auth/local/register', { username, email, password })
    }), 

    forgotPassword: (async (payload: any) => {
        return await axios.post(REACT_APP_API_BASEURL + '/auth/forgot-password', payload)
    }),  

    resetPassword: (async (payload: any) => {
        return await axios.post(REACT_APP_API_BASEURL + '/auth/reset-password', payload)
    }),
}



