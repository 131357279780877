
const white = '#ffffff';
const black = '#000000';
const background = 'rgba(194,222,255, 0.3)';
const secondary = '#172536';
const primary = '#C2DEFF';
const accent = '#69AEFF';
const red = '#E30000';
const green = '#00D018';
const gray1 = '#707070';
const gray2 = '#767474';
const gray3 = '#7C7C79';
const gray4 = '#585555';
const gray5 = '#808080';
const offBlack = '#040404';
const offWhite = '#F9F9F9';
const blueAccent = '#F2F8FF';
const chips = ['#FFe6E6','#C7FDFF','#E6D3FF','#FF9191','#ED88FF','#FFFFBE','#FFDEDE','#BCBCBC'];
const lightBlueAccent = '#f2f8ff';
const linkBlue = '#007fc7';
const noteGray = '#eaecee';

export default {
    light: {},
    dark: {},
    white,
    black,
    background,
    secondary,
    primary,
    accent,
    red,
    green,
    gray1,
    gray2,
    gray3,
    gray4,
    offBlack,
    offWhite,
    chips,
    blueAccent,
    gray5,
    lightBlueAccent,
    linkBlue,
    noteGray,
}