import React from 'react';
import axios from 'axios';
import qs from 'qs';
import useUser from '../hooks/useUser';
import { TouchableOpacity, Image, Text, StyleSheet, Dimensions } from 'react-native-web';
import GlobalStyles from '../styles/GlobalStyles';
import Images from '../constants/Images';
import Strings from '../constants/Strings';
import { isMobile } from 'react-device-detect';

const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export const GoogleSignInButton = ({ onSuccess, type } : { onSuccess, type? }) => {
  const handleSignInClick = () => {
    const options: any = {
      redirect_uri: process.env.REACT_APP_PUBLIC_DOMAIN + '/app/auth/googlecalendar',
      client_id: process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
      access_type: 'offline',
      response_type: 'code',
      prompt: 'consent',
      scope: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/calendar',
        'https://www.googleapis.com/auth/calendar.events',
      ].join(' '),
      //state: from,
    };
  
    const qs = new URLSearchParams(options);
  
    window.location.href = `${rootUrl}?${qs.toString()}`;
  };
  if(type === 'settings') return (
    <TouchableOpacity onPress={handleSignInClick} style={Styles.button}>
      <Image style={Styles.icon} source={Images.GOOGLE} />
      <Text style={[GlobalStyles.textBrandon, Styles.label]}>{Strings.SYNC_GOOGLE_CALENDAR}</Text>
    </TouchableOpacity>
  )
  else
  return (
    <button onClick={handleSignInClick}>
      Sign in with Google
    </button>
  );
};

const Styles = StyleSheet.create({
  icon: {
    width: isMobile ? 25 : 30,
    height: isMobile ? 25 : 30,
    resizeMode: 'contain',   
    alignSelf: "center",    
  },
  label: {
    fontSize: 25,
    marginLeft: 20
  },
  button: { 
    flexDirection: 'row',
    marginBottom: 10,
    paddingVertical: 15
  },
  

});

interface GoogleOauthToken {
  access_token: string;
  id_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
  scope: string;
}

export const getGoogleOauthToken = async ({
  code,
  user,
}: {
  code: string;
  user: any;
}): Promise<GoogleOauthToken> => {
    const rootURl = 'https://oauth2.googleapis.com/token';  
    const options = {
      code,
      client_id: process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_SECRET,
      redirect_uri: process.env.REACT_APP_PUBLIC_DOMAIN + '/app/auth/googlecalendar',
      grant_type: 'authorization_code',
    };
    try {
      if(user?.access_token) await getGoogleOauthRevokeToken();
      const { data } = await axios.post<GoogleOauthToken>(
        rootURl,
        qs.stringify(options),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      const expires_at = Date.now() + (data.expires_in * 1000);
      localStorage.setItem('g_expires_at', expires_at.toString());

      return data;
    } catch (err: any) {
      console.log('Failed to fetch Google Oauth Tokens');
      throw new Error(err);
    }
};

export const getGoogleOauthRefreshToken = async ({ user }): Promise<GoogleOauthToken> => {
  const rootURl = 'https://oauth2.googleapis.com/token';

  const options = {
    client_id: process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
    client_secret: process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_PUBLIC_DOMAIN + '/app/auth/googlecalendar',
    grant_type: 'refresh_token',
    refresh_token: user.refresh_token,
  };
  try {
    console.log({ options })
    const { data } = await axios.post<GoogleOauthToken>(
      rootURl,
      qs.stringify(options),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    console.log({ refresh: data })
    const expires_at = Date.now() + (data.expires_in * 1000);
    localStorage.setItem('g_expires_at', expires_at.toString());

    return data;
  } catch (err: any) {
    console.log('Failed to fetch Google Oauth Tokens');
    throw new Error(err);
  }
};

export const getGoogleOauthRevokeToken = async (): Promise<GoogleOauthToken> => {
    const rootURl = 'https://oauth2.googleapis.com/revoke';

    const { getUser } = useUser();
    const options = {
      token: getUser().access_token
    };
    try {
      const { data } = await axios.post<GoogleOauthToken>(
        rootURl,
        qs.stringify(options),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
  
      return data;
    } catch (err: any) {
      console.log('Failed to fetch Google Oauth Tokens');
      throw new Error(err);
    }
  };

interface GoogleUserResult {
  id: string;
  email: string;
  verified_email: boolean;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  locale: string;
}

export async function getGoogleUser({
  id_token,
  access_token,
}: {
  id_token: string;
  access_token: string;
}): Promise<GoogleUserResult> {
  try {
    const { data } = await axios.get<GoogleUserResult>(
      `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`,
      {
        headers: {
          Authorization: `Bearer ${id_token}`,
        },
      }
    );

    return data;
  } catch (err: any) {
    console.log(err);
    throw Error(err);
  }
}

export const googleCalendarList = async() => {
  const url = 'https://www.googleapis.com/calendar/v3/users/me/calendarList'
  const google_user = JSON.parse(localStorage.getItem('google_user'));
  const request : any = {
    method: 'get',
    url,
    headers: { Authorization: `Bearer ${google_user.access_token}`, 'Content-Type': 'application/json' },
    // params: {
    //   key: process.env.REACT_APP_GOOGLE_API_KEY
    // }
  }
  return await axios(request);
}

export const googleCalendarCreateEvent = async(calendarId, event) => {
  const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`
  const google_user = JSON.parse(localStorage.getItem('google_user'));
  const request : any = {
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${google_user.access_token}`, 'Content-Type': 'application/json' },
    data: event,
    params: {
      sendUpdates: 'all'
    }
  }
  return await axios(request);
}

export const googleCalendarUpdateEvent = async(calendarId, eventId, event) => {
  const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`
  const google_user = JSON.parse(localStorage.getItem('google_user'));
  const request : any = {
    method: 'put',
    url,
    headers: { Authorization: `Bearer ${google_user.access_token}`, 'Content-Type': 'application/json' },
    data: event,
  }
  return await axios(request);
}

export const googleCalendarDeleteEvent = async(calendarId, eventId) => {
  const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`
  const google_user = JSON.parse(localStorage.getItem('google_user'));
  const request : any = {
    method: 'delete',
    url,
    headers: { Authorization: `Bearer ${google_user.access_token}`, 'Content-Type': 'application/json' },
  }
  return await axios(request);
}

export const googleCalendarDeleteEvents = async(calendarId, query, nextPageToken = null) => {
  const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`;
  const google_user = JSON.parse(localStorage.getItem('google_user'));
  const headers = { Authorization: `Bearer ${google_user.access_token}`, 'Content-Type': 'application/json' };

  // Add the nextPageToken to the query parameters if it exists
  if (nextPageToken) {
    query.pageToken = nextPageToken;
  }

  const request : any = {
    method: 'get',
    url,
    headers,
    params: query,
  };

  try {
    const response = await axios(request);
    const data = response.data;
    console.log('data >>', data)
    let events = [];

    // Add the items to the events array
    if (data.items) {
      events = events.concat(data.items);
    }

    // If there are more pages of events, fetch the next page
    if (data.nextPageToken) {
      const nextPageEvents = await googleCalendarDeleteEvents(calendarId, query, data.nextPageToken);
      events = events.concat(nextPageEvents);
    }

    // Return the full list of events
    return events;
  } catch (error) {
    console.error(error);
    return [];
  }
};


