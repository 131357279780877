import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Text, View, Pressable, FlatList, StyleSheet, TouchableOpacity } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import randomColor from 'randomcolor';
import Drawer from "react-bottom-drawer";
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import GlobalStyles from '../../styles/GlobalStyles';
import { globalFunctions } from '../../services';
import useUser from '../../hooks/useUser';
import IconHeader from '../../components/Headers/IconHeader';
import ScreenNames from '../../constants/ScreenNames';
import { updateUser } from '../../hooks/useAPI';

// const width = Dimensions.get('window').width;
// const height = Dimensions.get('window').height;


export default function SettingsColorScreen() {  

    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const [ category, setCategory ] = useState('');
    const [ color, setColor ] = useState('');
    const [ cIndex, setCIndex ] = useState('');
    const [ updateError, setUpdateError ] = useState('');
    
    const [ categoryColors, setCategoryColors ] = useState(user?.noteColors || [
        {
            category: Strings.NOTE,
            color: '#fff',
        },
        {
            category: Strings.GROCERY,
            color: '#bcffbf',
        },
        {
            category: Strings.SHOPPING,
            color: '#ade0ff',
        },
        {
            category: Strings.REMINDER,
            color: '#fff3bc',
        },
        {
            category: Strings.PRACTICE,
            color: '#f2c8ff',
        },
    ]);

    const randomColorParams = {
        luminosity: 'dark', 
        format: 'rgba', 
        alpha: 0.5,
        count: 10,
    }

    const [randomColors, setRandomColors] = useState<any>([]);

    const [bottomDrawerVisible, setBottomDrawerVisible] = useState(false);

    const [ successText, setSuccessText ] = useState('');
    const [ formLoading, setFormLoading ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const onBottomDrawerClose = useCallback(() => {
        setBottomDrawerVisible(false);
    }, []);

    const save = async() => {

        try {
            setFormLoading(true);

            const newColors = [...categoryColors];

            newColors[cIndex].color = color;

            setCategoryColors(newColors);

            const res = await updateUser(user.id, {
                noteColors: newColors
            });

            setUser(res);

            setBottomDrawerVisible(false);
            // setSuccessText(Strings.SUCCESSFULLY_UPDATED);
            // await globalFunctions.timeout(5000);
        } catch (error) {
            console.log({ error })
            setUpdateError('Error updating category color.');
            
        } finally {
            //setSuccessText('');
            setUpdateError('');
            setFormLoading(false);
        }
        
    }

    const _renderColorCircle = (item: any, index: number) => {    
        if(color === item){
            return (
                <View style={[GlobalStyles.circle, { backgroundColor: item, marginRight: 10, borderWidth: 2, borderColor: Colors.black }]}></View>
            );
        }  else {
            return (
                <Pressable onPress={() => setColor(item)} style={{ marginRight: 10,}}>
                    <View style={[GlobalStyles.circle, { backgroundColor: item, }]}></View>
                </Pressable>
            );
        } 
        
    }

    useEffect(() => {  
        (async() => {
        })();
    },[]);

    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} exit={true} title={Strings.COLOR_PALETTE} category={''} filter={false} onFilterClick={() => null}/>
            
            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer,{  width: !isMobile ? '50%' : '100%'} ]}>
                
                <View style={[{ marginHorizontal: 25, marginTop: 60}]}>
                    
                    {/* FORM */}
                    {/* ERROR MESSAGE */}
                    <Text style={GlobalStyles.errorText}>{updateError}</Text>

                    {/* COLOR PALETTES */}
                    <View style={{ marginTop: 20}}>

                        <Text style={[GlobalStyles.textBrandon, Styles.subtitle]}>{'Customize your category colors for your notes.'}</Text>

                        <FlatList
                            showsVerticalScrollIndicator={false}
                            data={categoryColors}
                            numColumns={3}
                            onEndReachedThreshold={0.5}
                            columnWrapperStyle={{ justifyContent: 'space-between' }}
                            renderItem={({ item, index }) => <View key={item.category} style={{ marginBottom: 35 }}>
                            <Text style={[GlobalStyles.textBrandon, Styles.category]}>{item.category}</Text>
                            <Pressable onPress={() => {
                                setCategory(item.category);
                                setColor(item.color);
                                setCIndex(index);
                                setRandomColors([item.color, ...randomColor(randomColorParams)])
                                setBottomDrawerVisible(true);
                            }} style={{ marginRight: 10,}}>
                                <View style={[GlobalStyles.circle, { backgroundColor: item.color, borderColor: '#d3d3d3', borderWidth: 1 }]}></View>
                            </Pressable>
                        </View>}
                            keyExtractor={(index) => index.toString()}
                        />
                    </View>                 

                    {/* SUCCESS MESSAGE */}
                    <Text style={[GlobalStyles.successText, { width: '100%', fontSize: 20, marginTop: 30 }]}>{successText}</Text>
                    
                </View>
            </View>           

            <TouchableOpacity onPress={() => navigate(ScreenNames.APP_SETTINGS)} style={[GlobalStyles.secondaryButton, Styles.saveButton, ]}>
                <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.DONE}</Text>
            </TouchableOpacity>

            {/* BOTTOM DRAWER */}
            <Drawer
                isVisible={bottomDrawerVisible}
                onClose={onBottomDrawerClose}
            >
                <View style={GlobalStyles.bottomDrawer}>
                    
                    {/* CATEGORY */}  
                    <Text style={[GlobalStyles.textBrandon, Styles.name]}>{category}</Text>
                    
                    <View style={[GlobalStyles.bottomDrawerContainer]}>                        

                        {/* COLOR PALETTE */}
                        <View style={{alignSelf: 'center', width: isMobile && '100%', marginTop: 20}}>
                            <FlatList 
                                showsHorizontalScrollIndicator={false}
                                style={{ marginTop: 20, marginBottom: 20, }}
                                data={randomColors}
                                horizontal={true}
                                keyExtractor={(item, index) => index.toString()}
                                renderItem={({item, index}) => _renderColorCircle(item, index)}>
                            </FlatList> 
                        </View>

                        {/* SAVE COLOR BUTTON */}
                        <TouchableOpacity onPress={save} disabled={formLoading} style={[GlobalStyles.secondaryButton, { marginTop: 25, alignSelf: 'center'} ]}>
                            <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.SAVE}</Text>
                        </TouchableOpacity>

                        {/* CANCEL */}
                        <TouchableOpacity onPress={() => setBottomDrawerVisible(false)} style={Styles.deleteProfileButton}>
                            <Text style={[ GlobalStyles.textBrandon, Styles.deleteProfileText,]}>{Strings.CANCEL}</Text>
                        </TouchableOpacity>
                        
                    </View>
                </View>
            </Drawer>

        </View>
    );
}

const Styles = StyleSheet.create({
    
    category: {
        fontSize: 17,
        marginBottom: 10
    },
    subtitle: {
        fontSize: 20,
        marginBottom: 40
    },
    name: {
        fontSize: 25,
        alignSelf: 'flex-start',
        marginLeft: 8,
        marginBottom: 3
    },
    button: { 
        flexDirection: 'row',
        marginBottom: 10,
        paddingVertical: 15
    },
    saveButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    deleteProfileButton: {
        alignSelf: 'center', 
        marginBottom: 25,
        marginTop: 20,
    },
    deleteProfileText: {
        fontSize: 20,        
        color: Colors.red, 
    }
    
  
});
  
