import React, { useEffect, useMemo } from "react";
import { View } from "react-native-web";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import Colors from '../../constants/Colors';
import { Bird } from "../../components/Themed";
import GlobalStyles from '../../styles/GlobalStyles';
import { getGoogleOauthToken } from "../../components/GoogleSignInButton";
import ScreenNames from "../../constants/ScreenNames";
import { updateUser } from "../../hooks/useAPI";
import { apiGoogle } from "../../services";

export default function GoogleCalendarAuthMobileScreen(){
    const navigate = useNavigate();
    const { setGoogleUser, getUser, setUser } = useUser();      
    const { search } = useLocation();
    function useQuery() {
        return useMemo(() => new URLSearchParams(search), [search]);
    }      
    const code: any = useQuery().get('code');

    useEffect(() => {
      (async() => {
        try {
            console.log({ code })
          if(code) {
            //alert(code)
            window.location.replace('dovetail://Note?code=' + code);
            //const { data } = await apiGoogle.getToken({ code });
            // const res = await apiGoogle.subscribeToCalendar()
            //console.log({ res })
            // console.log('token data', data);
            // const user = await updateUser(getUser().id, { data: { gauth: data }});
            //setUser(user);
            // console.log({ gauth: data });
            // setGoogleUser(data);
            // navigate(ScreenNames.APP_HOME);
          }
        } catch (error) {
          console.log({ error })
        }
      })()
    },[]);

    return <View style={{ width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Bird width={150} opacity={1} color={Colors.secondary}/>
      {/* <View style={[GlobalStyles.progressDotsRow, { alignSelf: 'center', justifyContent: 'center', marginTop: 0}]}>
        <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
        <View style={GlobalStyles.progressDot} />
        <View style={GlobalStyles.progressDot} />
      </View> */}
    </View>;
}