import React from 'react';
import { StyleSheet, Dimensions } from "react-native";

import Colors from '../constants/Colors';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const Styles = StyleSheet.create({
  background: {
    flex: 1,
    minHeight: height,
    backgroundColor: Colors.background,
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    minHeight: height,
    width: width / 1.2,
    alignSelf: 'center',
    zIndex: 1,
    alignItems:'center'
    
  },
  menuText: {
    fontFamily: 'Brandon-Regular',
    fontSize: width / 80, 
    color: Colors.white,
    marginRight: width / 25,
    textAlign: 'center',
  },
  menuLogo: {
    top: height / 10,
    width: width / 13,
    height: height / 12,
    resizeMode: 'contain',
  },
  headerContainer: {
    marginTop: height / 3.5,
    width: width / 2.3,
    alignItems: 'center'
  },
  headerTitle: {
    fontFamily: 'Brandon-Regular',
    fontSize: width / 60, 
    color: Colors.secondary,
    
  },
  headerSubTitle: {
    fontFamily: 'Bio-Regular',
    fontSize: width / 30, 
    color: Colors.secondary,
    marginTop: height / 50,
  },
  
  

});


export default Styles;
