import React, { useRef,useState } from 'react';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity } from "react-native";
import { Link, useNavigate } from "react-router-dom";
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Screens from '../../constants/Screens';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function IntroScreen() {  

  const navigate = useNavigate();
  const [nextButtonLoading, setNextButtonLoading] = useState(false);

  const submitForm = async() => {
    navigate('/signup/email', { replace: false });        
  }
  const signIn = async() => {
    navigate('/signin', { replace: false });        
  }
  const forgotPassword = async() => {
    navigate('/forgot-password', { replace: false });        
  }

  return (
    <View style={GlobalStyles.background}> 
      {/* SITE CONTAINER */}
      <View style={GlobalStyles.container}>

        {/* LOGO */}
        <Image style={Styles.logo} source={Images.LOGO} />

        {/* IMAGE */}
        {/* <Image style={Styles.image} source={Images.INTRO_SCREEN_IMAGE} /> */}

        {/* ACTION BUTTONS */}
        <View style={[Styles.actionButtonContainer]}>
            <TouchableOpacity onPress={submitForm} style={[GlobalStyles.secondaryButton, GlobalStyles.buttonDropShadow]} >
                <Text style={GlobalStyles.secondaryButtonText}>{Strings.SIGN_UP}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={signIn} style={[GlobalStyles.secondaryOutlineButton, { marginTop: 20}]}>
                <Text style={GlobalStyles.secondaryOutlineButtonText}>{Strings.SIGN_IN}</Text>
            </TouchableOpacity>
            <Pressable onPress={forgotPassword}>
                <Text style={[Styles.forgotPassword, { marginTop: 20 }]}>{Strings.FORGOT_PASSWORD}?</Text>
            </Pressable>
        </View>
      </View>
    </View>
  );
}

const Styles = StyleSheet.create({
    logo: {
      top: height / 10,
      width: width / 1,
      height: height / 7,
      resizeMode: 'contain',
    },
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        bottom: height / 20, 
        alignItems: 'center',
    },
    forgotPassword: {
        fontFamily: 'Brandon-Regular',
        fontSize: 16,
        color: Colors.secondary,
    },  
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
        //backgroundColor: 'blue',
    }
    
  
  });
  