import axios from 'axios';
import uniqid from 'uniqid';
import Routes from './routes';

const baseURL = process.env.REACT_APP_API_BASEURL;

let token = JSON.parse(localStorage.getItem('token'));

let config = {
    headers: { Authorization: `Bearer ${token}` }
};

export const apiUsers = {
    // retrieve: (async (id) => {
    //     token = JSON.parse(localStorage.getItem('token')); 
    //     config.headers = { Authorization: `Bearer ${token}` };
    //     const res = await axios.get(baseURL + Routes.RETRIEVE_LOCATION + id, config)
    //     return res
    // }),
    list: (async () => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.get(baseURL + Routes.USERS_DETAIL, config)
        return res
    }),

    sendInvitationEmail: (async (payload) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.post(baseURL + Routes.SEND_INVITATION_EMAIL, payload, config)
        return res
    }),
    
}



