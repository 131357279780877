import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Image, Text, View, RefreshControl, FlatList, TouchableOpacity, ActivityLoader } from "react-native-web";
import { CheckBox } from 'react-native-web';
import { useNavigate } from "react-router-dom";
import Drawer from "react-bottom-drawer";
import { isMobile } from 'react-device-detect';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import useUser from '../../hooks/useUser';
import IconHeader from '../../components/Headers/IconHeader';
import QuickNote from '../../components/QuickNote';
import ScreenNames from '../../constants/ScreenNames';
import { listNotes, listPinnedNotes } from '../../hooks/useAPI';
import { TextInput } from '../../components/Themed';
import moment from 'moment-timezone';
import useToken from '../../hooks/useToken';

// const width = Dimensions.get('window').width;
// const height = Dimensions.get('window').height;

const sliceItems = 2;

export default function DashboardScreen() {  

    const ref = useRef<FlatList>(null);
    const { user, getGoogleUser, setGoogleUser, subscriptionActive } = useUser();
    const { token } = useToken();
    const [ notes, setNotes ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ filterVisible, setFilterVisible ] = useState(false);
    const [ filterCategories ] = useState([Strings.NOTE, Strings.SHARED, Strings.PINNED, Strings.GROCERY,Strings.SHOPPING, Strings.PRACTICE, Strings.REMINDER]);
    const [ filterCategory, setFilterCategory ] = useState(Strings.NOTE);
    const [ savedFilters, setSavedFilters ] = useState<any>({});
    const [ searchText, setSearchText ] = useState('');
    const [primaryCalendar, setPrimaryCalendar] = useState({});
    const [syncedNotes, setSyncedNotes] = useState([]);
    const [ count, setCount ] = useState(0);
    const [ start, setStart ] = useState(0);
    const limit = 50;

    const navigate = useNavigate();    

    const fetchPinnedNotes = async({ filters = {} } : { filters? }) => {
        try {
            const res = await listPinnedNotes({
                sort: {
                    createdAt: 'desc'
                },
                filters: {
                    user: user.id,
                    ...filters,
                },
                populate: {
                    note: {
                        populate: {
                            items: true,
                            members: true,
                        }
                    },
                    user: true,
                }
            });
            console.log({ pinned: res, pagination: res.meta.pagination })
            const { data, meta } = res;
            setCount(meta.pagination.total);
            setNotes(data);
        } catch (error) {
            console.log(error);
        } finally {

        }
    }

    const fetchNotes = async({ filters = {} } : { filters? }) => {
        setLoading(true);
        try {
            let body = {
                pagination: {
                    start,
                    limit
                },
                sort: {
                    //datetime: 'desc',
                    createdAt: 'desc',
                },
                filters: {
                    user: user.id,
                    ...filters
                },
                populate: {
                    items: true,
                    members: true,
                    user: true,
                }
            }

            if(filterCategory === Strings.SHARED) body.filters = filters;
            else {
                body.filters = {
                    user: user.id,
                    ...filters
                };

                if(searchText) {
                    body.filters.$or = [
                        {
                            title: {
                                $contains: searchText
                            }
                        },
                        {
                            text: {
                                $contains: searchText
                            }
                        },
                    ];
                }
            }

            setSavedFilters(filters);

            const res = await listNotes(body);
            const { data, meta } = res;
            setCount(meta.pagination.total);
            setNotes(data);
        } catch (error) {
            console.log(error);
            //alert(error?.message || 'Error fetching notes');
        } finally {
            setLoading(false);
        }
    }

    const onEndReached = () => {
        if(count === 0) return false
        if(_.size(notes) === 0) return false
        if(_.size(notes) < count){
            setStart(start+limit);
            // fetch more function
            onRefresh();
        }            
    }

    const onRefresh = () => fetchData();

    const fetchData = async() => {
        if(filterCategory === Strings.NOTE) await fetchNotes({});
        if(filterCategory === Strings.SHARED) await fetchNotes({ 
            filters: { 
                members: {
                    user: {
                        id: [user.id]
                    }
                }
            }
        });
        if(filterCategory === Strings.PINNED) await fetchPinnedNotes({});
        if(filterCategory === Strings.GROCERY) await fetchNotes({ filters: { category: Strings.GROCERY }});
        if(filterCategory === Strings.SHOPPING) await fetchNotes({ filters: { category: Strings.SHOPPING }});
        if(filterCategory === Strings.PRACTICE) await fetchNotes({ filters: { category: Strings.PRACTICE }});
        if(filterCategory === Strings.REMINDER) await fetchNotes({ filters: { category: Strings.REMINDER }});
        if(filterCategory === Strings.SEARCH) await fetchNotes({});
    }

    const [ categoryColors, setCategoryColors ] = useState(user?.noteColors || [
        {
            category: Strings.NOTE,
            color: '#fff',
        },
        {
            category: Strings.GROCERY,
            color: '#bcffbf',
        },
        {
            category: Strings.SHOPPING,
            color: '#ade0ff',
        },
        {
            category: Strings.REMINDER,
            color: '#fff3bc',
        },
        {
            category: Strings.PRACTICE,
            color: '#f2c8ff',
        },
    ]);

    const getNoteColor = (category) => {
        const item = _.find(categoryColors, item => item.category === category);

        if(item?.color === '#fff') return Colors.secondary;
        else if(item?.color === 'rgba(255, 255, 255, 0.5)') return Colors.secondary;
        else if(item?.color) return item.color;
        else return Colors.secondary;
    }

    useEffect(() => {
      (async() => {
        setSavedFilters({});
        await fetchData();
      })();
    },[filterCategory]);


    // RENDER NOTE
    const _renderNote = (item: any, index: number) => {  
        const route = ScreenNames.APP_NOTE;
        const title = filterCategory === Strings.PINNED ? item?.attributes?.note?.data?.attributes?.title : item.attributes.title;
        const text = filterCategory === Strings.PINNED ? item?.attributes?.note?.data?.attributes?.text : item.attributes.text;
        const category = filterCategory === Strings.PINNED ? item?.attributes?.note?.data?.attributes?.category : item.attributes.category;
        const items = filterCategory === Strings.PINNED ? item?.attributes?.note?.data?.attributes?.items : item.attributes.items;
        const updatedAt = filterCategory === Strings.PINNED ? item?.attributes?.note?.data?.attributes?.updatedAt : item.attributes.updatedAt;
        const createdAt = filterCategory === Strings.PINNED ? item?.attributes?.note?.data?.attributes?.createdAt : item.attributes.createdAt;
        const color = getNoteColor(category);

        let plusMore = 0;
        if(_.size(items) > 0) {
            const total = _.size(items);
            const left = total - sliceItems;
            if(left >= 1) plusMore = left;

        }

        return (
            <TouchableOpacity key={item.id} onPress={() => navigate(route + '/' + item.id, { replace: false })} style={[GlobalStyles.note, { backgroundColor: '#fff', }]}>
                <View style={GlobalStyles.noteContainer}>
                    <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                    >
                        <View
                        style={{
                            padding: 8,
                            borderRadius: 60,
                            backgroundColor: color || Colors.accent,
                            marginRight: 12
                        }}
                        >
                            { _.capitalize(category) === _.capitalize(Strings.GROCERY) && <Image style={GlobalStyles.littleIcon} source={Images.SHOPPING_WHITE}></Image>}
                            { _.capitalize(category) === _.capitalize(Strings.PRACTICE) && <Image style={GlobalStyles.littleIcon} source={Images.PRACTICE_WHITE}></Image>}
                            { _.capitalize(category) === _.capitalize(Strings.SHOPPING) && <Image style={GlobalStyles.littleIcon} source={Images.SHOPPING_BAG_WHITE}></Image>}
                            { _.capitalize(category) === _.capitalize(Strings.REMINDER) && <Image style={GlobalStyles.littleIcon} source={Images.BELL_WHITE}></Image>}
                            { _.capitalize(category) === _.capitalize(Strings.NOTE) && <Image style={GlobalStyles.littleIcon} source={Images.NOTEBOOK_WHITE}></Image>}
                        </View>

                        <View>
                            <Text style={[GlobalStyles.noteTitle]}>{title}</Text>
                            <Text style={[GlobalStyles.noteText, { marginTop: 0, overflow: 'hidden' }]}>{text}</Text>
                            <Text style={{ fontType: 'Brandon-Regular' , color: '#a6a6a6', fontSize: 10, marginTop: 2 }}>
                                Updated {moment(updatedAt).fromNow()}
                            </Text>
                        </View>
                    </View>
                    {/* { item.type == 'text' && <Text style={[GlobalStyles.noteText, { marginTop: 5, overflow: 'hidden' }]}>{item.attributes.note.data.attributes.text}</Text> } */}
                    {/* CHECK LIST SECTION */}
                    { _.size(items) > 0 && 
                        <FlatList 
                            style={{ marginTop: 10 }}
                            data={items.slice(0, sliceItems)}
                            showsHorizontalScrollIndicator={false}
                            //keyExtractor={(item, index) => _.toString(item)}
                            renderItem={({item, index}) => _renderListItem(item, index)}>
                        </FlatList> }
                    { plusMore !== 0 && <Text style={[GlobalStyles.noteText, { marginTop: 5, overflow: 'hidden' }]}> + {plusMore} more</Text>}

                </View>
            </TouchableOpacity>
        );
    }

    const _renderListItem = (item: any, index: number) => {        

        return (
            <View key={index} style={GlobalStyles.checkboxContainer}>
                <CheckBox
                    color={Colors.secondary}
                    value={item.active}
                    style={GlobalStyles.checkbox}
                />
                <Text style={[GlobalStyles.noteInputTextSmall, { marginTop: 10, margin: 5 }]}>{item.text}</Text>
            </View>
        );
    }

    useEffect(() => {
      (async() => {
        await fetchData();
      })();
    },[searchText]);

    useEffect(() => {
        if(!token) return navigate(ScreenNames.SIGNUP, { replace: false });
        else if(!subscriptionActive) return navigate(ScreenNames.APP_SETTINGS, { replace: false });        
    },[]);
    
    return (
        <View style={[GlobalStyles.appBackground, { height: '100vh' }]}> 
            {/* HEADER */}
            <IconHeader filter={true} title={''} type={''} exit={false} onFilterClick={() => setFilterVisible(prev => !prev)} category={filterCategory} />
            
            {/* SEARCH */}
            <View
            style={[
                GlobalStyles.appContainer,
                { 
                    marginTop: 75,
                    paddingHorizontal: 10,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyCenter: 'center',
                    width: isMobile ? '100%' : '30%'
                }]}
            >
                {/* <Pressable onPress={() => setFilterVisible(prev => !prev)} style={[GlobalStyles.iconButtonSmall, { marginLeft: 0 }]}>
                    { filterCategory == Strings.GROCERY && <Image style={GlobalStyles.littleIcon} source={Images.SHOPPING_WHITE}></Image>}
                    { filterCategory == Strings.PINNED && <Image style={GlobalStyles.littleIcon} source={Images.PIN_WHITE}></Image>}
                    { filterCategory == Strings.SHARED && <Image style={GlobalStyles.littleIcon} source={Images.SHARE_WHITE}></Image>}
                    { filterCategory == Strings.PRACTICE && <Image style={GlobalStyles.littleIcon} source={Images.PRACTICE_WHITE}></Image>}
                    { filterCategory == Strings.SHOPPING && <Image style={GlobalStyles.littleIcon} source={Images.SHOPPING_BAG_WHITE}></Image>}
                    { filterCategory == Strings.REMINDER && <Image style={GlobalStyles.littleIcon} source={Images.BELL_WHITE}></Image>}
                    { filterCategory == Strings.NOTE && <Image style={GlobalStyles.littleIcon} source={Images.NOTEBOOK_WHITE}></Image>}
                    { filterCategory == Strings.SEARCH && <Image style={GlobalStyles.littleIcon} source={Images.SEARCH_WHITE}></Image>}
                </Pressable> */}

                <TextInput
                value={searchText}
                onChangeText={setSearchText}
                placeholder={Strings.SEARCH}
                style={{
                    marginTop: 0,
                    marginLeft: 10,
                    width: '100%'
                }}
                />
            </View>

            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer, { marginTop: 5, height: '90vh', width: isMobile ? '100vw' : '30vw' }]}>
                
                {/* NOTES */}
                
                <FlatList
                    ref={ref}
                    style={{ marginTop: 5, marginBottom: 100 }}
                    data={notes}
                    extraData={notes}
                    onEndReached={onEndReached}
                    onEndReachedThreshold={0.5}
                    renderItem={({item, index}) => _renderNote(item, index)}
                    //keyExtractor={(item) => _.toString(item.id)}
                    showsVerticalScrollIndicator={false}
                    ListEmptyComponent={<>
                        {
                            !loading &&
                            <View
                            style={{
                                alignItems: 'center',
                                justifyCenter: 'center'
                            }}
                            >
                                <Text>No notes</Text>
                            </View>
                        }
                    </>}
                    refreshControl={
                        <RefreshControl
                        refreshing={loading}
                        onRefresh={onRefresh}
                        />
                    }
                />
                
            </View>

            {/* BOTTOM NOTE MAKER */}
            <QuickNote />
            
            {/* BOTTOM FILTER DRAWER */}
            <Drawer
                isVisible={filterVisible}
                onClose={() => setFilterVisible(false)}
            >
                <View style={GlobalStyles.bottomDrawer}>
                
                    <View style={[GlobalStyles.bottomDrawerContainer]}>
                        <Text style={[GlobalStyles.drawerTitle,  ]}>{'Filter'}</Text>
                        <FlatList 
                            style={{ marginTop: 20, marginBottom: 20, }}
                            data={filterCategories}
                            showsHorizontalScrollIndicator={false}
                            //keyExtractor={(item) => _.toString(item)}
                            renderItem={({item, index}) => {
                                let active = false;
                                if(filterCategory === item) active = true;
                                return (
                                    <View key={index} style={[GlobalStyles.checkboxContainer,  { marginBottom: 15 }]}>
                                        <CheckBox
                                            color={Colors.secondary}
                                            value={active}
                                            onValueChange={(value) => { 
                                                if(value) setFilterCategory(item);
                                                else setFilterCategory(Strings.NOTE);
                                            }}
                                            style={GlobalStyles.checkbox}
                                        />
                                        <View style={{ marginLeft: 20, flexDirection: 'row'}}>
                                            <Text style={[GlobalStyles.labelTitle]}>{item}</Text>
                                            <View style={[GlobalStyles.dot, { marginLeft: 30, backgroundColor: item.color, marginTop: 9 }]}></View>
                                        </View>
                                    </View>
                                )
                            }}>
                        </FlatList>

                    </View>
            
                </View>
            </Drawer>
        </View>
    );
}
  