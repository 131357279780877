import React, { useMemo, useEffect, useState } from 'react';
import { Image, Text, View, Dimensions, StyleSheet, TouchableOpacity } from "react-native-web";
import { TextInput, Bird } from '../../components/Themed';
import { passwordStrength } from 'check-password-strength';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Colors from '../../constants/Colors';
import { apiAuth, globalFunctions } from '../../services';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';
import ScreenNames from '../../constants/ScreenNames';
import { getMe, listFamilies, registerUser, updateMember, updateNote, updateUser } from '../../hooks/useAPI';
import { ProfileContext } from '../../context/ProfileContext';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SignUpPasswordScreen() {  

    const navigate = useNavigate();
    const { signUpUser, setUser, setFamilies, setFamily } = useUser();
    const { token, setToken } = useToken();

    const { search } = useLocation();
    function useQuery() {
        return useMemo(() => new URLSearchParams(search), [search]);
    }    
    const shareEmail = useQuery().get('email');
    const shareNote = useQuery().get('note');
    const shareFrom = useQuery().get('from');
    const shareFamily = useQuery().get('family');
    const shareMember = useQuery().get('member');

    const [email] = useState(() => {
        //get stored value
        if(signUpUser && signUpUser.email) return signUpUser.email;
        else return '';
    });

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordSecure, setPasswordSecure] = useState(true);
    const [passwordConfirmSecure, setPasswordConfirmSecure] = useState(true);
    const [nextButtonLoading, setNextButtonLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');


    const [formResponseText, setFormResponseText] = useState('');
    const submitForm = async() => {
        if(!password){
            setPasswordError(Strings.PASSWORD_EMPTY);
            return false
        }   
        if(passwordStrength(password).value == 'Too weak'){
            setPasswordError(Strings.PASSWORD_TOO_WEAK);
            return false
        }
        if(passwordStrength(password).value == 'Weak'){
            setPasswordError(Strings.PASSWORD_WEAK);
            return false
        }
        if(!passwordConfirm){
            setConfirmPasswordError(Strings.PASSWORD_EMPTY);
            return false
        } 
        if(password != passwordConfirm){
            setConfirmPasswordError(Strings.PASSWORD_NOT_MATCH);
            return false
        }

        if(!email) return false;        
        
        setNextButtonLoading(true);
        
        try {
            const res = await registerUser({email, username: email, password });
            setUser(res.user);
            setToken(res.jwt);

            //If shared, attach to notes and member
            if(shareMember) await updateMember(shareMember, { data: { email: res.user.email, user: res.user.id }});

            setFamily({});
            setFamilies({});

            setNextButtonLoading(false);
            navigate(ScreenNames.SIGNUP_FAMILY, { replace: false }); 

        } catch(error){
            setFormResponseText(error.message);
            //setFormResponseText(error.response.data.email ? error.response.data.email : error.response.data.statusText);
            setNextButtonLoading(false);
        }
        
        
        
    }

    


    return (
        <View style={GlobalStyles.background}> 
            {/* SITE CONTAINER */}
            <View style={GlobalStyles.container}>

                {/* TOP ACTION HEADER */}
                <View style={GlobalStyles.topActionHeader}>
                    {/* BACK ARROW */}
                    <Link style={{ textDecoration: 'none', left: 0,}} to={ScreenNames.SIGNUP}>
                        <Image style={[GlobalStyles.leftArrowIcon]} source={Images.ARROW_LEFT} />
                    </Link>
                    {/* EXIT */}
                    <Link style={{ textDecoration: 'none'}} to={ScreenNames.HOME}>
                        <Image style={[GlobalStyles.exitIcon, { position: 'absolute', right: 0,}]} source={Images.EXIT} />
                    </Link>
                </View>

                {/* SIGN UP CONTAINER */}
                <View style={GlobalStyles.signUpContainer}>
                    {/* PROGRESS */}
                    <View style={GlobalStyles.progressDotsRow}>
                        <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                        <View style={GlobalStyles.progressLongDot} />                    
                        <View style={GlobalStyles.progressDot} />
                        <View style={GlobalStyles.progressDot} />
                        <View style={GlobalStyles.progressDot} />
                    </View>

                    {/* DESCRIPTION */}
                    <Text style={GlobalStyles.signUpFlowDescription}>{Strings.SIGNUP_PASSWORD_DESCRIPTION}</Text>

                    {/* TITLE */}
                    <Text style={GlobalStyles.signUpFlowTitle}>{Strings.CREATE_PASSWORD}</Text>

                    {/* INPUT PASSWORD */}
                    <TextInput secureTextEntry={passwordSecure} value={password} onChange={(text) => text && setPasswordError('')} onChangeText={(password) => setPassword(password)} placeholder={Strings.PASSWORD} style={{ marginTop: 100}}></TextInput>
                    {/* INPUT PASSWORD ERROR MESSAGE */}
                    <Text style={GlobalStyles.errorText}>{passwordError}</Text>


                    {/* INPUT CONFIRM PASSWORD */}
                    <TextInput secureTextEntry={passwordConfirmSecure} value={passwordConfirm} onChange={(text) => text && setConfirmPasswordError('')} onChangeText={(password) => setPasswordConfirm(password)} placeholder={Strings.CONFIRM_PASSWORD} style={{ marginTop: 50}}></TextInput>
                    {/* INPUT CONFIRM PASSWORD ERROR MESSAGE */}
                    <Text style={GlobalStyles.errorText}>{confirmPasswordError}</Text>

                    {/* FORM RESPONSE TEXT ERROR*/}
                    <Text style={[GlobalStyles.errorText, { marginTop: 45, fontSize: 20 }]}>{formResponseText}</Text>

                    {/* BIRD */}
                    <Bird style={{ position: 'absolute', left: -width / 5.5, bottom: height / 8,}}/>  
                    

                    {/* ACTION BUTTONS */}
                    <View style={[Styles.actionButtonContainer]}>
                        <TouchableOpacity disabled={nextButtonLoading} onPress={submitForm} style={[GlobalStyles.signUpNextButton, GlobalStyles.buttonDropShadowCircle, { alignSelf:'flex-end', opacity: nextButtonLoading && 0.5, }]}>
                            <Image style={[GlobalStyles.nextArrowIcon]} source={Images.ARROW_RIGHT_WHITE} />
                        </TouchableOpacity>
                    </View>

                </View>

            
            </View>
        </View>
    );
}

const Styles = StyleSheet.create({
    
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        bottom: height / 20, 
        alignItems: 'center',
    },
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
        //backgroundColor: 'blue',
    }
    
  
  });
  