import React, { useRef,useState } from 'react';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity } from "react-native";
import { Link, useNavigate } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import emailValidator from 'email-validator';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Screens from '../../constants/Screens';
import { apiAuth, globalFunctions } from '../../services';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function ForgotPasswordScreen() {  

    const navigate = useNavigate();
    const [nextButtonLoading, setNextButtonLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const submitForm = async() => {
        if(!emailValidator.validate(email)){
            setEmailError(Strings.EMAIL_NOT_VALID);
            return false;
            
        } 

        setNextButtonLoading(true);
        let res = await apiAuth.forgot(email);
        if(res.status === 200){
            await globalFunctions.timeout(2000);
            setNextButtonLoading(false);
            navigate('/signin', { replace: false });  
        } else {
            alert(res.data.message);
            setNextButtonLoading(false);
        }  

              
    }
    const signin = async() => {
        navigate('/signin', { replace: false });  
    }

    return (
        <View style={GlobalStyles.background}> 
        {/* SITE CONTAINER */}
        <View style={GlobalStyles.container}>

            {/* LOGO */}
            <Link to='/'>
                <Image style={Styles.logo} source={Images.LOGO} />
            </Link>

            {/* DESCRIPTION */}
            <Text style={[GlobalStyles.signUpFlowDescription, { marginTop: 150 }]}>{Strings.FORGOT_PASSWORD_DESCRIPTION}</Text>

            {/* INPUT EMAIL */}
            <TextInput keyboardType='email-address' autoCompleteType='email' value={email} onChange={(text) => text && setEmailError('')} onChangeText={(email) => setEmail(email)} placeholder={Strings.EMAIL} style={{ marginTop: 100, width: isMobile ? '100%' : '40%'}}></TextInput>
            {/* ERROR MESSAGE */}
            <Text style={[GlobalStyles.errorText, { width: isMobile ? '100%' : '40%' }]}>{emailError}</Text>

            {/* ACTION BUTTONS */}
            <View style={[Styles.actionButtonContainer]}>
                <TouchableOpacity disabled={nextButtonLoading} onPress={submitForm} style={[GlobalStyles.secondaryButton, GlobalStyles.buttonDropShadow, { opacity: nextButtonLoading && 0.5, }]} >
                    <Text style={GlobalStyles.secondaryButtonText}>{Strings.SUBMIT}</Text>
                </TouchableOpacity>
                <Pressable onPress={signin}>
                    <Text style={[Styles.forgotPassword, { marginTop: 20 }]}>{Strings.SIGN_IN}</Text>
                </Pressable>
            </View>
        </View>
        </View>
    );
    }

const Styles = StyleSheet.create({
    logo: {
      top: height / 10,
      width: width / 1,
      height: height / 7,
      resizeMode: 'contain',
    },
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        bottom: height / 20, 
        alignItems: 'center',
    },
    forgotPassword: {
        fontFamily: 'Brandon-Regular',
        fontSize: 16,
        color: Colors.secondary,
    },  
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
        //backgroundColor: 'blue',
    }
    
  
  });
  