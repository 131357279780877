import React, { useContext, useEffect, useState } from 'react';
import { Image, Text, View, Dimensions, StyleSheet, TouchableOpacity, ActivityIndicator } from "react-native-web";
import { TextInput, Bird } from '../../components/Themed';
import { Link, useNavigate } from "react-router-dom";
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Colors from '../../constants/Colors';
import { apiAuth, apiFamily, apiUser, apiUserSettings, globalFunctions } from '../../services';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';
import ScreenNames from '../../constants/ScreenNames';
import { createFamily, listFamilies, updateFamily } from '../../hooks/useAPI';
import { ProfileContext } from '../../context/ProfileContext';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SignUpFamilyNameScreen() {  

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { user, families, setFamily, family, setFamilies } = useUser();
    const { verifyToken, token } = useToken(); 

    const [registered, setRegistered] = useState(() => {
        if(user.settings && user.settings.registered) return true;
        else return false;
    });
    
    const [familyName, setFamilyName] = useState(() => {
        //if(families[0]?.attributes?.name) return families[0]?.attributes?.name;
        if(families[0]?.attributes?.name) return families[0]?.attributes?.name;
        else return '';
    });

    const [nextButtonLoading, setNextButtonLoading] = useState(false);
    const [familyNameError, setFamilyNameError] = useState('');

    const [formResponseText, setFormResponseText] = useState('');

    const fetchFamilies = async() => {
        try {
            const res = await listFamilies({});
            setFamilies(res.data);
        } catch (error) {
            console.log({ error });
            return error;
        }
    }

    const submitForm = async() => {
        if(!familyName){
            setFamilyNameError(Strings.FAMILY_NAME_EMPTY);
            return false;
        }        

        setNextButtonLoading(true);
        
        try {
            //If family has id - update
            if(family?.id){                
                const res = await updateFamily(family.id, { 
                    data: { name: familyName },
                    populate: {
                        members: true
                    }
                });
                setFamily(res);
                await fetchFamilies();
                setNextButtonLoading(false);
                navigate(ScreenNames.SIGNUP_MEMBERS, { replace: false })
            } else {
                const res = await createFamily({ data: { name: familyName, user: user.id } });
                setFamily(res);
                await fetchFamilies();
                setNextButtonLoading(false);
                navigate(ScreenNames.SIGNUP_MEMBERS, { replace: false });
            }
            

        } catch (e) {
            setFormResponseText(e.message);
            setNextButtonLoading(false);
        }

    }

    
    return (
        <View style={GlobalStyles.background}> 
            {/* SITE CONTAINER */}
            <View style={GlobalStyles.container}>

                {/* TOP ACTION HEADER */}
                <View style={GlobalStyles.topActionHeader}>
                    {/* BACK ARROW */}
                    <Link style={{ textDecoration: 'none', left: 0,}} to={ScreenNames.SIGNUP_PASSWORD}>
                        <Image style={[GlobalStyles.leftArrowIcon]} source={Images.ARROW_LEFT} />
                    </Link>
                    {/* EXIT */}
                    <Link style={{ textDecoration: 'none'}} to={ScreenNames.HOME}>
                        <Image style={[GlobalStyles.exitIcon, { position: 'absolute', right: 0,}]} source={Images.EXIT} />
                    </Link>
                </View>

                {/* SIGN UP CONTAINER */}
                <View style={GlobalStyles.signUpContainer}>
                    {/* PROGRESS */}
                    <View style={GlobalStyles.progressDotsRow}>
                        <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                        <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                        <View style={GlobalStyles.progressLongDot} />                    
                        <View style={GlobalStyles.progressDot} />
                        <View style={GlobalStyles.progressDot} />
                    </View>

                    {/* DESCRIPTION */}
                    <Text style={GlobalStyles.signUpFlowDescription}>{Strings.SIGNUP_FAMILY_NAME_DESCRIPTION}</Text>

                    {/* TITLE */}
                    <Text style={GlobalStyles.signUpFlowTitle}>{Strings.FAMILY_NAME}</Text>

                    {/* INPUT FAMILY NAME */}
                    <TextInput value={familyName} onChange={(text) => text && setFamilyName('')} onChangeText={(name) => setFamilyName(name)} placeholder={Strings.FAMILY_NAME} style={{ marginTop: 100}}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={GlobalStyles.errorText}>{familyNameError}</Text>

                    {/* FORM RESPONSE TEXT ERROR*/}
                    <Text style={[GlobalStyles.errorText, { marginTop: 45, fontSize: 20 }]}>{formResponseText}</Text>

                    {/* BIRD */}
                    <Bird style={{ position: 'absolute', left: -width / 5.5, bottom: height / 8,}}/>

                    {/* ACTION BUTTONS */}
                    <View style={[Styles.actionButtonContainer]}>
                        <TouchableOpacity disabled={nextButtonLoading} onPress={submitForm} style={[GlobalStyles.signUpNextButton, GlobalStyles.buttonDropShadowCircle, { alignSelf:'flex-end', opacity: nextButtonLoading && 0.5, }]}>
                            <Image style={[GlobalStyles.nextArrowIcon]} source={Images.ARROW_RIGHT_WHITE} />
                        </TouchableOpacity>
                    </View>
                </View>            
            </View>
        </View>
    )
}

const Styles = StyleSheet.create({
    
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        bottom: height / 20, 
        alignItems: 'center',
    },
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
        //backgroundColor: 'blue',
    }
    
  
  });
  