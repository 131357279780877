import axios from 'axios';
import uniqid from 'uniqid';
import Routes from './routes';

const baseURL = process.env.REACT_APP_API_BASEURL;

let token = JSON.parse(localStorage.getItem('token'));

let config = {
    headers: { Authorization: `Bearer ${token}` }
};

export const apiImage = {   
    create: (async (payload) => {
        var fd = new FormData();
        fd.append('image',payload.file)
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}`, "content-type": "multipart/form-data" }
        const res = await axios.post(baseURL + Routes.CREATE_IMAGE, fd, config)
        return res
    }),
    retrieve: (async (id) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.get(baseURL + Routes.RETRIEVE_IMAGE + id, config)
        return res
    }),
    update: (async (id, payload) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.put(baseURL + Routes.UPDATE_IMAGE + id, payload, config)
        return res
    }),
    delete: (async (id) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.delete(baseURL + Routes.DELETE_IMAGE + id, config)
        return res
    }),
    list: (async () => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.get(baseURL + Routes.LIST_IMAGES, config)
        return res
    }),
    
}



