import React, { useCallback, useEffect, useMemo, useRef,useState } from 'react';
import _ from 'lodash';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity, ScrollView, SectionList, Platform,Switch } from "react-native-web";
import { CheckBox } from 'react-native-web';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import randomColor from 'randomcolor';
import Drawer from "react-bottom-drawer";
import uniqid from 'uniqid';
import emailValidator from 'email-validator';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Screens from '../../constants/Screens';
import { apiAuth, apiChild, apiFamily, apiNote, apiParent, apiRelative, apiUser, globalFunctions } from '../../services';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';
import IconHeader from '../../components/Headers/IconHeader';
import useNotes from '../../hooks/useNotes';
import ScreenNames from '../../constants/ScreenNames';
import { createMember, deleteMember, listFamilies, updateMember } from '../../hooks/useAPI';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SettingsFamilyScreen() {  

    const { user } = useUser();

    function useQuery() {
        const { search } = useLocation();      
        return useMemo(() => new URLSearchParams(search), [search]);
    }  

    const note = useQuery().get('note');

    const navigate = useNavigate();
    const [nextButtonLoading, setNextButtonLoading] = useState(false);

    const [familyMembersError, setFamilyMembersError] = useState('');
    const [family, setFamily] = useState<any>({});
    const [parent, setParent] = useState('');
    const [parents, setParents] = useState<any>([]);
    const [parentAutoFocus, setParentAutoFocus] = useState(true);
    const parentInput: any = useRef(null);

    const [child, setChild] = useState('');
    const [children, setChildren] = useState<any>([]);
    const [childAutoFocus, setChildAutoFocus] = useState(false);
    const childInput: any = useRef(null);

    const [relative, setRelative] = useState('');
    const [relatives, setRelatives] = useState<any>([]);
    const [relativeAutoFocus, setRelativeAutoFocus] = useState(false);
    const relativeInput: any = useRef(null);    

    const randomColorParams = {
        luminosity: 'dark', 
        format: 'rgba', 
        alpha: 0.5,
        count: 10,
    }

    const [randomColors, setRandomColors] = useState([]);

    const [bottomDrawerVisible, setBottomDrawerVisible] = useState(false);
    const [drawerType, setDrawerType] = useState('');

    const [ successText, setSuccessText ] = useState('');
    const [ formLoading, setFormLoading ] = useState(false);
    const [ formProfileLoading, setFormProfileLoading ] = useState(false);


    const [ member, setMember ] = useState<any>({});
    const [ memberIndex, setMemberIndex ] = useState(0);
    const [ memberType, setMemberType ] = useState('');
    
    const [ memberName, setMemberName ] = useState(() => {
        return member.name || '';
    });
    const [ memberNameError, setMemberNameError ] = useState('');
    const [ memberEmail, setMemberEmail ] = useState(() => {
        return member.email || '';
    });
    const [ memberEmailError, setMemberEmailError ] = useState('');
    const [ memberPhone, setMemberPhone ] = useState(() => {
        return member.phone || '';
    });
    const [ memberPhoneError, setMemberPhoneError ] = useState('');


    const onBottomDrawerClose = useCallback(() => {
        setBottomDrawerVisible(false);
    }, []);

    const openDrawer = (item, index, type) => {
        setRandomColors([item.color, ...randomColor(randomColorParams)])
        setMember(item);
        setMemberName(item.name);
        setMemberEmail(item.email);
        setMemberPhone(item.phone);
        setMemberIndex(index);
        setMemberType(type);
        setBottomDrawerVisible(true);
    };
    //
    const save = async() => {

        //Loop through and check if all members have been created
        try {
            setFormLoading(true);
            console.log([...parents, ...children, ...relatives])
            const nonMembers = _.find([...parents, ...children, ...relatives], memb => !memb?.id) || [];
            console.log({ nonMembers })
            for await (const memb of nonMembers) {
                await createMember({ data: memb });
            }
            //
            setSuccessText(Strings.SUCCESSFULLY_UPDATED);
            await globalFunctions.timeout(5000);
            setSuccessText('');
            setFormLoading(false);
        } catch (error) {
            console.log({ error })
            setSuccessText('');
            setFormLoading(false);
        }
        
    } 

    const saveProfile = async() => {
        const memberData = {...member};

        if(!memberName){
            setMemberNameError(Strings.NAME_REQUIRED);
            return false
        } 

        if(memberEmail && !emailValidator.validate(memberEmail)){
            setMemberEmailError(Strings.EMAIL_NOT_VALID);
            return false
        } 
        
        let phoneValid = await globalFunctions.phoneValidation(memberPhone);
        if(memberPhone && !phoneValid){
            setMemberPhoneError(Strings.PHONE_NUMBER_NOT_VALID);
            return false;
        }
       

        memberData.name = memberName;
        //member color already set
        memberData.phone = memberPhone;
        memberData.email = memberEmail;  
        memberData.relation = memberType;  

        console.log({ memberData })

        try {

            setFormProfileLoading(true);

            const par : any = [...parents];
            const chil : any = [...children];
            const rel : any = [...relatives];

            let res: any = {};
            if(memberData.id) res = await updateMember(memberData.id, { data: memberData });
            else res = await createMember({ data: memberData });

            if(memberType === 'parent'){               
                par[memberIndex] = { id: res.data.id, ...res.data.attributes };
            }
            if(memberType === 'child'){
                chil[memberIndex] = { id: res.data.id, ...res.data.attributes };
            }
            if(memberType === 'other'){
                rel[memberIndex] = { id: res.data.id, ...res.data.attributes };
            }

            setParents(par);
            setChildren(chil);
            setRelatives(rel);

            //If note - redirect back to note
            if(note) return navigate(ScreenNames.APP_NOTE + '/' + note + '?share=true')

            setFormProfileLoading(false);
            setSuccessText(Strings.SUCCESSFULLY_UPDATED);
            await globalFunctions.timeout(500);
            setBottomDrawerVisible(false);
            setSuccessText('');

        } catch (error) {
            console.log({ error });
            setFormProfileLoading(false);
        }  
        
    }   

    const deleteConfirmed = async() => {
        try {

            if(memberType === 'parent') {
                const pars = [...parents];
                delete pars[memberIndex];
                setParents(pars);
            }
            if(memberType === 'child') {
                const chil = [...children];
                delete chil[memberIndex];
                setChildren(chil);
            }
            if(memberType === 'other') {
                const rel = [...relatives];
                delete rel[memberIndex];
                setRelatives(rel);
            }

            if(member?.id) await deleteMember(member.id);
           
            setBottomDrawerVisible(false);
            
        } catch (e) {
            console.log(e);
        }

    }

    const deleteProfile = async() => {
        if(confirm(Strings.ARE_YOU_SURE_WANT_TO_DELETE_ACCOUNT)){
            await deleteConfirmed();            
        }
    }

    // RENDER CHIP
    const _renderChip = (item: any, index: number, type: any) => {   
        return (
            <TouchableOpacity onPress={() => openDrawer(item, index, type)} style={[GlobalStyles.chip, GlobalStyles.chipShadow, { backgroundColor: item.color, margin: 5,  }]}>
                <Text style={GlobalStyles.chipText}>{item.name}</Text>
                <View style={[GlobalStyles.chipMinus, { alignItems: 'center', justifyContent: 'center'}]}>
                    <Image style={[{ width: 12, height: 12 }]} source={Images.PENCIL} />
                </View>
            </TouchableOpacity>
        );
    }

    const setColor = (color) => {
        let familyMember = {...member};
        familyMember.color = color;
        setMember(familyMember);
        console.log(familyMember)
    }

    const _renderColorCircle = (item: any, index: number) => {    
        if(member.color === item){
            return (
                <View style={[GlobalStyles.circle, { backgroundColor: item, marginRight: 10, borderWidth: 2, borderColor: Colors.black }]}></View>
            );
        }  else {
            return (
                <Pressable onPress={() => setColor(item)} style={{ marginRight: 10,}}>
                    <View style={[GlobalStyles.circle, { backgroundColor: item, }]}></View>
                </Pressable>
            );
        } 
        
    }
    const addParent = async(event) => {
        if((parent && !event.key) || (parent && (event.key === 'Enter'))) {
            setParents([...parents, { name: parent, relation: 'parent', color: Colors.secondary, family: family.id, active: false}]);            
            setParent('');
            setTimeout(() => parentInput.current.focus(), 10);            
        }
        
    }

    //ADD CHILD TO LIST
    const addChild = (event) => {
        if((child && !event.key) || (child && (event.key === 'Enter'))) {
            setChildren([...children, { name: child, relation: 'child', color: Colors.secondary, family: family.id, active: false}]);
            setChild('');
            setTimeout(() => childInput.current.focus(), 10);
        }        
    }

    //ADD RELATIVE TO LIST
    const addRelative = (event) => {
        if((relative && !event.key) || (relative && (event.key === 'Enter'))) {
            setRelatives([...relatives, { name: relative, relation: 'other', color: Colors.secondary, family: family.id, active: false}]);
            setRelative('');
            setTimeout(() => relativeInput.current.focus(), 10);
        }        
    }

    const fetchFamily = async() => {
        try {
            
            const res = await listFamilies({
                filters: {
                    user: user.id
                },
                populate: {
                    members: {
                        populate: {
                            user: true
                        }
                    }
                }
            });

            const arry : any = []
            _.map(res.data[0].attributes?.members?.data, member => arry.push({ id: member.id, ...member.attributes }));
            const tempFamily = {
                id: res.data[0].id,
                ...res.data[0].attributes,
                members: arry,
                active: false
            }
            console.log({ family: tempFamily })

            setFamily(tempFamily);

            let par : any = [], chl : any  = [], rel : any  = [];
            _.map(tempFamily?.members, member => {
                if(member.relation === 'parent') {
                    par.push(member);
                }
                else if(member.relation === 'child') {
                    chl.push(member);
                }
                else if(member.relation === 'other') {
                    rel.push(member);
                }
            });
            setParents(par);
            setChildren(chl);
            setRelatives(rel);

        } catch (e) {
            console.log(e)
            
        }
    }
    
    

    useEffect(() => {  
        (async() => {
            await fetchFamily();
        })();
      

    },[]);

    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} exit={true} title={Strings.UPDATE_FAMILY_MEMBERS} onFilterClick={() => null} category={''} filter={false}/>
            
            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer,{  width: !isMobile ? '50%' : '100%'} ]}>
                
                <View style={[{ marginHorizontal: 25, marginTop: 100}]}>
                    
                {/* FORM */}
                {/* ERROR MESSAGE */}
                <Text style={GlobalStyles.errorText}>{familyMembersError}</Text>

                {/* FAMILY MEMBERS */}
                <View style={{ marginTop: 40}}>
                    {/* PARENTS */}
                    <View>
                        {/* INPUT */}
                        <TextInput Ref={parentInput}  value={parent} onKeyPress={addParent} autoFocus={parentAutoFocus} onChangeText={(name) => setParent(name)} placeholder={Strings.PARENTS}></TextInput>
                        <TouchableOpacity onPress={addParent} style={[GlobalStyles.inputAddButton, { position: 'relative', alignSelf: 'flex-end', bottom: 40, zIndex: 2 }]}>
                            <Image style={[GlobalStyles.inputAddButtonIcon]} source={Images.PLUS_SMALL} />
                        </TouchableOpacity>
                        {/* CHIPS */}                        
                        <FlatList 
                            style={{ marginTop: -10, marginLeft: -5, }}
                            contentContainerStyle={{flexDirection : "row", flexWrap : "wrap"}}  
                            data={parents}
                            //keyExtractor={(item, index) => item.id.toString()}
                            renderItem={({item, index}) => _renderChip(item, index, 'parent')}>
                        </FlatList> 
                    </View>
                    {/* CHILDREN */}
                    <View style={{ marginTop: 35 }}>
                        {/* INPUT */}
                        <TextInput Ref={childInput} value={child} onKeyPress={addChild} autoFocus={childAutoFocus} onChangeText={(name) => setChild(name)} placeholder={Strings.CHILDREN}></TextInput>
                        <TouchableOpacity onPress={addChild} style={[GlobalStyles.inputAddButton, { position: 'relative', alignSelf: 'flex-end', bottom: 40, zIndex: 2 }]}>
                            <Image style={[GlobalStyles.inputAddButtonIcon]} source={Images.PLUS_SMALL} />
                        </TouchableOpacity>
                        {/* CHIPS */}                        
                        <FlatList 
                            style={{ marginTop: -10, marginLeft: -5, }}
                            contentContainerStyle={{flexDirection : "row", flexWrap : "wrap"}}  
                            data={children}
                            // keyExtractor={(item, index) => item.id.toString()}
                            renderItem={({item, index}) => _renderChip(item, index, 'child')}>
                        </FlatList> 
                    </View>
                    {/* RELATIVES */}
                    <View style={{ marginTop: 35 }}>
                        {/* INPUT */}
                        <TextInput Ref={relativeInput} value={relative} onKeyPress={addRelative} autoFocus={relativeAutoFocus} onChangeText={(name) => setRelative(name)} placeholder={Strings.RELATIVES}></TextInput>
                        <TouchableOpacity onPress={addRelative} style={[GlobalStyles.inputAddButton, { position: 'relative', alignSelf: 'flex-end', bottom: 40, zIndex: 2 }]}>
                            <Image style={[GlobalStyles.inputAddButtonIcon]} source={Images.PLUS_SMALL} />
                        </TouchableOpacity>
                        {/* CHIPS */}                        
                        <FlatList 
                            style={{ marginTop: -10, marginLeft: -5, }}
                            contentContainerStyle={{flexDirection : "row", flexWrap : "wrap"}}  
                            data={relatives}
                            //  keyExtractor={(item, index) => item.id.toString()}
                            renderItem={({item, index}) => _renderChip(item, index, 'other')}>
                        </FlatList> 
                    </View>
                </View>

                  

                    {/* SUCCESS MESSAGE */}
                    <Text style={[GlobalStyles.successText, { width: '100%', fontSize: 20, marginTop: 30 }]}>{successText}</Text>
                    
                </View>
            </View>           

            <TouchableOpacity onPress={save} disabled={formLoading} style={[GlobalStyles.secondaryButton, Styles.saveButton, ]}>
                <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.SAVE}</Text>
            </TouchableOpacity>

            {/* BOTTOM DRAWER */}
            <Drawer
                isVisible={bottomDrawerVisible}
                onClose={onBottomDrawerClose}
            >
                <View style={GlobalStyles.bottomDrawer}>

                    
                    {/* NAME */}  
                    <Text style={[GlobalStyles.textBrandon, Styles.name]}>{memberName}</Text>

                    {/* DIVIDER */}                        
                    <View style={[GlobalStyles.divider]}></View>

                    
                    <View style={[GlobalStyles.bottomDrawerContainer]}>                        

                        {/* COLOR PALETTE */}
                        {/* <View style={{alignSelf: 'center', width: isMobile && '100%', marginTop: 20}}>
                            <Text style={[GlobalStyles.drawerTitle,  ]}>{Strings.COLORS}</Text>
                            <FlatList 
                                showsHorizontalScrollIndicator={false}
                                style={{ marginTop: 20, marginBottom: 20, }}
                                data={randomColors}
                                horizontal={true}
                                keyExtractor={(item, index) => index.toString()}
                                renderItem={({item, index}) => _renderColorCircle(item, index)}>
                            </FlatList> 
                        </View> */}

                        {/* NAME */}
                        <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 20, marginBottom: 10, color: Colors.accent }]}>{Strings.NAME}</Text>
                        {/* INPUT NAME */}
                        <TextInput value={memberName} onChange={(text) => text && setMemberNameError('')} onChangeText={(text) => setMemberName(text)} placeholder={Strings.NAME} style={{ width: '100%'}}></TextInput>
                        {/* ERROR MESSAGE */}
                        <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{memberNameError}</Text>

                        {/* EMAIL */}
                        <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 20, marginBottom: 10, color: Colors.accent }]}>{Strings.EMAIL}</Text>
                        {/* INPUT EMAIL */}
                        <TextInput value={memberEmail} onChange={(text) => text && setMemberEmailError('')} onChangeText={(text) => setMemberEmail(text)} placeholder={Strings.EMAIL} style={{ width: '100%'}}></TextInput>
                        {/* ERROR MESSAGE */}
                        <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{memberEmailError}</Text>

                        {/* PHONE */}
                        <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 20, marginBottom: 10, color: Colors.accent }]}>{Strings.PHONE}</Text>
                        {/* INPUT PHONE */}
                        <TextInput value={memberPhone} onChange={(text) => text && setMemberPhoneError('')} onChangeText={(text) => setMemberPhone(text)} placeholder={Strings.PHONE} style={{ width: '100%'}}></TextInput>
                        {/* ERROR MESSAGE */}
                        <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{memberPhoneError}</Text>
                        
                        {/* SAVE PROFILE BUTTON */}
                        <TouchableOpacity onPress={saveProfile} disabled={formProfileLoading} style={[GlobalStyles.secondaryButton, { marginTop: 25, alignSelf: 'center'} ]}>
                            <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.SAVE}</Text>
                        </TouchableOpacity>

                        {/* DELETE PROFILE BUTTON */}
                        <TouchableOpacity onPress={deleteProfile} style={Styles.deleteProfileButton}>
                            <Text style={[ GlobalStyles.textBrandon, Styles.deleteProfileText,]}>{Strings.DELETE_PROFILE}</Text>
                        </TouchableOpacity>
                        
                    </View>

                    

                </View>
            </Drawer>

        </View>
    );
}

const Styles = StyleSheet.create({
    
    name: {
        fontSize: 25,
        alignSelf: 'flex-start',
        marginLeft: 8,
        marginBottom: 3
    },
    button: { 
        flexDirection: 'row',
        marginBottom: 10,
        paddingVertical: 15
    },
    saveButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    deleteProfileButton: {
        alignSelf: 'center', 
        marginBottom: 25,
        marginTop: 20,
    },
    deleteProfileText: {
        fontSize: 20,        
        color: Colors.red, 
    }
    
  
});
  
