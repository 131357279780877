import React, { useCallback, useEffect, useMemo, useRef,useState } from 'react';
import _ from 'lodash';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity, ScrollView, Platform, Modal, ImageBackground  } from "react-native-web";
import { TextInput,CheckBox } from 'react-native-web';
import moment from 'moment-timezone';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from 'react-device-detect';
import randomColor from 'randomcolor';
import Drawer from "react-bottom-drawer";
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import { apiNote, globalFunctions } from '../../services';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';
import ScreenNames from '../../constants/ScreenNames';
import { deleteUpload, listNotes, updateNote as modifyNote, upload, pinNote as pinThisNote, deletePinnedNote, listPinnedNotes, listLocations, listFamilies, sendEmail } from '../../hooks/useAPI';
import { Bird } from '../../components/Themed';
import { GoogleSignInButton, googleCalendarCreateEvent, googleCalendarList, googleCalendarUpdateEvent, getGoogleOauthRefreshToken, googleCalendarDeleteEvent } from '../../components/GoogleSignInButton';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const targetSearch = 'https://www.target.com/s?searchTerm=';
const targetImageUri = require('../../assets/images/target.png');
const amazonSearch = 'https://www.amazon.com/s?k=';
const wholeFoodsSearch = 'https://www.amazon.com/s?i=wholefoods&k=';
const amazonImageUri = 'https://static.vecteezy.com/system/resources/previews/014/018/561/non_2x/amazon-logo-on-transparent-background-free-vector.jpg';
const wholeFoodsImageUri = 'https://www.pngitem.com/pimgs/m/162-1627250_whole-foods-photo-booth-circle-hd-png-download.png';
const costcoSearch = 'https://www.costco.com/CatalogSearch?dept=All&keyword=';
const costcoImageUri = 'https://s40123.pcdn.co/wp-content/uploads/2016/01/costco.jpg.optimal.jpg';

export default function NoteScreen(props) {  

    const noteID = useParams().id; 

    const { user, getGoogleUser, setGoogleUser, subscriptionActive } = useUser();
    const { token } = useToken();

    function useQuery() {
        const { search } = useLocation();      
        return useMemo(() => new URLSearchParams(search), [search]);
    }    

    const share = useQuery().get('share');
    const [shareActive, setShareActive] = useState(() => {
        if(share === 'true') return true
        else return false;
    }); 

    const navigate = useNavigate();

    const randomColorParams = {
        luminosity: 'light', 
        format: 'rgba', 
        alpha: 0.5,
        count: 10,
    }

    const sharedPath = useLocation().pathname.split('/')[3];

    const maxNumber = 69;

    const [id] = useState('');
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState(Strings.NOTE.toLowerCase());
    const [pinned, setPinned] = useState(false);
    const [family, setFamily] = useState<any>({});
    const [location, setLocation] = useState<any>({});
    const [images, setImages] = useState([]);
    const [locations, setLocations] = useState([]);
    const [pinnedNoteId, setPinnedNotedId] = useState('');
    const [datetime, setDateTime] = useState('');
    const [endtime, setEndTime] = useState('');

    const [noteColor, setNoteColor] = useState('#fff');
    const [ categoryColors, setCategoryColors ] = useState(user?.noteColors || [
        {
            category: Strings.NOTE,
            color: '#fff',
        },
        {
            category: Strings.GROCERY,
            color: '#bcffbf',
        },
        {
            category: Strings.SHOPPING,
            color: '#ade0ff',
        },
        {
            category: Strings.REMINDER,
            color: '#fff3bc',
        },
        {
            category: Strings.PRACTICE,
            color: '#f2c8ff',
        },
    ]);

    useEffect(() => {
        const item = _.find(categoryColors, item => item.category === category);
        if(item?.color) setNoteColor(item.color);
    },[categoryColors, category]);

    const [note, setNote] = useState<any>([]);  
    const [primaryCalendar, setPrimaryCalendar] = useState({});
    const [inputTextHeight, setInputTextHeight] = useState(25);
    const [inputListItemTextHeight, setInputListItemTextHeight] = useState(25);
    const inputListItemTextHeightRef = useRef<any>(null);
    const [newListItemText, setNewListItemText] = useState('');
    const [categories, setCategories] = useState([Strings.GROCERY,Strings.PRACTICE,Strings.SHOPPING,Strings.REMINDER,Strings.NOTE]);
    const [modalVisible, setModalVisible] = useState(false);
    const [imageInModal, setImageInModal] = useState<any>({});

    const [bottomDrawerVisible, setBottomDrawerVisible] = useState<boolean>(shareActive);
    const [drawerType, setDrawerType] = useState(() => {
        if(shareActive) return 'family'
        else ''
    });
    
    const [randomColors, setRandomColors] = useState([noteColor, ...randomColor(randomColorParams)]);
    const [showDateTimeInput, setShowDateTimeInput] = useState(false);

    const [executed, setExecuted] = useState(false);
    const setInitialInputTextHeight = (height) => {        
        if (!executed) {
            setExecuted(true);
            // do something
            setInputTextHeight(height+20);
        }        
    }

    const addListItem = async(event) => {
        if(newListItemText && event.key === 'Enter'){

            try {
                const body = { active: false, text: newListItemText };
                setNewListItemText('');

                await updateNote({...note, items: [...note.items, body]}); 
                await globalFunctions.timeout(5);
                if (inputListItemTextHeightRef.current) {
                    inputListItemTextHeightRef.current.focus();
                    inputListItemTextHeightRef.current.clear();
                }  
            } catch(error) {
                console.log({ error });
            }
            
        }
        
    }

    const updateListItem = async(active, text, index) => {
        const newNote = {...note};
        newNote.items[index].active = active;
        newNote.items[index].text = text;

        await updateNote(newNote);

    }

    const updateDateTime = async(value) => {
        setDateTime(value);
        console.log({ value })
        //if(!value) value = '1970-01-01 01:00';
        await updateNote({ ...note, datetime: moment(value,'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYY-MM-DD HH:mm')});
    }
    
    const updateEndDateTime = async(value) => {
        setEndTime(value);
        //if(!value) value = '1970-01-01 01:00';
        await updateNote({ ...note, endtime: moment(value,'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYY-MM-DD HH:mm')});
    }

    const updateNote = async(data) => {
        setNote({...note, ...data })
        try {
            await modifyNote(note.id, { data });

        } catch (error) {
            console.log({ error })
        }
    }

    const pinNote = async(value) => {
        setPinned(value);
        if(value) {
            const res = await pinThisNote({ data: { user: user.id, note: note.id }, populate: { user: true }});
            setPinnedNotedId(res.id);
        }
        else {
            await deletePinnedNote(pinnedNoteId);
        }
    }

    const onBottomDrawerClose = useCallback(() => {
        setBottomDrawerVisible(false);
        setShowDateTimeInput(false);
    }, []);

    const openDrawer = (type) => {
        setDrawerType(type);
        setBottomDrawerVisible(true);
    }

    const deletePhoto = async(index) => {

        if(confirm(Strings.DELETE_PHOTO_CONFIRM)){
            const image = note?.images.filter((i,idx)=> idx === index);
            const items = note?.images.filter((i,idx)=> idx !== index);
            setNote({...note, images: items });

            try {

                await deleteUpload(image[0].id);
                
            } catch (e) {
                console.log(e);
                alert('Error deleting photo');
            }
        }        
    
    }

    const deleteNote = async() => {

        if(confirm(Strings.DELETE_NOTE_CONFIRM)){
            try {
                await apiNote.delete(note.id);
                //if(note?.googleCalendarEvent?.id) await deleteCalendar();

                navigate(ScreenNames.APP_HOME, { replace: false });
            } catch(error) {
                console.log({ error })
            }
        }        
    
    }

    const onImageChange = async(
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined,        
      ) => {

        const formData : any = new FormData();
        _.map(imageList, item => formData.append('files', item.file));        
        formData.append('ref', 'api::note.note');
        formData.append('refId', note.id);
        formData.append('field', 'images');
       
        try {
            const uploads = await upload(formData);
            console.log({ uploads })
            setNote({...note, images: [...note?.images, ...uploads]})
        } catch (error) {
            console.log({ error });
        }
    };

    const updateCategory = async(value, item) => {
        if(value) setCategory(_.capitalize(item));
        else setCategory(_.capitalize(Strings.NOTE));
        await updateNote({ category: _.capitalize(item) });
    }

    const fetchFamily = async() => {
        try {
            const res = await listFamilies({
                filters: {
                    user: user.id
                },
                populate: {
                    members: {
                        populate: {
                            user: true
                        }
                    }
                }
            });

            const arry : any = []
            _.map(res.data[0].attributes?.members?.data, member => arry.push({ id: member.id, ...member.attributes, user: { id: member.attributes.user?.data?.id, ...member.attributes?.user?.data?.attributes } }));
            console.log({ family: {
                id: res.data[0].id,
                ...res.data[0].attributes,
                members: arry,
                active: false
            } })

            setFamily({
                id: res.data[0].id,
                ...res.data[0].attributes,
                members: arry,
                active: false
            });
        } catch (error) {
            console.log({ error })
        }
    }

    const fetchLocations = async() => {
        try {
            const res = await listLocations({
                filters: {
                    active: true,
                    user: user.id
                }
            });

            const locs : any = [];
            _.map(res?.data, item => locs.push({id: item.id, ...item.attributes}))
            setLocations(locs);
        } catch (e) {
            
        }
    }

    const updateFamily = async(value, item, index) => {
        const fam = {...family};
        fam.members[index].active = value;    
        setFamily(fam);
        const exists = _.some(note?.members, member => member.id === item.id);
        
        if(exists) {
            const filtered = _.filter(note?.members, member => member.id !== item.id);
            await updateNote({ members: filtered });
        } else
        { 
            await updateNote({ members: [...note.members, fam.members[index]] });
        }
        
    }

    const removeFamily = async(item) => {
        if(confirm('Remove family member')){
            const filtered = _.filter(note?.members, member => member.id !== item.id);
            await updateNote({ members: filtered });
        }
    }


    const shareNote = async(item, index) => {
        //check if user has email
        //if user has not email, prompt them to update the family member in settings
        console.log({ item })
        if(!item?.user?.email && !item.email){
            if(confirm(Strings.SHARED_NOTE_EMPTY_EMAIL_PROMPT)){
                navigate(ScreenNames.APP_SETTINGS_FAMILY + '?note=' + note.id, { replace: false, state: { note: note.id } });
            }
            return false;
        }

        
        try {
            
            //if user does not exist share note via email and invite them to app - end
            if(!item?.user?.email){
                if(confirm(`${item.name} ${Strings.SHARED_NOTE_USER_NO_ACCOUNT}`)){
                    //invite the family member
                    await sendEmail({ data: { type: 'invite_user', template: 2, family, member: item, note, from: user } })
                    toast(Strings.INVITE_EMAIL_SENT);

                }

                return false;
            }
            else {
                // send to email
                await sendEmail({ data: { type: 'share_note', template: 1, family, member: item, note, from: user } })
                toast(Strings.SHARE_NOTE_SUCCESS);
            }
            
            setBottomDrawerVisible(false);
            setDrawerType('');

            //toast(Strings.SHARE_NOTE_SUCCESS);
            
        } catch (error) {
            console.log({ error })
        }
        
    }

    // const getPrimaryCalendar = async() => {
    //     try {
    //         const res = await googleCalendarList();
    //         const found = _.find(res?.data?.items, item => item.primary);
    //         setPrimaryCalendar(found);
    //         return found;
    //     } catch (error) {
    //         console.log({ error })
    //     }
    // }

    // const addToCalendar = async() => {
    //     try {
    //         const calendar = await getPrimaryCalendar();
    //         const event = {
    //             summary: note.title,
    //             location: note?.location?.address,
    //             description: note?.text,
    //             start: {
    //                 dateTime: datetime,
    //                 timeZone: calendar.timeZone
    //             },
    //             end: {
    //                 dateTime: endtime,
    //                 timeZone: calendar.timeZone
    //             }
    //         }
    //         const newTokens = await getGoogleOauthRefreshToken({ user: getGoogleUser() });
    //         setGoogleUser({ ...newTokens, refresh_token: getGoogleUser().refresh_token,})
    //         const res = await googleCalendarCreateEvent(calendar.id, event);
    //         await updateNote({...note, googleCalendarEvent: res.data, googleCalendarId: calendar.id, googleCalendarEventId: res.data.id });
    //     } catch (error) {
    //         console.log({ error })
    //     }
    // }

    // const updateCalendar = async() => {
    //     try {
    //         const calendarId = note.googleCalendarId;
    //         const eventId = note.googleCalendarEvent.id;
    //         const timeZone = note.googleCalendarEvent.start.timeZone;
    //         const event = {
    //             summary: note.title,
    //             location: note?.location?.address,
    //             description: note?.text,
    //             start: {
    //                 dateTime: datetime,
    //                 timeZone,
    //             },
    //             end: {
    //                 dateTime: endtime,
    //                 timeZone,
    //             }
    //         }
    //         const newTokens = await getGoogleOauthRefreshToken({ user: getGoogleUser() });
    //         setGoogleUser({ ...newTokens, refresh_token: getGoogleUser().refresh_token,})
    //         const res = await googleCalendarUpdateEvent(calendarId, eventId, event);
    //         await updateNote({...note, googleCalendarEvent: res.data });
    //         console.log({ res })
    //     } catch (error) {
    //         console.log({ error, status: error.response.status })
    //         switch (error.response.status) {
    //             case 401:
    //                 console.log('code 401')
    //                 break;
            
    //             default:
    //                 break;
    //         }
    //     }
    // }

    // const deleteCalendar = async() => {
    //     try {
    //         const calendarId = note.googleCalendarId;
    //         const eventId = note.googleCalendarEvent.id;
    //         const timeZone = note.googleCalendarEvent.start.timeZone;
    //         const newTokens = await getGoogleOauthRefreshToken({ user: getGoogleUser() });
    //         setGoogleUser({ ...newTokens, refresh_token: getGoogleUser().refresh_token,})
    //         const res = await googleCalendarDeleteEvent(calendarId, eventId);
    //         await updateNote({...note, googleCalendarEvent: res.data });
    //     } catch (error) {
    //         console.log({ error, status: error.response.status })
    //         switch (error.response.status) {
    //             case 401:
    //                 console.log('code 401')
    //                 break;
            
    //             default:
    //                 break;
    //         }
    //     }
    // }

    async function fetchNote(){
        try {
            setLoading(true);
            //await getPrimaryCalendar();
            const notes = await listNotes({
                pagination: {
                    limit: 1
                },
                filters: {
                    id: noteID,
                    user: user.id
                },
                populate: {
                    items: true,
                    images: true,
                    location: true,
                    members: {
                        populate: {
                            user: true
                        }
                    },
                    user: true
                }
            });

            const images : any = [];
            const imagesData = notes.data[0].attributes?.images?.data;
            _.map(imagesData, item => images.push({ id: item.id, ...item.attributes}));

            const members : any = [];
            const membersData = notes.data[0].attributes?.members?.data;
            _.map(membersData, item => members.push({ id: item.id, ...item.attributes, user: item.attributes.user.data }));

            const loc = {
                id: notes.data[0].attributes.location?.data?.id,
                ...notes.data[0].attributes.location?.data?.attributes
            }

            const res = {
                id: notes.data[0].id,
                ...notes.data[0].attributes,
                user: { id: notes.data[0].attributes.user.data.id, ...notes.data[0].attributes.user.data.attributes },
                images,
                members,
            }
            if(loc?.id) res.location = loc;

            setNote(res);

            setDateTime(res.datetime);
            setEndTime(res.endtime);

            const pinnedNotes = await listPinnedNotes({
                filters: {
                    user: user.id,
                    note: res.id,
                }
            })
            if(pinnedNotes.data[0] && pinnedNotes.data[0]?.id) {
                setPinned(true);
                setPinnedNotedId(pinnedNotes.data[0].id);
            }
            else setPinned(false);

            if(res?.location?.id) setLocation(res.location);
            
            setCategory(_.capitalize(res.category))
            
            setLoading(false);
        } catch(e) {
            
            setLoading(false);
        }
    }


    useEffect(() => {        

        (async() => {
            await fetchNote();
            await fetchLocations();
            await fetchFamily();
        })()

    },[]);
    useEffect(() => {        
        (async() => {
            if(datetime && endtime && getGoogleUser()?.access_token) {
                const datetime1 = moment(datetime);
                const datetime2 = moment(endtime);

                if(datetime1.isAfter(datetime2)) return false;

                if(note?.googleCalendarEvent?.id) {
                    //await updateCalendar();
                } else {
                    //await addToCalendar();
                }
            }
        })();
    },[datetime, endtime]);

    const _renderListItem = (item: any, index: number) => {
        return (
            <View style={[GlobalStyles.checkboxContainer, { marginBottom: 10 }]}>
                <View style={{ marginTop: 0 }}>
                    <CheckBox
                        color={Colors.secondary}
                        value={item.active}
                        onValueChange={(value) => updateListItem(value, item.text, index)}
                        style={[GlobalStyles.checkbox]}
                    />
                </View>
                <View style={{ flexDirection: 'column', width: '100%', marginTop: -5 }}>
                    <TextInput multiline={false} value={note.items[index].text} onChangeText={(value) => updateListItem(item.active, value, index)} style={[Platform.select({web: { outlineStyle: 'none' }}), GlobalStyles.noteInputText, GlobalStyles.checkboxLabel, { marginTop: 0 }, { width:'100%' }]} placeholderTextColor={Colors.gray5} placeholder={Strings.NOTE}></TextInput>
                    {(category === Strings.SHOPPING) && <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', marginLeft: 10 }}>
                        <TouchableOpacity onPress={() => window.open(`${amazonSearch}${note.items[index].text}`)} style={{ marginRight: 5 }}><Image style={{ width: 20, height: 20, borderRadius: 20 }} source={amazonImageUri} /></TouchableOpacity>
                        <TouchableOpacity onPress={() => window.open(`${wholeFoodsSearch}${note.items[index].text}`)} style={{ marginRight: 5 }}><Image style={{ width: 20, height: 20, borderRadius: 20 }} source={wholeFoodsImageUri} /></TouchableOpacity>
                        <TouchableOpacity onPress={() => window.open(`${costcoSearch}${note.items[index].text}`)} style={{ marginRight: 5 }}><Image style={{ width: 20, height: 20, borderRadius: 20 }} source={costcoImageUri} /></TouchableOpacity>
                        <TouchableOpacity onPress={() => window.open(`${targetSearch}${note.items[index].text}`)} style={{ marginRight: 5 }}><Image style={{ width: 20, height: 20, borderRadius: 20 }} source={targetImageUri} /></TouchableOpacity>
                    </View>}
                    {(category === Strings.GROCERY) && <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', marginLeft: 10 }}>
                        <TouchableOpacity onPress={() => window.open(`${amazonSearch}${note.items[index].text}`)} style={{ marginRight: 5 }}><Image style={{ width: 20, height: 20, borderRadius: 20 }} source={amazonImageUri} /></TouchableOpacity>
                        <TouchableOpacity onPress={() => window.open(`${wholeFoodsSearch}${note.items[index].text}`)} style={{ marginRight: 5 }}><Image style={{ width: 20, height: 20, borderRadius: 20 }} source={wholeFoodsImageUri} /></TouchableOpacity>
                        <TouchableOpacity onPress={() => window.open(`${costcoSearch}${note.items[index].text}`)} style={{ marginRight: 5 }}><Image style={{ width: 20, height: 20, borderRadius: 20 }} source={costcoImageUri} /></TouchableOpacity>
                        <TouchableOpacity onPress={() => window.open(`${targetSearch}${note.items[index].text}`)} style={{ marginRight: 5 }}><Image style={{ width: 20, height: 20, borderRadius: 20 }} source={targetImageUri} /></TouchableOpacity>
                    </View>}
                </View>
            </View>
        );
    }

    const _renderColorCircle = (item: any, index: number) => {      
        if(noteColor === item){
            return (
                <View style={[GlobalStyles.circle, { backgroundColor: item, marginRight: 10, borderWidth: 2, borderColor: Colors.black }]}></View>
            );
        }  else {
            return (
                <Pressable onPress={() => updateNote({...note, color: item})} style={{ marginRight: 10,}}>
                    <View style={[GlobalStyles.circle, { backgroundColor: item }]}></View>
                </Pressable>
            );
        } 
        
    }

    const _renderFamilyList = (item: any, index: number) => {
        const active = _.some(note?.members, member => member.id === item.id)
        return (
            <View style={[GlobalStyles.checkboxContainer,  { marginBottom: 15, alignItems: 'center' }]}>
                <View style={[{ height: 25, width: 25, marginRight: 10 }]}>
                    {item.user.id && <Bird width={25} opacity={1} color={Colors.secondary}/>}
                </View>
                {!shareActive && <CheckBox
                    color={Colors.secondary}
                    value={active ? active : item.active}
                    onValueChange={(value) => updateFamily(value, item, index)}
                    style={GlobalStyles.checkbox}
                />}
                <View style={{ marginLeft: !shareActive && 20, flexDirection: 'row', width: '100%'}}>
                    <Text style={[GlobalStyles.labelTitle]}>{item.name}</Text>
                    <View style={[GlobalStyles.dot, { marginLeft: 15, backgroundColor: item.color, marginTop: 9 }]}></View>
                    {shareActive && <TouchableOpacity disabled={false} onPress={() => {shareNote(item, index); updateFamily(true, item, index)}} style={{ position: 'absolute', right: 35, borderColor: Colors.linkBlue, borderWidth: 1, borderRadius: 5}}>
                        <Text style={[Styles.shareText]}>{Strings.SHARE}</Text>
                    </TouchableOpacity>}
                </View>
            </View>
        );
    }

    const _renderCategoryList = (item: any, index: number) => {   
        let active = false; 
        if( _.capitalize(category) ==  _.capitalize(item)) active = true;
        return (
            <View style={[GlobalStyles.checkboxContainer,  { marginBottom: 15 }]}>
                <CheckBox
                    color={Colors.secondary}
                    value={active}
                    onValueChange={(value) => updateCategory(value,item)}
                    style={GlobalStyles.checkbox}
                />
                <View style={{ marginLeft: 20, flexDirection: 'row'}}>
                    <Text style={[GlobalStyles.labelTitle]}>{item}</Text>
                    <View style={[GlobalStyles.dot, { marginLeft: 30, backgroundColor: item.color, marginTop: 9 }]}></View>
                </View>
            </View>
        );
    }

    const _renderLocationList = (item: any, index: number) => {   
        let active = false; 
        if(item.id == location.id) active = true;
        return (
            <View style={[GlobalStyles.checkboxContainer,  { marginBottom: 15 }]}>
                <CheckBox
                    color={Colors.secondary}
                    value={active}
                    onValueChange={(value) => { 
                        if(value) {
                            setLocation(item);
                            updateNote({ location: item });
                        }
                        else {
                            setLocation({});
                            updateNote({ location: '' });
                        }
                    }}
                    style={GlobalStyles.checkbox}
                />
                <View style={{ marginLeft: 20, flexDirection: 'row'}}>
                    <Text style={[GlobalStyles.labelTitle]}>{item.name}</Text>
                    <View style={[GlobalStyles.dot, { marginLeft: 30, backgroundColor: item.color, marginTop: 9 }]}></View>
                </View>
            </View>
        );
    }

    const _renderLocation = () => {
        if(location?.id)
        return (
            <View style={{flexDirection : "row", flexWrap : "wrap", marginLeft: -10 }}>
                <View style={[GlobalStyles.bigChip, GlobalStyles.bigChipShadow, { backgroundColor: '#5E8BC3', margin: 5, }]}>
                    <Image style={[Styles.chipIcon, { marginLeft: 15, marginTop: 8}]} source={Images.MAP_PIN_WHITE}></Image>
                    <Text style={[ GlobalStyles.bigChipText, { paddingLeft: 10}]}>{location?.name}</Text>
                </View>
            </View>
            
        )
    }

    const _renderImageList = (item: any, index: number) => {
        return (
            <Pressable onPress={() => { setModalVisible(true); setImageInModal(item); }} onLongPress={() => deletePhoto(index)} style={{ marginRight: 15, marginBottom: 15,}}>
                <Image style={[GlobalStyles.noteImage, GlobalStyles.noteImageShadow]} source={item.url}></Image>
            </Pressable>
        );
    }
    
    useEffect(() => {
        if(!token) return navigate(ScreenNames.SIGNUP, { replace: false });
        else if(!subscriptionActive) return navigate(ScreenNames.APP_SETTINGS, { replace: false });
        else if(!note) return navigate(ScreenNames.APP_HOME);
    },[]);

    return (
        <View style={[GlobalStyles.appBackground, { backgroundColor: noteColor }]}> 
            {/* HEADER */}
            <View style={[GlobalStyles.appHeader, { backgroundColor: noteColor }]}>

                <View style={[GlobalStyles.appHeaderContainer, { zIndex: 10}]}>
                    {/* BACK */}
                    <Pressable onPress={() => { navigate(ScreenNames.APP_HOME, { replace: false }); }} style={{ alignSelf: 'flex-start', position: 'absolute'}}>
                        <Image style={Styles.icon} source={Images.ARROW_LEFT}></Image>
                    </Pressable>
                    {/* PIN */}
                    { pinned ?
                        <Pressable onPress={() => pinNote(false)} style={{ alignSelf: 'center', position: 'absolute', left: isMobile ? width / 5 : width / 3 }}>
                            <Image style={[Styles.icon]} source={Images.PIN_FILL}></Image>
                        </Pressable> :
                        <Pressable onPress={() => pinNote(true)} style={{ alignSelf: 'center', position: 'absolute', left: isMobile ? width / 5 : width / 3}}>
                            <Image style={[Styles.icon]} source={Images.PIN}></Image>
                        </Pressable>
                    }
                    {/* SWITCH BUTTON */}
                    <Pressable onPress={() => openDrawer('category')} style={[GlobalStyles.iconButtonSmall, Styles.inputIconButton]}>
                        { _.capitalize(category) == _.capitalize(Strings.GROCERY) && <Image style={Styles.littleIcon} source={Images.SHOPPING_WHITE}></Image>}
                        {  _.capitalize(category) == _.capitalize(Strings.PRACTICE) && <Image style={Styles.littleIcon} source={Images.PRACTICE_WHITE}></Image>}
                        {  _.capitalize(category) == _.capitalize(Strings.SHOPPING) && <Image style={Styles.littleIcon} source={Images.SHOPPING_BAG_WHITE}></Image>}
                        {  _.capitalize(category) == _.capitalize(Strings.REMINDER) && <Image style={Styles.littleIcon} source={Images.BELL_WHITE}></Image>}
                        {  _.capitalize(category) == _.capitalize(Strings.NOTE) && <Image style={Styles.littleIcon} source={Images.NOTEBOOK_WHITE}></Image>}
                        {/* { category == 'note' && <Image style={Styles.littleIcon} source={Images.NOTEBOOK_WHITE}></Image>} */}
                    </Pressable>
                    {/* TRASH */}
                    {note?.user?.id === user.id && <Pressable onPress={deleteNote} style={{ alignSelf: 'center', position: 'absolute', right: isMobile ? width / 5 : width / 3}}>
                        <Image style={[Styles.icon]} source={Images.TRASH}></Image>
                    </Pressable>}
                    {/* SHARE */}
                    {note?.user?.id === user.id && <Pressable onPress={() => {
                        setDrawerType('family');
                        setShareActive(true);
                        setBottomDrawerVisible(true);
                    }} style={{ alignSelf: 'flex-end', position: 'absolute'}}>
                        <Image style={Styles.icon} source={Images.SHARE}></Image>
                    </Pressable>}
                </View>
                
            </View>
            
            {/* SITE CONTAINER */}
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={[GlobalStyles.appContainer, { marginTop: 100, minHeight: 100}]}>
                {/* NOTE SECTION */}                    
                <View style={{ marginLeft: 20, marginRight: 20, justifyContent: 'center', minHeight: '5%' }}>
                    {/* <Text style={[GlobalStyles.listTitle, { alignSelf: 'flex-start'}]}>{Strings.TITLE}</Text> */}
                    <TextInput value={note.title} onChangeText={(title) => updateNote({...note, title})} style={[Platform.select({web: { outlineStyle: 'none', }}), GlobalStyles.noteInputTitle]} placeholderTextColor={Colors.gray5} placeholder={Strings.TITLE}></TextInput>
                    <TextInput onLayout={(event) => setInitialInputTextHeight(event.nativeEvent.layout.height)} onContentSizeChange={(event) => { setInputTextHeight(event.nativeEvent.contentSize.height)}} multiline={true} value={note.text} onChangeText={(text) => updateNote({...note, text})} style={[Platform.select({web: { outlineStyle: 'none', }}),GlobalStyles.noteInputText, { marginTop: 10, height: inputTextHeight}]} placeholderTextColor={Colors.gray5} placeholder={Strings.NOTE}></TextInput>

                    {/* CHECK LIST SECTION */}
                    { note.type === 'list' && 
                    <View> 
                        <FlatList 
                            style={{ marginTop: 20 }}
                            data={note?.items || []}
                            showsHorizontalScrollIndicator={false}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({item, index}) => _renderListItem(item, index)}>
                        </FlatList> 
                        <View style={[GlobalStyles.checkboxContainer, { marginLeft: 15 }]}>
                            <Image style={[Styles.littleIcon, GlobalStyles.checkbox]} source={Images.PLUS}></Image>
                            <TextInput ref={inputListItemTextHeightRef} autoFocus={true} onContentSizeChange={(event) => { setInputListItemTextHeight(event.nativeEvent.contentSize.height)}} onKeyPress={(event) => addListItem(event)} multiline={false} value={newListItemText} onChangeText={(value) => setNewListItemText(value)} style={[Platform.select({web: { outlineStyle: 'none', }}), GlobalStyles.noteInputText, GlobalStyles.checkboxLabel, { marginTop: 10, height: inputListItemTextHeight, width: '100%',  }]} placeholderTextColor={Colors.gray5} placeholder={Strings.ADD_ITEM}></TextInput>
                        </View>
                    </View>}

                    <View style={{marginTop: 25}}></View>

                    {/* IMAGES */}
                    <FlatList 
                        style={{ marginBottom: 20, marginLeft: -5 }}
                        contentContainerStyle={{flexDirection : "row", flexWrap:'wrap' }}  
                        data={note?.images || []}
                        showsHorizontalScrollIndicator={false}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({item, index}) => _renderImageList(item, index)}>
                    </FlatList> 

                    {/* FAMILY MEMBERS */}
                    <FlatList 
                        style={{ marginLeft: -10 }}
                        contentContainerStyle={{flexDirection : "row", flexWrap: 'wrap' }}
                        data={note?.members || []}
                        showsHorizontalScrollIndicator={false}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({item, index}) => {
                            return (
                                <Pressable onPress={() => removeFamily(item)} style={[GlobalStyles.bigChip, GlobalStyles.bigChipShadow, { backgroundColor: item.color, margin: 5, }]}>
                                    <Text style={[ GlobalStyles.bigChipText ]}>{item.name}</Text>
                                </Pressable>
                            )
                        }}>
                    </FlatList> 

                     {/* LOCATION */}
                     {  _renderLocation() }

                    {/* DATETIME */}
                    { datetime && datetime != '1969-12-31 20:12' ? <View style={{flexDirection : "row", flexWrap : "wrap", marginLeft: -10  }}>
                        <TouchableOpacity onPress={() => { openDrawer('datetime'); setShowDateTimeInput(true)}} style={[GlobalStyles.bigChip, GlobalStyles.bigChipShadow, { backgroundColor: '#69AEFF', margin: 5, }]}>
                            <Image style={[Styles.chipIcon, { marginLeft: 15, marginTop: 8}]} source={Images.ALARM_CLOCK_WHITE}></Image>
                            <Text style={[ GlobalStyles.bigChipText, { paddingLeft: 10}]}>{moment(datetime).format('MMM D [@] h:mm a')} ({moment(datetime).fromNow()})</Text>
                        </TouchableOpacity>
                    </View> : null}

                    <View style={{marginBottom: 100}}></View>

                    

                </View>
            </ScrollView>

            <ToastContainer />

            {/* BOTTOM NAVIGATION */}
            <View style={[GlobalStyles.appBottomNavigation, { backgroundColor: noteColor }]}>
                <View style={[GlobalStyles.appBottomNavigationContainer, { flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}]}>
                    {/* TEXT */}
                    { note.type === 'list' &&
                        <Pressable onPress={() => updateNote({...note, type: 'text'})} style={{ }}>
                        <Image style={Styles.icon} source={Images.TEXT}></Image>
                    </Pressable> }
                    {/* LIST */}
                    { note.type === 'text' && 
                    <Pressable onPress={() => updateNote({...note, type: 'list'})} style={{ }}>
                        <Image style={Styles.icon} source={Images.LIST}></Image>
                    </Pressable> }
                    {/* PALETTE */}
                    {/* <Pressable onPress={() => openDrawer('colors')} style={{}}>
                        <Image style={Styles.icon} source={Images.PALETTE}></Image>
                    </Pressable> */}
                    {/* PICTURE */}
                    <ImageUploading
                    multiple
                    value={images}
                    onChange={onImageChange}
                    maxNumber={maxNumber}
                    >
                        {({
                            imageList,                            
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,                            
                            dragProps
                        }) => (
                            <Pressable onPress={onImageUpload} {...dragProps} style={{ }}>
                                <Image style={Styles.icon} source={Images.PICTURE}></Image>
                            </Pressable>
                        )}
                    </ImageUploading>
                    
                    {/* USER ADD */}
                    <Pressable onPress={() => {
                        setShareActive(false);
                        openDrawer('family')}} style={{ }}>
                        <Image style={Styles.icon} source={Images.USER_ADD}></Image>
                    </Pressable>
                    {/* MARKER ADD */}
                    <Pressable onPress={() => openDrawer('location')} style={{ }}>
                        <Image style={Styles.icon} source={Images.MAP_MARKER_PLUS}></Image>
                    </Pressable>
                    {/* TIME ADD */}
                    <Pressable onPress={() => openDrawer('datetime')}  style={{ }}>
                        <Image style={Styles.icon} source={Images.CLOCK_ADD}></Image>
                    </Pressable>
                </View>
                
            </View>

            
            
            {/* BOTTOM DRAWER */}
            <Drawer
                isVisible={bottomDrawerVisible}
                onClose={onBottomDrawerClose}
            >
                <View style={GlobalStyles.bottomDrawer}>
                    {/* COLOR PALETTE */}
                    { drawerType == 'colors' && 
                    <View style={[GlobalStyles.bottomDrawerContainer]}>
                        <View style={{alignSelf: 'center', width: isMobile && '100%'}}>
                            <Text style={[GlobalStyles.drawerTitle,  ]}>{Strings.COLORS}</Text>
                            <FlatList 
                                showsHorizontalScrollIndicator={false}
                                style={{ marginTop: 20, marginBottom: 20, }}
                                data={randomColors}
                                horizontal={true}
                                keyExtractor={(item, index) => index.toString()}
                                renderItem={({item, index}) => _renderColorCircle(item, index)}>
                            </FlatList> 
                        </View>
                    </View> } 
                    { drawerType == 'family' &&
                    <View style={[GlobalStyles.bottomDrawerContainer]}>
                        <Text style={[GlobalStyles.drawerTitle,  ]}>{Strings.FAMILY}</Text>
                        <FlatList 
                            showsHorizontalScrollIndicator={false}
                            style={{ marginTop: 20, marginBottom: 20, }}
                            data={family?.members || []}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({item, index}) => _renderFamilyList(item, index)}>
                        </FlatList> 
                    </View> }
                    { drawerType == 'category' &&
                    <View style={[GlobalStyles.bottomDrawerContainer]}>
                        <Text style={[GlobalStyles.drawerTitle,  ]}>{Strings.CATEGORY}</Text>
                        <FlatList 
                            style={{ marginTop: 20, marginBottom: 20, }}
                            data={categories}
                            showsHorizontalScrollIndicator={false}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({item, index}) => _renderCategoryList(item, index)}>
                        </FlatList> 
                    </View> }

                    { drawerType == 'location' &&
                    <View style={[GlobalStyles.bottomDrawerContainer]}>
                        <Text style={[GlobalStyles.drawerTitle,  ]}>{Strings.LOCATION}</Text>
                        <FlatList 
                            style={{ marginTop: 20, marginBottom: 5, }}
                            data={locations}
                            showsHorizontalScrollIndicator={false}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({item, index}) => _renderLocationList(item, index)}>
                        </FlatList> 
                        <TouchableOpacity onPress={() => navigate(ScreenNames.APP_SETTINGS_ADD_LOCATIONS + '?note=' + note.id)} style={{ marginBottom: 20, flexDirection: "row",}}>
                            {/* ADD LOCATION */}
                            <View style={[GlobalStyles.iconButtonSmall, Styles.inputIconButton,]}>
                                <Image style={[Styles.littleIcon, { alignSelf: "center", }]} source={Images.PLUS_WHITE}></Image>                                
                            </View>
                            <Text style={[GlobalStyles.textBrandon, { marginLeft: 15, marginTop: 5, fontSize: 23  }]}>{Strings.ADD_LOCATION}</Text>
                        </TouchableOpacity>
                    </View> }

                    { drawerType == 'datetime' && !showDateTimeInput &&
                        // <Datetime renderInput={(props, openCalendar, closeCalendar) => {}} />
                        <View style={[GlobalStyles.bottomDrawerContainer]}>
                            <Text style={[GlobalStyles.drawerTitle,  ]}>{Strings.PICK_DATE_TIME}</Text>                        
                            <TouchableOpacity onPress={() => setShowDateTimeInput(true)} style={{ marginBottom: 20, marginTop: 20, flexDirection: "row",}}>
                                <View style={[GlobalStyles.iconButtonSmall, Styles.inputIconButton,]}>
                                    <Image style={[Styles.littleIcon, { alignSelf: "center", }]} source={Images.PLUS_WHITE}></Image>                                
                                </View>
                                <Text style={[GlobalStyles.textBrandon, { marginLeft: 15, marginTop: 5, fontSize: 23  }]}>{Strings.PICK_DATE_TIME}</Text>
                            </TouchableOpacity>
                        </View> 
                    }
                    { drawerType == 'datetime' && showDateTimeInput &&
                        // <Datetime renderInput={(props, openCalendar, closeCalendar) => {}} />
                        <View style={[GlobalStyles.bottomDrawerContainer]}>
                            <Text style={[GlobalStyles.drawerTitle,  ]}>{Strings.PICK_DATE_TIME}</Text> 
                            <Datetime initialValue={new Date(datetime || '')} initialViewDate={new Date(datetime || '')} value={datetime} onChange={updateDateTime} renderInput={(props, openCalendar, closeCalendar) => {
                                return(
                                    <View {...props} style={Styles.inputContainer}>
                                        {/* INPUT */}
                                        <TextInput value={moment(datetime).format('MM-DD-YYYY h:mm a')} placeholderTextColor={Colors.secondary} style={[Platform.select({web: { outlineStyle: 'none' }}),GlobalStyles.roundedTextInput, {marginBottom: 20, marginTop: 20, width: '100%' }]} placeholder={Strings.PICK_DATE_TIME}></TextInput>
                                        {/* ICON */}
                                        <Pressable style={Styles.inputIcon} >
                                            <Image style={[Styles.chipIcon]} source={Images.CALENDAR}></Image>
                                        </Pressable>
                                        
                                    </View>
                                );
                            }} />
                            <View style={{ flexDirection: 'row',  justifyContent: 'space-between', width: '100%' }}>
                                <TouchableOpacity onPress={() => updateDateTime(null)} style={{marginLeft: 10}}>
                                    <Text style={[GlobalStyles.textBrandon, { fontSize: 20, alignSelf: 'flex-start'}]}>Clear</Text>
                                </TouchableOpacity>
                            </View> 
                            <Datetime initialValue={new Date(endtime || datetime || '')} initialViewDate={new Date(endtime || datetime || '')} value={endtime || datetime} onChange={updateEndDateTime} renderInput={(props, openCalendar, closeCalendar) => {
                                return(
                                    <View {...props} style={Styles.inputContainer}>
                                        {/* INPUT */}
                                        <TextInput value={moment(endtime || datetime).format('MM-DD-YYYY h:mm a')} placeholderTextColor={Colors.secondary} style={[Platform.select({web: { outlineStyle: 'none' }}),GlobalStyles.roundedTextInput, {marginBottom: 20, marginTop: 20, width: '100%' }]} placeholder={Strings.PICK_DATE_TIME}></TextInput>
                                        {/* ICON */}
                                        <Pressable style={Styles.inputIcon} >
                                            <Image style={[Styles.chipIcon]} source={Images.CALENDAR}></Image>
                                        </Pressable>
                                        
                                    </View>
                                );
                            }} />
                            <View style={{ flexDirection: 'row',  justifyContent: 'space-between', width: '100%' }}>
                                <TouchableOpacity onPress={() => updateEndDateTime(null)} style={{marginLeft: 10}}>
                                    <Text style={[GlobalStyles.textBrandon, { fontSize: 20, alignSelf: 'flex-start'}]}>Clear</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{ marginBottom: height * .4}}></View>
                            
                        </View> 
                    }
                
                </View>
            </Drawer>

            {/* IMAGE MODAL */}
            <View style={GlobalStyles.imageModalCenteredView}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={() => setModalVisible(!modalVisible)}
                >
                    <View style={GlobalStyles.imageModalCenteredView}>
                        <ImageBackground source={{ uri: imageInModal.url }} style={GlobalStyles.imageModalView}>
                            <Pressable
                                style={[Styles.button, { backgroundColor: Colors.accent, position: 'absolute', bottom: 10, right: 10,}]}
                                onPress={() => setModalVisible(!modalVisible)}
                                >
                                <Text style={[GlobalStyles.textBrandon, { fontSize: 20, paddingLeft: 15, paddingRight: 15, color: Colors.white }]}>{Strings.CLOSE}</Text>
                            </Pressable>
                        </ImageBackground>
                    </View>
                </Modal>
            </View>

            
            
        </View>
    );


   
}

const Styles = StyleSheet.create({
    
    icon: {
        width: isMobile ? 24 : 24,
        height: isMobile ? 24 : 24,
        resizeMode: 'contain',
        
    },
    inputIcon: {
        position: 'absolute',
        alignSelf: 'flex-end',
        right: 13,
        zIndex: 1,
    },
    chipIcon: {
        width: isMobile ? 24 : 24,
        height: isMobile ? 24 : 24,
        resizeMode: 'contain',
        
    },
    inputIconButton: {
       // position: 'absolute',
        //alignSelf: 'flex-start',
        //left: 5,
        zIndex: 1,
    },
    littleIcon: {
        resizeMode: 'contain',
        width: 16,
        height: 16,        
    },
    addIcon: {
        resizeMode: 'contain',
        width: 50,
        height: 50,        
    },
    inputContainer: { 
        //flexDirection: 'row', 
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',

    },
    button: {
        borderRadius: 50,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    textStyle: {
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    shareText: {
        fontFamily: 'Brandon-Regular',
        fontSize: 17,
        color: Colors.linkBlue,
        paddingVertical: 2,
        paddingHorizontal: 12
    },
    
  
});
  
//   NameScreen.propTypes = {
//     setToken: PropTypes.func.isRequired
//   }
  