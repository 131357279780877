import React, { useEffect, useMemo, useRef,useState } from 'react';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity, ScrollView, SectionList, Platform } from "react-native";
import { CheckBox } from 'react-native-web';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import emailValidator from 'email-validator';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Screens from '../../constants/Screens';
import { apiAuth, apiChild, apiListItem, apiNote, apiNoteDetached, apiParent, apiRelative, apiSharedNotePKs, apiSharedNotes, globalFunctions } from '../../services';
import PropTypes from 'prop-types';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';

import IconHeader from '../../components/Headers/IconHeader';
import QuickNote from '../../components/QuickNote';
import useNotes from '../../hooks/useNotes';
import ScreenNames from '../../constants/ScreenNames';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function DashboardScreen() {  

    const { user, setUser } = useUser();
    const { verifyToken } = useToken();

    const [ notes, setNotes ] = useState([]);
    const [ sectionNotes, setSectionNotes ] = useState([]);

    const navigate = useNavigate();    

    function useQuery() {
        const { search } = useLocation();
      
        return useMemo(() => new URLSearchParams(search), [search]);
    }    

    let catCheck = [Strings.NOTE, Strings.GROCERY, Strings.SHOPPING, Strings.REMINDER, Strings.PRACTICE, ''] 

    
    const pinned = useQuery().get('pinned');
    const memberID = useQuery().get('member');
    const category = useQuery().get('category');
    const memberType = useQuery().get('memberType');
    const shared = useQuery().get('shared');


    const [title, setTitle] = useState('');
    if(pinned == 'true') setTitle(Strings.PINNED);

    const [member, setMember] = useState({ name: '', pk: '', });
    const [members, setMembers] = useState([]);
    

    // user.familyMembers.parents.map((item) => members.push(item));
    // user.familyMembers.children.map((item) => members.push(item));
    // user.familyMembers.relatives.map((item) => members.push(item));

    // if(memberID) {        
    //     member = members.find((i) => i.id === memberID);
    //     title = Strings.NOTES_FOR + ' ' + member.name;
    // }

    if(category) setTitle(category);


    //const [familyMembers, setFamilyMembers] = useState([]);

    const loadInfo = async() => {
       
        try {

            if(shared) setTitle(Strings.SHARED_WITH_YOU)

            // if(memberID) {        
            //     member = members.find((i) => i.pk === memberID);
            //     title = Strings.NOTES_FOR + ' ' + member.name;
            //     let res = await api
            // }
            let memberData = {}
            if(memberID && memberType){
                if(memberType === 'parent'){
                    let res = await apiParent.retrieve(memberID);
                    setTitle(res.data.name);
                    setMember(res.data);
                    await findNotes({member: res.data});
                }
                if(memberType === 'child'){
                    let res = await apiChild.retrieve(memberID);
                    setTitle(res.data.name);
                    setMember(res.data);
                    await findNotes({member: res.data});
                }
                if(memberType === 'relative'){
                    let res = await apiRelative.retrieve(memberID);
                    setTitle(res.data.name);
                    setMember(res.data);
                    await findNotes({member: res.data});
                }
            }
            console.log(category)
            if(!category && !memberID && !pinned) await findNotes({member: {}})

            

            // let members = []

            // for await(let pk of user.family.parents){
            //     let res = await apiParent.retrieve(pk);
            //     members.push(res.data);
            // }
            // for await(let pk of user.family.children){
            //     let res = await apiChild.retrieve(pk);
            //     members.push(res.data);
            // }
            // for await(let pk of user.family.relatives){
            //     let res = await apiRelative.retrieve(pk);
            //     members.push(res.data);
            // }
            

            // setMembers(members);

        } catch (e) {
            console.log(e.response)
            if(e.response.data.code === 'token_not_valid'){
                await verifyToken();
                await loadInfo();
            }
            if(e.response.data.code === 'user_not_found'){
                navigate(ScreenNames.SIGNUP);
                return false;
            }
        }
    }

    

    const findNotes = async({member}) => {

        try {
            let res = await apiNote.list();
            let notes = res.data;
            let sections = [], members = [];
            console.log('member && member.pk',notes)
            //Pinned   
            if((pinned == 'true') || (member && member.pk)){
                let pinned = notes.filter((i) => i.pinned === true);
                let newPinnedList = []
                for await (let note of pinned){
                    let pinnedListItems = []; 
                    
                    for await (let id of note.list){
                        let res = await apiListItem.retrieve(id);
                        if(res.data) pinnedListItems.push(res.data);
                    }

                    let newNote = {...note};
                    newNote.list = pinnedListItems;
                    newPinnedList.push(newNote);
                    
                }
                if(pinned.length > 0) sections.push({ title: Strings.PINNED, data: newPinnedList, horizontal: false });

            }  

            if(!category && !memberID && !pinned && !shared){
                
                let newList = []
                for await (let note of notes){
                    let listItems = []; 
                    
                    for await (let id of note.list){
                        let res = await apiListItem.retrieve(id);
                        if(res.data) listItems.push(res.data);
                    }

                    let newNote = {...note};
                    newNote.list = listItems;
                    newList.push(newNote);
                    
                }
                sections.push({ title: 'All Notes', data: newList, horizontal: false });

            }         

            if(shared){
                let items = [];
                let shared = await apiSharedNotes.list();

                

                for await(let item of shared.data){
                    let pk = item.sharedNotes[0];
                    try {
                        let sharedNoteData = await apiSharedNotePKs.list();
                        let note = sharedNoteData.data.find(i => i.pk === pk);
                        let noteData = await apiNoteDetached.retrieve(note.notePk);
                        let listItems = [];
                        for await(let id of noteData.data.list){
                            let listItemData = await apiListItem.retrieve(id);
                            listItems.push(listItemData.data);
                        }
                        noteData.data.list = listItems;
                        noteData.data.detached = true;
                        items.push(noteData.data)
                    } catch (e) {
                        console.log(e);
                    }
                }
                sections.push({ title: Strings.SHARED_WITH_YOU, data: items, horizontal: true, });
            }
                
            //Family         
            // for await (let member of members){
            //     let memberNotes = [];

            //     for await (let note of notes){
            //         let noteListItems = [];  
                    
            //         for await (let id of note.list){
            //            let res = await apiListItem.retrieve(id);
            //            noteListItems.push(res.data);
            //         }

            //         let newNote = {...note};
            //         newNote.list = noteListItems

            //         if(member.type === 'parent' && note.parents.some((i) => i === member.pk)) memberNotes.push(newNote);
            //         if(member.type === 'child' && note.children.some((i) => i === member.pk)) memberNotes.push(newNote);
            //         if(member.type === 'relative' && note.relatives.some((i) => i === member.pk)) memberNotes.push(newNote);
                    
            //     }

            //     if(memberNotes.length > 0) sections.push({ title: '@' + member.name, data: memberNotes, horizontal: true })
            // }       
            if(member && member.pk){
                let memberNotes = [];
                for await (let note of notes){
                    let noteListItems = [];  
                    for await (let id of note.list){
                        let res = await apiListItem.retrieve(id);
                        if(res.data) noteListItems.push(res.data);
                    }
                    let newNote = {...note};
                    newNote.list = noteListItems
                    if((note.parents.some((i) => i === member.pk)) || (note.children.some((i) => i === member.pk)) || (note.relatives.some((i) => i === member.pk))) memberNotes.push(newNote);                        
                }
                if(memberNotes.length > 0) sections.push({ title: '@' + member.name, data: memberNotes, horizontal: true })
            }

            if(category){
                //Categories
                let item = notes.filter((i) => i.category === category);
                if(item.length > 0) sections.push({ title: category, data: item, horizontal: true });
            }

            setSectionNotes(sections);
        } catch (e) {
            console.log(e.response)
            if(e.response.data.code === 'token_not_valid'){
                await verifyToken();
                await loadInfo();
            }
            if(e.response.data.code === 'user_not_found'){
                navigate(ScreenNames.SIGNUP);
                return false;
            }
        }
      
    }

    useEffect(() => { 

        loadInfo();
              

    },[]); 



    // RENDER NOTE
    const _renderNote = (item: any, index: number) => { 
        let route = ScreenNames.APP_NOTE;
        if(item.detached) route = ScreenNames.APP_NOTE_SHARED  
        return (
            <TouchableOpacity onPress={() => navigate(route + '/' + item.pk, { replace: false })} style={[GlobalStyles.note, GlobalStyles.noteDropShadow, { marginLeft: 20, marginTop: 20, marginBottom: 20, marginRight: 0, backgroundColor: item.color, }]}>
                <View style={[GlobalStyles.noteContainer, {overflow: 'hidden'}]}>
                    <Text style={[GlobalStyles.noteTitle]}>{item.title}</Text>
                    { item.type == 'text' && <Text style={[GlobalStyles.noteText, { marginTop: 5, overflow: 'hidden' }]}>{item.text}</Text> }
                    {/* CHECK LIST SECTION */}
                    { item.type == 'list' && 
                        <FlatList 
                            style={{ marginTop: 10 }}
                            data={item.list}
                            showsHorizontalScrollIndicator={false}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({item, index}) => _renderListItem(item, index)}>
                        </FlatList> }

                </View>
            </TouchableOpacity>
        );
    }

    const _renderListItem = (item: any, index: number) => {        

        return (
            <View style={GlobalStyles.checkboxContainer}>
                <CheckBox
                    color={Colors.secondary}
                    value={item.active}
                    style={GlobalStyles.checkbox}
                />
                <Text style={[GlobalStyles.noteInputTextSmall, { marginTop: 10, margin: 5 }]}>{item.text}</Text>
            </View>
        );
    }

    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} title={title} />
            
            {/* SITE CONTAINER */}
            <ScrollView showsVerticalScrollIndicator={false} style={[GlobalStyles.appContainer, ]}>
                
                {/* LIST SECTION */}  
                <SectionList 
                    style={{ marginLeft: 0, overflow: 'hidden', marginBottom: 25, marginTop: 100,}}
                    //contentContainerStyle={{flexDirection : "row", flexWrap : "wrap", marginRight: 20,}}  
                    sections={sectionNotes}
                    stickySectionHeadersEnabled={false}
                    keyExtractor={(item, index) => item + index}
                    renderSectionHeader={({ section }) => (
                        <>
                            {!category && <View style={{ marginLeft: 20, marginRight: 20,justifyContent: 'center',}}>
                                <Text style={[GlobalStyles.listTitle, { alignSelf: 'flex-start'}]}>{section.title}</Text>                                
                            </View>}
                            {section.horizontal ? (<FlatList
                                style={{marginBottom: 10, }}
                                contentContainerStyle={{ marginRight: 15}}
                                horizontal
                                data={section.data}
                                renderItem={({item, index}) => _renderNote(item, index)}
                                showsHorizontalScrollIndicator={false}
                            />
                            ) : (<FlatList
                                style={{marginBottom: 10,  }}
                                contentContainerStyle={{ marginRight: 15, }}
                                data={section.data}
                                numColumns={isMobile ? 2 : 4}
                                renderItem={({item, index}) => _renderNote(item, index)}
                                showsHorizontalScrollIndicator={false}
                            />
                            )}
                        </>
                    )}
                    
                    renderItem={({item, section, index}) => {
                        if(section.horizontal){
                            return null;
                        }
                        //return _renderNote(item, index)
                        return null;
                    }}
                    
                    >                                                    
                </SectionList> 
                
            </ScrollView>

        </View>
    );
}

const Styles = StyleSheet.create({
    logo: {
      top: height / 10,
      width: width / 1,
      height: height / 7,
      resizeMode: 'contain',
    },
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        bottom: height / 20, 
        alignItems: 'center',
    },
    forgotPassword: {
        fontFamily: 'Brandon-Regular',
        fontSize: 16,
        color: Colors.secondary,
    },  
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
    },
    calendarIcon: {
        width: isMobile ? 14 : 20,
        height: isMobile ? 14 : 20,
        resizeMode: 'contain',
        
    }
    
  
});
  
//   NameScreen.propTypes = {
//     setToken: PropTypes.func.isRequired
//   }
  