import { useState } from 'react';


export default function useNotes() {
    const getNotes = () => {        
        const notesString = localStorage.getItem('notes');
        const userNotes = JSON.parse(notesString);
        return userNotes;
    };

    const [notes, setNotes] = useState(() => {
        return getNotes() || []
    });

    const saveNotes = notes => {
        localStorage.setItem('notes', JSON.stringify(notes));
        setNotes(notes);
    };

    return {
        setNotes: saveNotes,
        notes
    }
}
