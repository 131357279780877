import { apiAuth, apiFamily, apiFeatureFeedback, apiLocation, apiMember, apiNote, apiPinnedNotes, apiSendEmail, apiUpload, apiUser } from "../services";
import { apiStripe } from "../services/stripe";

export async function login({ email, password }) {
    try {
        const res = await apiAuth.login({ identifier: email, password });
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function registerUser({ email, username, password }) {
    try {
        const res = await apiAuth.register({ email, username, password });
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function createFamily(data) {
    try {
        const res = await apiFamily.create(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function updateFamily(id, data) {
    try {
        const res = await apiFamily.update(id, data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function listFamilies(data) {
    try {
        const res = await apiFamily.list(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function listUsers(data) {
    try {
        const res = await apiUser.list(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function updateUser(id, data) {
    try {
        const res = await apiUser.update(id, data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function getMe() {
    try {
        const res = await apiUser.me();
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function createNote(data) {
    try {
        const res = await apiNote.create(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function deleteNote(id) {
    try {
        const res = await apiNote.delete(id);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function updateNote(id, data) {
    try {
        const res = await apiNote.update(id, data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function listNotes(data) {
    try {
        const res = await apiNote.list(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function pinNote(data) {
    try {
        const res = await apiPinnedNotes.create(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function removePinnedNote(id) {
    try {
        const res = await apiPinnedNotes.delete(id);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function listPinnedNotes(data) {
    try {
        const res = await apiPinnedNotes.list(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function deletePinnedNote(id) {
    try {
        const res = await apiPinnedNotes.delete(id);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}
export async function getPinnedNote(id) {
    try {
        const res = await apiPinnedNotes.retrieve(id);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function upload(data) {
    try {
        const res = await apiUpload.upload(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function deleteUpload(id) {
    try {
        const res = await apiUpload.delete(id);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function createLocation(data) {
    try {
        const res = await apiLocation.create(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function updateLocation(id, data) {
    try {
        const res = await apiLocation.update(id, data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function deleteLocation(id) {
    try {
        const res = await apiLocation.delete(id);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function listLocations(data) {
    try {
        const res = await apiLocation.list(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function createMember(data) {
    try {
        const res = await apiMember.create(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function updateMember(id, data) {
    try {
        const res = await apiMember.update(id, data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function deleteMember(id,) {
    try {
        const res = await apiMember.delete(id);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function listMembers(data) {
    try {
        const res = await apiMember.list(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function sendEmail(data) {
    try {
        const res = await apiSendEmail.create(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function findEmails(data) {
    try {
        const res = await apiSendEmail.list(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function createCheckoutSession(data) {
    try {
        const res = await apiStripe.createCheckoutSession(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function sendFeatureFeedback(data) {
    try {
        const res = await apiFeatureFeedback.create(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function listProducts(data) {
    try {
        const res = await apiStripe.listProducts(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function createPortalSession(data) {
    try {
        const res = await apiStripe.createPortalSession(data);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}

export async function retrievePrice(id) {
    try {
        const res = await apiStripe.retrievePrice(id);
        return res.data;
    } catch (error) {
        switch (error.response.data.error.status) {
            case 400:
                // alert(error.response.data.error.message);
                throw error.response.data.error;
            case 401:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 403:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 404:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 405:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            case 500:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
            default:
                //alert(error.response.data.error.message);
                throw error.response.data.error;
        }
    }
}