import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import { TextInput, Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, Platform,  } from "react-native-web";
import Drawer from "react-bottom-drawer";
import { CheckBox } from 'react-native-web';
import { useNavigate } from "react-router-dom";
import randomColor from 'randomcolor';
import { Bird } from "../Themed";
import Colors from "../../constants/Colors";
import Strings from "../../constants/Strings";
import Images from "../../constants/Images";
import GlobalStyles from "../../styles/GlobalStyles";
import ScreenNames from "../../constants/ScreenNames";
import useUser from "../../hooks/useUser";
import useNotes from "../../hooks/useNotes";
import { apiNote } from "../../services";
import useToken from "../../hooks/useToken";
import { createNote as AddNote } from "../../hooks/useAPI";

// const width = Dimensions.get('window').width;
// const height = Dimensions.get('window').height;

interface Props {
    //title: string;
    //onClick: () => void;
}

const QuickNote: React.FC<Props> = ({ 
        //title,
        //onClick, 
    }) => { 

    const navigate = useNavigate();

    const { user, setUser } = useUser();
    const { notes, setNotes } = useNotes();
    const { verifyToken } = useToken();

    const [title, setTitle] = useState('');  
    const [category, setCategory] = useState(Strings.NOTE.toLowerCase());  
    const [categories, setCategories] = useState([Strings.GROCERY,Strings.PRACTICE,Strings.SHOPPING,Strings.REMINDER,Strings.NOTE]);
    const [activeBird, setActiveBird] = useState(0.5);  
    const [drawerType, setDrawerType] = useState('');
    const [bottomDrawerVisible, setBottomDrawerVisible] = useState(false);
    const [formLoading, setFormLoading] = useState(false);  

    const randomColorParams = {
        luminosity: 'light', 
        format: 'rgba', 
        alpha: 0.5
    }
    

    const goToBlankNote = async() => {
        setFormLoading(true);
        await apiNote.create({ title, user, color: randomColor(randomColorParams), type: 'text', category, })
        .then(res => {
            setFormLoading(false);
            setNotes([...notes, res.data]);
            setTitle('');
            navigate(ScreenNames.APP_NOTE + '/' + res.data.id, { replace: false,  }); 
        }).catch(error => {
            console.log(error.response)
            alert(error.response.statusText);
            setFormLoading(false);
        })
    }
    
    const onEnter = async(event) => {
        if(title && (event.key === 'Enter') && !formLoading) {
            console.log({ event })
        }
    }

    const createNote = async(event) => {
        if(!title) return false;

        let type = 'text';

        if(_.capitalize(category) === _.capitalize(Strings.GROCERY)) type = 'list';
        else if(_.capitalize(category) === _.capitalize(Strings.SHOPPING)) type = 'list';

        setFormLoading(true);
        try {
            const res = await AddNote({ data: { title, color: Colors.noteGray, type, category: _.capitalize(category), user: user.id }});
            console.log({ res })
            setFormLoading(false);
            //setNotes([...notes, res]);
            setTitle('');
            navigate(ScreenNames.APP_NOTE + '/' + res.data.id, { replace: false });  
        } catch(e) {

            alert(e.message);
            setFormLoading(false);
        }
        
    }

    const openDrawer = (type) => {
        setDrawerType(type);
        setBottomDrawerVisible(true);
    }

    const onBottomDrawerClose = useCallback(() => {
        setBottomDrawerVisible(false);
    }, []);

    const _renderCategoryList = (item: any, index: number) => {   
        let active = false;
        console.log(category)
        if(category === item.toLowerCase()) active = true;
        return (
            <View style={[GlobalStyles.checkboxContainer,  { marginBottom: 15 }]}>
                <CheckBox
                    color={Colors.secondary}
                    value={active}
                    onValueChange={(value) => { 
                        if(value) setCategory(item.toLowerCase());
                        else setCategory(Strings.NOTE.toLowerCase());
                    }}
                    style={GlobalStyles.checkbox}
                />
                <View style={{ marginLeft: 20, flexDirection: 'row'}}>
                    <Text style={[GlobalStyles.labelTitle]}>{item}</Text>
                    <View style={[GlobalStyles.dot, { marginLeft: 30, backgroundColor: item.color, marginTop: 9 }]}></View>
                </View>
            </View>
        );
    }

    useEffect(()=>{

    },[])
  

    return (
        <View
            style={[GlobalStyles.appBottom, GlobalStyles.appBottomShadow]}
        >
            {/* CONTAINER */}
            <View style={[GlobalStyles.appBottomContainer]}>
                {/* ACTION BUTTION */}
                {/* <TouchableOpacity onPress={goToBlankNote} style={[GlobalStyles.secondaryCircleButton, GlobalStyles.buttonDropShadowCircle, { position: 'absolute', alignSelf: 'flex-end', marginTop: -75 }]}>
                    <Image style={[GlobalStyles.inputAddButtonIcon]} source={Images.PLUS_WHITE} />
                </TouchableOpacity> */}

                <View style={{ marginTop: 50 }}>
                    {/* WELCOME TEXT */}
                    <Text style={Styles.welcomeText}>{Strings.WELCOME_BACK},</Text>
                    <View style={Styles.inputContainer}>
                        {/* SWITCH BUTTON */}
                        <Pressable onPress={() => openDrawer('category')} style={[GlobalStyles.iconButtonSmall, Styles.inputIconButton, { zIndex: 2}]}>
                            { category == Strings.GROCERY.toLowerCase() && <Image style={Styles.littleIcon} source={Images.SHOPPING_WHITE}></Image>}
                            { category == Strings.PRACTICE.toLowerCase() && <Image style={Styles.littleIcon} source={Images.PRACTICE_WHITE}></Image>}
                            { category == Strings.SHOPPING.toLowerCase() && <Image style={Styles.littleIcon} source={Images.SHOPPING_BAG_WHITE}></Image>}
                            { category == Strings.REMINDER.toLowerCase() && <Image style={Styles.littleIcon} source={Images.BELL_WHITE}></Image>}
                            { category == Strings.NOTE.toLowerCase() && <Image style={Styles.littleIcon} source={Images.NOTEBOOK_WHITE}></Image>}
                            {/* { category == 'note' && <Image style={Styles.littleIcon} source={Images.NOTEBOOK_WHITE}></Image>} */}
                        </Pressable>
                        {/* INPUT */}
                        <TextInput onKeyPress={onEnter} value={title} onChangeText={(text) => setTitle(text)} placeholderTextColor={Colors.secondary} style={[Platform.select({web: { outlineStyle: 'none', }}),GlobalStyles.roundedTextInput]} onFocus={() => setActiveBird(1)} onBlur={() => setActiveBird(0.5)}  placeholder={Strings.START_NEW_NOTE}></TextInput>
                        {/* BIRD */}
                        <Pressable onPress={createNote} style={Styles.inputIcon} >
                            <Bird width={35.51} opacity={activeBird} color={Colors.secondary}/>    
                        </Pressable>
                    </View>
                </View>
            </View>   

            {/* BOTTOM DRAWER */}
            <Drawer
                isVisible={bottomDrawerVisible}
                onClose={onBottomDrawerClose}
            >
                <View style={GlobalStyles.bottomDrawer}>
                   
                    { drawerType == 'category' &&
                    <View style={[GlobalStyles.bottomDrawerContainer]}>
                        <Text style={[GlobalStyles.drawerTitle,  ]}>{Strings.CATEGORY}</Text>
                        <FlatList 
                            style={{ marginTop: 20, marginBottom: 20, }}
                            data={categories}
                            showsHorizontalScrollIndicator={false}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({item, index}) => _renderCategoryList(item, index)}>
                        </FlatList> 
                    </View> }
              
                </View>
            </Drawer>        

        </View>
    );
}

const Styles = StyleSheet.create({
    inputContainer: { 
        //flexDirection: 'row', 
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
    },
    welcomeText: {
        marginBottom: 10,
        marginLeft: 15,
        fontFamily: 'Brandon-Regular',
        fontSize: 17,
        color: Colors.secondary,
    },
    inputIcon: {
        position: 'absolute',
        alignSelf: 'flex-end',
        right: 13,
        zIndex: 1,
    },
    inputIconButton: {
        position: 'absolute',
        alignSelf: 'flex-start',
        left: 5,
        zIndex: 1,
    },
    littleIcon: {
        resizeMode: 'contain',
        width: 16,
        height: 16,
        
    },
  
});



export default QuickNote