import React, { useEffect, useMemo, useRef,useState } from 'react';
import _ from 'lodash';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity, ScrollView, SectionList, Platform } from "react-native-web";
import { CheckBox } from 'react-native-web';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import emailValidator from 'email-validator';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Screens from '../../constants/Screens';
import { apiAuth, apiLocation, apiNote, globalFunctions } from '../../services';
import PropTypes from 'prop-types';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';

import IconHeader from '../../components/Headers/IconHeader';
import QuickNote from '../../components/QuickNote';
import useNotes from '../../hooks/useNotes';
import ScreenNames from '../../constants/ScreenNames';
import { listLocations } from '../../hooks/useAPI';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SettingsLocationsScreen() {  

    const { user, setUser } = useUser();
    const { token } = useToken();

    const { note } = useParams();

   const [ defaultLocations, setDefaultLocations] = useState<any>([]);
   const [ otherLocations, setOtherLocations] = useState<any>([]);

    // const defaultLocations = [
    //     {
    //         id: 'home',
    //         name: Strings.HOME,
    //         icon: Images.MAP_MARKER_HOME,            
    //     },
    //     {
    //         id: 'work',
    //         name: Strings.WORK,
    //         icon: Images.WORK,            
    //     },
    //     {
    //         id: 'daycare',
    //         name: Strings.DAYCARE,
    //         icon: Images.DAYCARE,            
    //     },
    //     {
    //         id: 'school',
    //         name: Strings.SCHOOL,
    //         icon: Images.SCHOOL,            
    //     },
    // ];

    const navigate = useNavigate();    

    const goToAddLocation = () => {
        if(note) navigate(ScreenNames.APP_SETTINGS_ADD_LOCATIONS + '?note=' + note, { replace: false })
        else navigate(ScreenNames.APP_SETTINGS_ADD_LOCATIONS, { replace: false })
    }

    const fetchLocations = async() => {
        const res = await listLocations({
            filters: {
                active: true,
                user: user.id
            }
        });

        const defaultLocs : any = [];
        const items : any = [];
        _.map(res.data, item => items.push({ id: item.id, ...item.attributes }));

        var homeLocation = items.find(location => location.name === 'Home');
        if(homeLocation && homeLocation.name === 'Home') defaultLocs.push({ id: homeLocation.id, name: homeLocation.name, address: homeLocation.address, icon: Images.MAP_MARKER_HOME })
        else defaultLocs.push({ name: 'Home', address: '', icon: Images.MAP_MARKER_HOME })

        var workLocation = items.find(location => location.name === 'Work');
        if(workLocation && workLocation.name === 'Work') defaultLocs.push({ id: workLocation.id, name: workLocation.name, address: workLocation.address, icon: Images.WORK })
        else defaultLocs.push({ name: 'Work', address: '', icon: Images.WORK })

        var dayCareLocation = items.find(location => location.name === 'Daycare');
        if(dayCareLocation && dayCareLocation.name === 'Daycare') defaultLocs.push({ id: dayCareLocation.id, name: dayCareLocation.name, address: dayCareLocation.address, icon: Images.DAYCARE })
        else defaultLocs.push({ name: 'Daycare', address: '', icon: Images.DAYCARE })

        var schoolLocation = items.find(location => location.name === 'School');
        if(schoolLocation && schoolLocation.name === 'School') defaultLocs.push({ id: schoolLocation.id, name: schoolLocation.name, address: schoolLocation.address, icon: Images.SCHOOL })
        else defaultLocs.push({ name: 'School', address: '', icon: Images.SCHOOL });

        const otherLocs = items.filter(location => location.name !== 'Home' && location.name !== 'Work' && location.name !== 'Daycare' && location.name !== 'School');

        setDefaultLocations(defaultLocs);
        setOtherLocations(otherLocs)

        
    }

    const editLocation = (item) => {
        if(item.id) navigate(ScreenNames.APP_SETTINGS_UPDATE_LOCATIONS + item.id, { replace: false })
        else navigate(ScreenNames.APP_SETTINGS_ADD_LOCATIONS + '?name=' + item.name, { replace: false })
    }
 

    useEffect(() => {  
        
        fetchLocations()
        
    },[]); 


    if(!token) return navigate(ScreenNames.SIGNIN);
    else
    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} exit={true} title={Strings.LOCATIONS} category={''} filter={false} onFilterClick={() => null} />
            
            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer, { alignSelf: 'center', width: !isMobile ? '50%' : '100%'}]}>
                
                <ScrollView showsVerticalScrollIndicator={false} style={[{ marginHorizontal: 25, marginTop: 100, height: height / 1.3}]}>
                    {/* LIST SECTION */} 
                    {defaultLocations.map((i,idx) => {
                        return (
                            <TouchableOpacity key={idx} onPress={() => editLocation(i)} style={Styles.button}>
                                <Image style={Styles.icon} source={i.icon} />
                                <Text style={[GlobalStyles.textBrandon, Styles.label]}>{i.name}</Text>
                            </TouchableOpacity> 
                        );
                    })}
                    {otherLocations.map((i,idx) => {
                        return (
                            <TouchableOpacity key={idx} onPress={() => editLocation(i)} style={Styles.button}>
                                <Image style={Styles.icon} source={Images.MAP_MARKER} />
                                <Text style={[GlobalStyles.textBrandon, Styles.label]}>{i.name}</Text>
                            </TouchableOpacity> 
                        );
                    })}
                </ScrollView>               
                
            </View>

            <TouchableOpacity onPress={goToAddLocation} style={[GlobalStyles.secondaryButton, Styles.saveButton, ]}>
                <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.ADD_LOCATION}</Text>
            </TouchableOpacity>

        </View>
    );
}

const Styles = StyleSheet.create({
    
    icon: {
        width: isMobile ? 25 : 30,
        height: isMobile ? 25 : 30,
        resizeMode: 'contain',   
        alignSelf: "center",    
    },
    label: {
        fontSize: 25,
        marginLeft: 20
    },
    button: { 
        flexDirection: 'row',
        marginBottom: 10,
        paddingVertical: 15
    },
    saveButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    
  
});
  
