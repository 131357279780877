import { useState } from 'react';
import Strings from '../constants/Strings';

export default function useUser() {
    const getUser = () => {
        const data: any = localStorage.getItem('user');
        return JSON.parse(data) || {}
    };
    const getSignUpUser = () => {
        const data: any = localStorage.getItem('signup_user');
        return JSON.parse(data) || {}
    };
    const getFamilies = () => {
        const data: any = localStorage.getItem('families');
        return JSON.parse(data) || []
    };
    const getFamily = () => {
        const data: any = localStorage.getItem('family');
        return JSON.parse(data) || {}
    };
    const getGoogleUser = () => {
        const data: any = localStorage.getItem('google_user');
        return JSON.parse(data) || {}
    };
    const getCheckoutSession = () => {
        const data: any = localStorage.getItem('checkout_session');
        return JSON.parse(data) || {}
    };
    
    const isSubscriptionActive = () => {
        const data: any = localStorage.getItem('user');
        const status = JSON.parse(data)?.subscription_status || '';
        if (status === 'active') return true;
        else if (status === 'trialing') return true;
        else return false;
    };
    
    const logOut = () => {
        
        if(confirm(Strings.ARE_YOU_SURE_LOGOUT)){
            localStorage.removeItem('user');
            localStorage.removeItem('family');
            localStorage.removeItem('families');
            localStorage.removeItem('token');
            localStorage.removeItem('checkout_session');
            localStorage.removeItem('google_user');
            localStorage.removeItem('signup_user');
            setUser({});
            return true
            
        }
    };

    const logOutPlain = () => {
        
        localStorage.removeItem('user');
        localStorage.removeItem('notes');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        setUser({});
        return true
            
    };

    const [user, setUser] = useState(getUser());
    const [families, setFamilies] = useState(getFamilies());
    const [family, setFamily] = useState(getFamily());
    const [signUpUser, setSignUpUser] = useState(getSignUpUser());
    const [googleUser, setGoogleUser] = useState(getGoogleUser());
    const [checkoutSession, setCheckoutSession] = useState(getCheckoutSession());
    const [subscriptionActive, setSubscriptionActive] = useState(isSubscriptionActive());

    const saveUser = user => {
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
    };
    const saveFamilies = data => {
        localStorage.setItem('families', JSON.stringify(data));
        setFamilies(data);
    };
    const saveFamily = data => {
        localStorage.setItem('family', JSON.stringify(data));
        setFamily(data);
    };
    const saveSignUpUser = data => {
        localStorage.setItem('signup_user', JSON.stringify(data));
        setSignUpUser(data);
    };
    const saveGoogleUser = data => {
        localStorage.setItem('google_user', JSON.stringify(data));
        setGoogleUser(data);
    };
    const saveCheckoutSession = data => {
        localStorage.setItem('checkout_session', JSON.stringify(data));
        setCheckoutSession(data);
    };

    return {
        setUser: saveUser,
        user,
        setFamilies: saveFamilies,
        families,
        getUser,
        getFamilies,
        setFamily: saveFamily,
        family,
        getFamily,
        logOut,
        logOutPlain,
        setSignUpUser: saveSignUpUser,
        signUpUser,
        getSignUpUser,
        setGoogleUser: saveGoogleUser,
        googleUser,
        getGoogleUser,
        checkoutSession,
        setCheckoutSession: saveCheckoutSession,
        getCheckoutSession,
        isSubscriptionActive,
        subscriptionActive,
        setSubscriptionActive,

    }
}
