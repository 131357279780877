import React, { useEffect, useState } from 'react';
import { Image, Text, View, Dimensions, StyleSheet, TouchableOpacity, Pressable } from "react-native-web";
import ReactPlayer from 'react-player'
import { isMobile } from 'react-device-detect';
import { TextInput, Bird } from '../../components/Themed';
import { Link, useNavigate } from "react-router-dom";
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Colors from '../../constants/Colors';
import useUser from '../../hooks/useUser';
import ScreenNames from '../../constants/ScreenNames';
import Videos from '../../constants/Videos'
import { GoogleSignInButton } from '../../components/GoogleSignInButton';
import useToken from '../../hooks/useToken';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default function SignUpAllSetScreen() { 
    
    const navigate = useNavigate();

    const { subscriptionActive } = useUser();
    const { token } = useToken();

    const [email, setEmail] = useState('');

    const submitForm = () => navigate(ScreenNames.APP_HOME, { replace: false });

    useEffect(() => {
        if(!token) return navigate(ScreenNames.SIGNUP, { replace: false });
        //else if(!subscriptionActive) return navigate(ScreenNames.APP_SETTINGS, { replace: false });
    },[]);
    
    return (
        <View style={GlobalStyles.background}> 
        {/* SITE CONTAINER */}
        <View style={GlobalStyles.container}>

            {/* SIGN UP CONTAINER */}
            <View style={GlobalStyles.signUpContainer}>

                {/* TITLE */}
                <Text style={[GlobalStyles.signUpFlowTitle, { marginTop: 75, }]}>{Strings.ALL_SET}</Text>                

                {/* DESCRIPTION */}
                <Text style={GlobalStyles.signUpFlowDescription}>{Strings.SIGNUP_ALLSET_DESCRIPTION}</Text>
                
                <View
                style={{
                    marginTop: 20
                }}
                >
                    <GoogleSignInButton onSuccess={() => null} type='settings' />
                </View>

                {/* BACKGROUND IMAGE */}
                <Image style={{ position: 'absolute', width: 310, height: 310, right: -width / 3.8, top: height / 3, zIndex:-1 }} source={Images.BIG_CALENDAR} />

                {/* INTRO VIDEO */}
                {/* <ReactPlayer url={Videos.INTRO} loop muted height={isMobile ? height/1.75 : height/1.4} playing width={300} playsinline controls style={{ alignSelf: 'center', marginTop: isMobile ? 15 : -50 }}/> */}

                

                {/* ACTION BUTTONS */}
                <View style={[Styles.actionButtonContainer]}>
                    <TouchableOpacity onPress={submitForm} style={[GlobalStyles.secondaryButton, GlobalStyles.buttonDropShadow]} >
                        <Text style={GlobalStyles.secondaryButtonText}>{Strings.START_PLANNING}</Text>
                    </TouchableOpacity>
                </View>

            </View>

        
        </View>
        </View>
    );
}

const Styles = StyleSheet.create({
    
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        bottom: height / 20, 
        alignItems: 'center',
    },
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
        //backgroundColor: 'blue',
    }
    
  
  });
  