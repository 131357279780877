import React, { useEffect, useState } from 'react';
import { Image, Text, View, Dimensions, StyleSheet, TouchableOpacity, Pressable } from "react-native-web";
import { Link, useNavigate } from "react-router-dom";
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Colors from '../../constants/Colors';
import useUser from '../../hooks/useUser';
import ScreenNames from '../../constants/ScreenNames';
import { createCheckoutSession, listProducts, retrievePrice } from '../../hooks/useAPI';
import _ from 'lodash';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default function SignUpSubscribeScreen() { 
    
    const navigate = useNavigate();

    const { user, checkoutSession, setCheckoutSession } = useUser();
    const [ products, setProducts ] = useState<any>([]);
    const [ loading, setLoading ] = useState(false);
    const hostname = window.location.hostname;
    
    useEffect(() => {    
        (async() => {
            console.log(window.location.hostname)
            const res = await listProducts({ 
                active: true,
                limit: 3,
            });

            let prods = res.data;

            for await (const [index, product] of prods.entries()) {
                const res = await retrievePrice(product.default_price);
                prods[index].priceData = res;
            }

            setProducts(prods);
        })();
    },[]);

    const submit = async() => {
        //if(checkoutSession?.url) return window.location.href = checkoutSession.url;

        try {            
            setLoading(true);
            const session = await createCheckoutSession({
                success_url: `https://${hostname}${ScreenNames.SIGNUP_ALLSET}`,
                cancel_url: `https://${hostname}${ScreenNames.SIGNUP_SUBSCRIBE}`,
                price: products[0].default_price,
            });
            console.log({ session })
            setCheckoutSession(session);
            window.location.href = session.url;
        } catch (error) {
            alert('Oh no! An error occured.  Please try again.');
            console.log(error);
        } finally {
            setLoading(false);
        }
    };      

    return (
        <View style={GlobalStyles.background}> 
        {/* SITE CONTAINER */}
        <View style={GlobalStyles.container}>

            {/* SIGN UP CONTAINER */}
            <View style={GlobalStyles.signUpContainer}>
                {/* PROGRESS */}
                <View style={GlobalStyles.progressDotsRow}>
                    <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                    <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                    <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                    <View style={[GlobalStyles.progressDot, { backgroundColor: Colors.accent }]} />
                    <View style={GlobalStyles.progressLongDot} />
                </View>                

                {/* TITLE */}
                <Text style={[GlobalStyles.signUpFlowTitle, { marginTop: 75, }]}>{'30-Day Money-Back Guarantee'}</Text> 

                <View
                style={{
                    marginTop: 15
                }}
                >
                    {
                        _.map(
                            products,
                            product => 
                            <View
                              key={product.id}
                              style={GlobalStyles.productContainer}
                              >
                                {/* {_.size(product.images) > 0 && (
                                    <Image style={GlobalStyles.productImage} source={{ uri: product.images[0] }} />
                                )} */}
                                {product?.priceData?.unit_amount && <View style={GlobalStyles.productPriceContainer}>
                                    <Text style={GlobalStyles.productPrice}>${product.priceData.unit_amount / 100}</Text>
                                    <Text style={GlobalStyles.productPriceTerm}>per {product?.priceData?.recurring?.interval}</Text>
                                </View>}
                                <View
                                style={GlobalStyles.productRightContainer}
                                >
                                    <Text style={GlobalStyles.productTitle}>{product.name}</Text>
                                    {/* <Text style={GlobalStyles.productSubTitle}>{product.description}</Text> */}
                                </View>
                            </View>
                        )
                    }
                </View>               

                {/* DESCRIPTION */}
                <Text style={GlobalStyles.signUpFlowDescription}>
                Try our subscription risk-free with our 30-day money-back guarantee.
                If you are not completely satisfied with our service within the first 30 days of your subscription,
                simply contact our customer support team to request a refund. No questions asked.
                </Text>
                
                

                {/* BACKGROUND IMAGE */}
                <Image style={{ position: 'absolute', width: 310, height: 310, right: -width / 3.8, top: height / 3, zIndex:-1 }} source={Images.BIG_CALENDAR} />                

                {/* ACTION BUTTONS */}
                <View style={[Styles.actionButtonContainer]}>
                    <TouchableOpacity onPress={submit} disabled={loading} style={[GlobalStyles.secondaryButton, GlobalStyles.buttonDropShadow]} >
                        <Text style={GlobalStyles.secondaryButtonText}>{'Subscribe Now'}</Text>
                    </TouchableOpacity>
                </View>

            </View>

        
        </View>
        </View>
    );
}

const Styles = StyleSheet.create({
    
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        bottom: height / 20, 
        alignItems: 'center',
    },
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
        //backgroundColor: 'blue',
    }
    
  
  });
  