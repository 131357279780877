import axios from 'axios';
import Routes from './routes';

const baseURL = process.env.REACT_APP_API_BASEURL;

let token = JSON.parse(localStorage.getItem('token'));

let config = {
    headers: { Authorization: `Bearer ${token}` }
};

export const apiSharedNotePKs = {   
    create: (async (payload) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        return await axios.post(baseURL + Routes.CREATE_SHARED_NOTE_PK, payload, config)
    }),   
    list: (async () => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.get(baseURL + Routes.LIST_SHARED_NOTES_PK, config)
        return res
    }),
    
}



