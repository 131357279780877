import React, { useEffect, useState } from 'react';
import { Text, View, Dimensions, StyleSheet, TouchableOpacity} from "react-native-web";
import { useNavigate } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import { passwordStrength } from 'check-password-strength';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import GlobalStyles from '../../styles/GlobalStyles';
import { globalFunctions } from '../../services';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';
import IconHeader from '../../components/Headers/IconHeader';
import { getMe, updateUser } from '../../hooks/useAPI';
import ScreenNames from '../../constants/ScreenNames';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default function SettingsPasswordScreen() {  

    const { user, setUser } = useUser();
    const { token } = useToken();
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordSecure, setPasswordSecure] = useState(true);
    const [passwordConfirmSecure, setPasswordConfirmSecure] = useState(true);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const [ successText, setSuccessText ] = useState('');
    const [ formLoading, setFormLoading ] = useState(false);

    const navigate = useNavigate();    
    

    const save = async() => {
        
        if(!password){
            setPasswordError(Strings.PASSWORD_EMPTY);
            return false
        }   
        if(passwordStrength(password).value == 'Too weak'){
            setPasswordError(Strings.PASSWORD_TOO_WEAK);
            return false
        }
        if(passwordStrength(password).value == 'Weak'){
            setPasswordError(Strings.PASSWORD_WEAK);
            return false
        }
        if(!passwordConfirm){
            setConfirmPasswordError(Strings.PASSWORD_EMPTY);
            return false
        } 
        if(password != passwordConfirm){
            setConfirmPasswordError(Strings.PASSWORD_NOT_MATCH);
            return false
        }

        setFormLoading(true);
        
        try {
            const res = await updateUser(user.id, { password });
            setUser(res);
            setSuccessText(Strings.SUCCESSFULLY_UPDATED);
            setFormLoading(true);
            await globalFunctions.timeout(5000);
            setSuccessText('');            
        } catch (error) {
            alert(error.message);
            setFormLoading(false);
        }
        
    }

    if(!token) return navigate(ScreenNames.SIGNIN);
    else
    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} exit={true} title={Strings.CHANGE_PASSWORD} category={''} filter={false} onFilterClick={() => null}/>
            
            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer, { width: !isMobile ? '50%' : '100%'}]}>
                
                <View style={[{ marginHorizontal: 25, marginTop: 100}]}>
                    
                    {/* FORM */}

                    {/* PASSWORD */}
                    <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 25, marginBottom: 10, color: Colors.accent }]}>{Strings.PASSWORD}</Text>
                    {/* INPUT PASSWORD */}
                    <TextInput secureTextEntry={passwordConfirmSecure} value={password} onChange={(text) => text && setPasswordError('')} onChangeText={(text) => setPassword(text)} placeholder={Strings.PASSWORD} style={{ width: '100%'}}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{passwordError}</Text>
                    
                    {/* CONFIRM PASSWORD */}
                    <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 25, marginBottom: 10, color: Colors.accent }]}>{Strings.CONFIRM_PASSWORD}</Text>
                    {/* INPUT PASSWORD */}
                    <TextInput secureTextEntry={passwordConfirmSecure} value={passwordConfirm} onChange={(text) => text && setConfirmPasswordError('')} onChangeText={(text) => setPasswordConfirm(text)} placeholder={Strings.CONFIRM_PASSWORD} style={{ width: '100%' }}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{confirmPasswordError}</Text>


                    {/* SUCCESS MESSAGE */}
                    <Text style={[GlobalStyles.successText, { width: '100%', fontSize: 20, marginTop: 30 }]}>{successText}</Text>
                    
                </View>
            </View>           

            <TouchableOpacity onPress={save} disabled={formLoading} style={[GlobalStyles.secondaryButton, Styles.saveButton, ]}>
                <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.SAVE}</Text>
            </TouchableOpacity>

        </View>
    );
}

const Styles = StyleSheet.create({
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
    },
    icon: {
        width: isMobile ? 25 : 30,
        height: isMobile ? 25 : 30,
        resizeMode: 'contain',   
        alignSelf: "center",    
    },
    label: {
        fontSize: 25,
        marginLeft: 20
    },
    button: { 
        flexDirection: 'row',
        marginBottom: 10,
        paddingVertical: 15
    },
    deleteAccountButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    deleteAccountText: {
        fontSize: 25,        
        color: Colors.black, 
    },
    saveButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    
  
});
  
