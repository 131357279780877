import axios from 'axios';
import qs from 'qs';

const { REACT_APP_API_BASEURL } = process.env;
const baseURL = REACT_APP_API_BASEURL;
const url = 'stripe';

export const apiStripe = {   
    createCheckoutSession: (async (data) => {
        const token = JSON.parse(localStorage.getItem('token') || '');
        const request: object = {
            method: 'post',
            baseURL,
            url: `/${url}/checkout-session/create`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data,
        }
        return axios(request);
    }),
    createPortalSession: (async (data) => {
        const token = JSON.parse(localStorage.getItem('token') || '');
        const request: object = {
            method: 'post',
            baseURL,
            url: `/${url}/portal/create`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data,
        }
        return axios(request);
    }),
    listProducts: (async (data?) => {
        const token = JSON.parse(localStorage.getItem('token') || '');
        const query = qs.stringify(data,{
            encodeValuesOnly: true,
        })
        const request: object = {
            method: 'get',
            baseURL,
            url: `${url}/products?${query}`,
            headers: {
              'Authorization': `Bearer ${token}`,
            },
        };

        return axios(request);
    }),
    retrievePrice: (async (id) => {
        const token = JSON.parse(localStorage.getItem('token') || '');
        const request: object = {
            method: 'get',
            baseURL,
            url: `/${url}/prices/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
        return axios(request);
    }),
    // retrieve: (async (id) => {
    //     const token = JSON.parse(localStorage.getItem('token') || '');
    //     const request: object = {
    //         method: 'get',
    //         baseURL,
    //         url: `/${url}/${id}`,
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //         }
    //     }
    //     return axios(request);
    // }),
    // update: (async (id, data) => {
    //     const token = JSON.parse(localStorage.getItem('token') || '');

    //     const request: object = {
    //         method: 'put',
    //         baseURL,
    //         url: `${url}/${id}`,
    //         headers: {
    //           'Authorization': `Bearer ${token}`,
    //         },
    //         data,
    //     };

    //     return axios(request);
    // }),
    // delete: (async (id) => {
    //     const token = JSON.parse(localStorage.getItem('token') || '');
    //     const request: object = {
    //         method: 'delete',
    //         baseURL,
    //         url: `/${url}/${id}`,
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //         }
    //     }
    //     return axios(request);
    // }),
    // list: (async (data?) => {
    //     const token = JSON.parse(localStorage.getItem('token') || '');
    //     const query = qs.stringify(data,{
    //         encodeValuesOnly: true,
    //     })
    //     const request: object = {
    //         method: 'get',
    //         baseURL,
    //         url: `${url}?${query}`,
    //         headers: {
    //           'Authorization': `Bearer ${token}`,
    //         },
    //     };

    //     return axios(request);
    // }),
    
}



