import React, { useEffect, useMemo, useRef,useState } from 'react';
import { Image, Text, View, Dimensions, Pressable, FlatList, StyleSheet, TouchableOpacity, ScrollView, SectionList, Platform } from "react-native-web";
import { CheckBox } from 'react-native-web';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { TextInput } from '../../components/Themed';
import { isMobile } from 'react-device-detect';
import emailValidator from 'email-validator';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Screens from '../../constants/Screens';
import { apiAuth, apiNote, apiUser, globalFunctions } from '../../services';
import PropTypes from 'prop-types';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';

import IconHeader from '../../components/Headers/IconHeader';
import QuickNote from '../../components/QuickNote';
import useNotes from '../../hooks/useNotes';
import ScreenNames from '../../constants/ScreenNames';
import { getMe, updateUser } from '../../hooks/useAPI';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SettingsEmailScreen() {  

    const { user, setUser } = useUser();
    const { token } = useToken();

    const [ email, setEmail ] = useState(() => {
        return user.email || '';
    });
    const [ emailError, setEmailError ] = useState('');
    const [ confirmEmail, setConfirmEmail ] = useState('');
    const [ confirmEmailError, setConfirmEmailError ] = useState('');

    const [ successText, setSuccessText ] = useState('');
    const [ formLoading, setFormLoading ] = useState(false);

    const navigate = useNavigate();    

    const save = async() => {
        
        if(!emailValidator.validate(email)){
            setEmailError(Strings.EMAIL_NOT_VALID);
            return false;            
        } 

        if(!confirmEmail){
            setConfirmEmailError(Strings.ENTER_EMAIL);
            return false;
        }

        if(email != confirmEmail){
            setConfirmEmailError(Strings.EMAIL_NON_MATCH);
            return false;
        }

        setFormLoading(true);
        try {
            const res = await updateUser(user.id, { email, username: email });
            setUser(res);
            setSuccessText(Strings.SUCCESSFULLY_UPDATED);
            setFormLoading(true);
            //await globalFunctions.timeout(5000);
            setSuccessText('');
        } catch (error) {
            alert(error.message);
            setFormLoading(false);
        }
        

        
    } 

    if(!token) return navigate(ScreenNames.SIGNIN)
    else
    return (
        <View style={GlobalStyles.appBackground}> 
            {/* HEADER */}
            <IconHeader type={'back'} exit={true} title={Strings.EMAIL_ADDRESS} />
            
            {/* SITE CONTAINER */}
            <View style={[GlobalStyles.appContainer,{  width: !isMobile ? '50%' : '100%'} ]}>
                
                <View style={[{ marginHorizontal: 25, marginTop: 100}]}>
                    
                    {/* FORM */}

                    {/* EMAIL */}
                    <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 25, marginBottom: 10, color: Colors.accent }]}>{Strings.EMAIL_ADDRESS}</Text>
                    {/* INPUT EMAIL */}
                    <TextInput value={email} onChange={(text) => text && setEmailError('')} onChangeText={(text) => setEmail(text)} placeholder={Strings.EMAIL_ADDRESS} style={{ width: '100%'}}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{emailError}</Text>
                    
                    {/* CONFIRM EMAIL */}
                    <Text style={[GlobalStyles.textBrandon, { width: '100%', marginTop: 25, marginBottom: 10, color: Colors.accent }]}>{Strings.CONFIRM_EMAIL_ADDRESS}</Text>
                    {/* INPUT FAMILY NAME */}
                    <TextInput value={confirmEmail} onChange={(text) => text && setConfirmEmailError('')} onChangeText={(text) => setConfirmEmail(text)} placeholder={Strings.CONFIRM_EMAIL_ADDRESS} style={{ width: '100%' }}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={[GlobalStyles.errorText, { width: '100%' }]}>{confirmEmailError}</Text>


                    {/* SUCCESS MESSAGE */}
                    {successText && <Text style={[GlobalStyles.successText, { width: '100%', fontSize: 20, marginTop: 30 }]}>{successText}!</Text>}
                    
                </View>
            </View>           

            <TouchableOpacity onPress={save} disabled={formLoading} style={[GlobalStyles.secondaryButton, Styles.saveButton, ]}>
                <Text style={[GlobalStyles.secondaryButtonText]}>{Strings.SAVE}</Text>
            </TouchableOpacity>

        </View>
    );
}

const Styles = StyleSheet.create({
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
    },
    icon: {
        width: isMobile ? 25 : 30,
        height: isMobile ? 25 : 30,
        resizeMode: 'contain',   
        alignSelf: "center",    
    },
    label: {
        fontSize: 25,
        marginLeft: 20
    },
    button: { 
        flexDirection: 'row',
        marginBottom: 10,
        paddingVertical: 15
    },
    deleteAccountButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    deleteAccountText: {
        fontSize: 25,        
        color: Colors.black, 
    },
    saveButton: {
        alignSelf: 'center', 
        position: 'absolute', 
        bottom: 30,
    },
    
  
});
  
