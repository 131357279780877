

export const globalFunctions = {
    timeout: (async (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }), 
    setToken: (async (token) => {
        localStorage.setItem('token', JSON.stringify(token));
    }), 
    getToken: (async () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.token;
    }),
    phoneValidation: (async (phone) => {
        const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        return !(!phone || regex.test(phone) === false);
    }),
    logOut: (async () => {
        localStorage.setItem('user', JSON.stringify(''));
        localStorage.setItem('notes', JSON.stringify(''));
        localStorage.setItem('token', JSON.stringify(''));
    }),
}



