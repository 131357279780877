export default {
    LOGO: require('../assets/logos/dovetail_logo.png'),
    INTRO_SCREEN_IMAGE: require('../assets/images/ROA_4104-removebg.png'),


    //ICONS
    ARROW_LEFT: require('../assets/icons/arrow-left.svg'),
    ARROW_RIGHT_WHITE: require('../assets/icons/arrow-right-white.svg'),
    EXIT: require('../assets/icons/cross.svg'),
    BIG_CALENDAR: require('../assets/icons/big-calendar.svg'),
    PLUS: require('../assets/icons/plus.svg'),
    PLUS_SMALL: require('../assets/icons/plus-small.svg'),
    PLUS_WHITE: require('../assets/icons/plus-white.svg'),
    MINUS_SMALL: require('../assets/icons/minus-small.svg'),
    EYE: require('../assets/icons/eye.svg'),
    EYE_CROSSED: require('../assets/icons/eye-crossed.svg'),
    SETTINGS: require('../assets/icons/settings.svg'),
    SHOPPING_BAG_WHITE: require('../assets/icons/shopping-bag-thin-white.svg'),
    CALENDAR: require('../assets/icons/calendar.svg'),
    PIN: require('../assets/icons/thumbtack.svg'),
    PIN_FILL: require('../assets/icons/thumbtack-fill.svg'),
    TRASH: require('../assets/icons/trash.svg'),
    GOOGLE: require('../assets/icons/google.svg'),
    SHARE: require('../assets/icons/share.svg'),
    LIST: require('../assets/icons/list-check.svg'),
    PALETTE: require('../assets/icons/palette.svg'),
    PICTURE: require('../assets/icons/picture.svg'),
    USER_ADD: require('../assets/icons/user-add.svg'),
    MAP_MARKER_PLUS: require('../assets/icons/map-marker-plus.svg'),
    CLOCK_ADD: require('../assets/icons/time-add.svg'),
    TEXT: require('../assets/icons/text.svg'),
    MAP_PIN_WHITE: require('../assets/icons/pin-white.svg'),
    ALARM_CLOCK_WHITE: require('../assets/icons/alarm-clock-white.svg'),
    ALARM_CLOCK: require('../assets/icons/alarm-clock-blue.svg'),
    PRACTICE_WHITE: require('../assets/icons/baseball.svg'),
    SHOPPING_WHITE: require('../assets/icons/shopping-cart-white.svg'),
    BELL_WHITE: require('../assets/icons/bell-white.svg'),
    NOTEBOOK_WHITE: require('../assets/icons/notebook-white.svg'),
    USER: require('../assets/icons/user.svg'),
    FAMILY_MEMBERS: require('../assets/icons/family.svg'),
    NOTIFICATIONS: require('../assets/icons/bell.svg'),
    LOCATIONS: require('../assets/icons/map-marker.svg'),
    ENVELOPE: require('../assets/icons/envelope.svg'),
    KEY: require('../assets/icons/key.svg'),
    LOG_OUT: require('../assets/icons/sign-out.svg'),
    POWER: require('../assets/icons/power.svg'),
    PENCIL: require('../assets/icons/pencil.svg'),
    MAP_MARKER_HOME: require('../assets/icons/map-marker-home.svg'),
    WORK: require('../assets/icons/briefcase.svg'),
    DAYCARE: require('../assets/icons/child-head.svg'),
    SCHOOL: require('../assets/icons/school.svg'),
    MAP_MARKER: require('../assets/icons/marker.svg'),
    SEARCH: require('../assets/icons/search.svg'),
    SEARCH_WHITE: require('../assets/icons/search-white.svg'),
    PIN_WHITE: require('../assets/icons/thumbtack-white.svg'),
    SHARE_WHITE: require('../assets/icons/share-white.svg'),
    BOX_OPEN_WHITE: require('../assets/icons/box-open-white.svg'),
    BOX_OPEN: require('../assets/icons/box-open.svg'),

    //VIDEO
    VIDEO: require('../assets/images/tutorial.mp4'),
    
}