import axios from 'axios';
import uniqid from 'uniqid';
import Routes from './routes';

const baseURL = process.env.REACT_APP_API_BASEURL;

let token = JSON.parse(localStorage.getItem('token'));

let config = {
    headers: { Authorization: `Bearer ${token}` }
};

export const apiRelative = {   
    create: (async (payload) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.post(baseURL + Routes.CREATE_RELATIVE, payload, config)
        return res
    }),
    retrieve: (async (id) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.get(baseURL + Routes.RETRIEVE_RELATIVE + id, config)
        return res
    }),
    update: (async (id, payload) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.put(baseURL + Routes.UPDATE_RELATIVE + id, payload, config)
        return res
    }),
    delete: (async (id) => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.delete(baseURL + Routes.DELETE_RELATIVE + id, config)
        return res
    }),
    list: (async () => {
        token = JSON.parse(localStorage.getItem('token')); 
        config.headers = { Authorization: `Bearer ${token}` };
        const res = await axios.get(baseURL + Routes.LIST_RELATIVES, config)
        return res
    }),
    
}



