import React, { useRef, useState } from 'react';
import { Image, Text, View, Dimensions, Pressable, FlatList, } from "react-native";
import Styles from '../../styles/Styles';
import Colors from '../../constants/Colors';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function DesktopLanding() {

    const headerTitle = 'Before you get started here is a brief tutorial';


    return (
        <View style={Styles.background}>
            {/* SITE CONTAINER */}
            <View style={Styles.container}>
                {/* HEADER */}
                <View style={[Styles.headerContainer, {marginTop:30}]}>
                    <Text style={Styles.headerTitle} >{headerTitle}</Text>
                    <iframe
                        src={Images.VIDEO}
                        width="100%"
                        height="800px"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </View>
            </View>
        </View>
    );
}