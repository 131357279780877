import IntroScreen from '../screens/IntroScreen';
import SignUpEmailScreen from '../screens/SignUpEmailScreen';
import SignUpPasswordScreen from '../screens/SignUpPasswordScreen';
import SignUpFamilyNameScreen from '../screens/SignUpFamilyNameScreen';
import SignUpFamilyMembersScreen from '../screens/SignUpFamilyMembersScreen';
import SignUpAllSetScreen from '../screens/SignUpAllSetScreen';
import SignInScreen from '../screens/SignInScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';

import DashboardScreen from '../screens/DashboardScreen';
import NoteScreen from '../screens/NoteScreen';
import SharedNoteScreen from '../screens/NoteScreen';
import CategoryListScreen from '../screens/CategoryListScreen';
import SettingsScreen from '../screens/SettingsScreen';
import SettingsAccountScreen from '../screens/SettingsAccountScreen';
import SettingsEmailScreen from '../screens/SettingsEmailScreen';
import SettingsPasswordScreen from '../screens/SettingsPasswordScreen';
import SettingsNotificationScreen from '../screens/SettingsNotificationsScreen';
import SettingsFamilyScreen from '../screens/SettingsFamilyScreen';
import SettingsLocationsScreen from '../screens/SettingsLocationsScreen';
import SettingsAddLocationScreen from '../screens/SettingsAddLocationScreen';
import TutorialScreen from '../screens/TutorialScreen';
import GoogleCalendarAuthScreen from '../screens/GoogleCalendarAuthScreen';
import SettingsColorScreen from '../screens/SettingsColorScreen';
import SignUpSubscribeScreen from '../screens/SignUpSubscribeScreen';

export default {
    IntroScreen,
    SignUpEmailScreen,
    SignUpPasswordScreen,
    SignUpFamilyNameScreen,
    SignUpFamilyMembersScreen,
    SignUpAllSetScreen,
    SignInScreen,
    ForgotPasswordScreen,
    DashboardScreen,
    NoteScreen,
    CategoryListScreen,
    SettingsScreen,
    SettingsAccountScreen,
    SettingsEmailScreen,
    SettingsPasswordScreen,
    SettingsNotificationScreen,
    SettingsFamilyScreen,
    SettingsLocationsScreen,
    SettingsAddLocationScreen,
    SharedNoteScreen,
    TutorialScreen,
    GoogleCalendarAuthScreen,
    SettingsColorScreen,
    SignUpSubscribeScreen,
    
}