import React, { useMemo, useEffect, useState } from 'react';
import { Image, Text, View, Dimensions, StyleSheet, TouchableOpacity } from "react-native-web";
import { TextInput, Bird } from '../../components/Themed';
import { Link, useLocation, useNavigate } from "react-router-dom";
import emailValidator from 'email-validator';
import Strings from '../../constants/Strings';
import Images from '../../constants/Images';
import GlobalStyles from '../../styles/GlobalStyles';
import Colors from '../../constants/Colors';

import { apiAuth, globalFunctions } from '../../services';
import useUser from '../../hooks/useUser';
import useToken from '../../hooks/useToken';
import { ProfileContext } from '../../context/ProfileContext';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;


export default function SignUpEmailScreen() {  

    const navigate = useNavigate();
    const { signUpUser, setSignUpUser } = useUser();

    const { search } = useLocation();
    function useQuery() {
        return useMemo(() => new URLSearchParams(search), [search]);
    }    
    const shareEmail = useQuery().get('email');

    const [email, setEmail] = useState(() => {
        //get stored value
        if(shareEmail) return shareEmail;
        else if(signUpUser && signUpUser.email) return signUpUser.email;
        else return '';
    });

    const [nextButtonLoading, setNextButtonLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    
    useEffect(() => {    
       // if(token) logOutPlain();
    },[]);

    const submitForm = async() => {
        if(!emailValidator.validate(email)){
            setEmailError(Strings.EMAIL_NOT_VALID);
            return false;            
        } 

        setNextButtonLoading(true);
        setSignUpUser({ email });
        setNextButtonLoading(false);
        navigate('/signup/password' + search);       
         
    }

    
    return (
        <View style={GlobalStyles.background}> 
            {/* SITE CONTAINER */}
            <View style={GlobalStyles.container}>

                {/* TOP ACTION HEADER */}
                <View style={GlobalStyles.topActionHeader}>
                    {/* BACK ARROW */}
                    <Link style={{ textDecoration: 'none', left: 0,}} to='/'>
                        <Image style={[GlobalStyles.leftArrowIcon]} source={Images.ARROW_LEFT} />
                    </Link>
                    {/* EXIT */}
                    <Link style={{ textDecoration: 'none'}} to={{ pathname: '/' }}>
                        <Image style={[GlobalStyles.exitIcon, { position: 'absolute', right: 0,}]} source={Images.EXIT} />
                    </Link>
                </View>

                {/* SIGN UP CONTAINER */}
                <View style={GlobalStyles.signUpContainer}>
                    {/* PROGRESS */}
                    <View style={GlobalStyles.progressDotsRow}>
                        <View style={GlobalStyles.progressLongDot} />
                        <View style={GlobalStyles.progressDot} />
                        <View style={GlobalStyles.progressDot} />
                        <View style={GlobalStyles.progressDot} />
                        <View style={GlobalStyles.progressDot} />
                    </View>

                    {/* DESCRIPTION */}
                    <Text style={GlobalStyles.signUpFlowDescription}>{Strings.SIGNUP_EMAIL_DESCRIPTION}</Text>

                    {/* TITLE */}
                    <Text style={GlobalStyles.signUpFlowTitle}>{Strings.ENTER_EMAIL}</Text>

                    {/* INPUT EMAIL */}
                    <TextInput keyboardType='email-address' value={email} onChange={(text) => text && setEmailError('')} onChangeText={(email) => setEmail(email)} placeholder={Strings.EMAIL} style={{ marginTop: 100}}></TextInput>
                    {/* ERROR MESSAGE */}
                    <Text style={GlobalStyles.errorText}>{emailError}</Text>

                    {/* BIRD */}
                    <Bird style={{ position: 'absolute', left: -width / 5.5, bottom: height / 8,}}/>                  

                    {/* ACTION BUTTONS */}
                    <View style={[Styles.actionButtonContainer]}>
                        <TouchableOpacity disabled={nextButtonLoading} onPress={submitForm} style={[GlobalStyles.signUpNextButton, GlobalStyles.buttonDropShadowCircle, { alignSelf:'flex-end', opacity: nextButtonLoading && 0.5, }]}>
                            <Image style={[GlobalStyles.nextArrowIcon]} source={Images.ARROW_RIGHT_WHITE} />
                        </TouchableOpacity>
                    </View>

                </View>

            
            </View>
        </View>
    );
}

const Styles = StyleSheet.create({
    
    actionButtonContainer: { 
        position: 'absolute', 
        width: '100%', 
        bottom: height / 20, 
        alignItems: 'center',
    },
    image: {
        top: height / 18,
        left: width / 9,
        width: width * 2,
        height: height / 2,
        resizeMode: 'contain',
        //backgroundColor: 'blue',
    }
    
  
  });
  